import React, { Component, useState } from "react";
import { connect } from "react-redux";
// import { globalAction } from "../../redux/actions";
// import {
//   ADD_TO_CART,
//   APPLY_FILTER,
//   GET_BRAND_LIST,
//   GET_CATEGORY,
//   GET_LATEST_PRODUCTS,
//   GET_OFFER_LIST,
//   GET_PRODUCT_DETAILS,
//   SAVE_PRODUCT,
//   SHOW_TOAST,
// } from "../../redux/actionTypes";
import {
  Card,
  Accordion,
  Pagination,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./category-detail.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactStars from "react-rating-stars-component";
// import { Navigation } from "react-minimal-side-navigation";
import SeoSetting from "../../pages/seoSetting/seoSetting";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import BannerInner from "./banner/BannerInner";
// import Bannerimg from "../../layouts/image/product/engineering-design.jpg";
import {
  APPLY_FILTER,
  GET_HOME_DATA,
  GET_LATEST_PRODUCTS,
  GET_PRODUCT_DETAILS,
  globalAction,
  SAVE_PRODUCT,
  SHOW_TOAST,
  ADD_TO_CART,
  GET_SEARCH_PRODUCT,
} from "../../../redux";
import RangeSlider from "../../layouts/range-slider/RangeSlider";
import { getBrandListApi, getSearchProduct } from "../../../api";
import { BiRupee } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { MdCompareArrows } from "react-icons/md";
import ReactPaginate from "react-paginate";
import CategoryBanner from "../../../assets/categoryBanner.png";
class CategoryDetail extends Component {
  constructor() {
    super();
    this.state = {
      minimumPrice: 0,
      maximumPrice: 10000,
      sellName: "",
      myFavSeller: "",
      shiping: false,
      video: false,
      store: false,
      selectedView: "grid",
      brands: [],
      show: false,
      class: "section",
      total: 0,
    };
  }

  // componentDidMount(){
  //   const {applyFilter}=this.props;
  //   applyFilter()
  // }

  componentDidMount() {
    window.scroll({ top: 0 });
    const {
      //   getCategories,
      match: {
        params: { cat_id, sub_catId },
      },
      getHomeData,
      best_selling_product,
      getLatestProduct,
      filter,
      category,
      cart,
      products,
      match,
      location,
      params
      // brands,
      // getBrands,
      // offerList,
      // getOfferList,
    } = this.props;
    const calculateTotalPrice = (cart) => {
      let total = 0;
      cart.map((item, i) => {
        total = total + parseFloat(item.sale_price);
        return total;
      });
      total = Number(total).toFixed(0);
      this.setState({ total: total });
    };
    let searchQuery = localStorage.getItem("isSearchValid");
    let brandQuery = localStorage.getItem("brandQuery");


    const { brands, show } = this.state;
    if (sub_catId) {
      localStorage.setItem("isSearchValid", "false");
      localStorage.removeItem("brandQuery");
      this.props.applyFilter({ sub_category_slug: sub_catId });
      this.props.applyFilter({ sub_category_slug: sub_catId });
    }
    else if (cat_id) {
      localStorage.setItem("isSearchValid", "false");
      localStorage.removeItem("brandQuery");
      this.props.applyFilter({ category_slug: cat_id });
    }
  
    else if ( searchQuery === "true") {
      // this.props.applyFilter();
      this.props.history.push(`/view-all`);
    }
    else if (products.data.length <= 0 || searchQuery === "false") {
      // this.props.applyFilter();
      this.props.history.push(`/view-all`);
    }
    else {
      localStorage.removeItem("brandQuery");
      this.props.applyFilter();
      this.props.history.push(`/view-all`);
    }

    if (brands.length === 0 && searchQuery === "false" && brandQuery) {
      localStorage.setItem("isSearchValid", "false");
      getBrandListApi().then((res) => {
        const { data, status } = res;
        if (status === 200) {
          this.setState({ brands: data.brands.data });
        }
      });
    }

    if (best_selling_product.data.length <= 0) {
      localStorage.setItem("isSearchValid", "false");
      getHomeData();
    }
    if (best_selling_product.data.length <= 0) {
      localStorage.setItem("isSearchValid", "false");
      getHomeData();
    }
    if (show === true) {
      calculateTotalPrice();
    }
    // if (offerList.length === 0) {
    //   //   getOfferList();
    // }
    // getCategories();
    getLatestProduct();
  }

  getProductDetail = async (id, slug) => {
    const {
      getProductDetailById,
      match: {
        params: { cat_id, sub_catId },
      },
    } = this.props;
    await getProductDetailById(id);
    this.props.history.push(`/${cat_id}/${sub_catId}/${slug}`);
    window.scroll(0, 0);
  };

  saveProductByUser = (product_id) => {
    const { saveProduct, user, showToast } = this.props;
    if (user) {
      saveProduct({ product_id, user_id: user.id });
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      showToast({ data });
    }
  };

  changeView(value) {
    this.setState({
      selectedView: value,
    });
  }

  handleChange = (text, value) => {
    let state = this.state;
    state[text] = value;
    this.setState(state);
  };

  // getCategoryName = (catId, subCatId) => {
  //   const category = this.props.categories.filter((item) => item.slug === Number(catId))[0];

  //   if (category) {
  //     if (subCatId) {
  //       const subCategory = category.sub_category.filter((item) => item.slug === Number(subCatId))[0];
  //       return [category, subCategory];
  //     }
  //     return [category];
  //   } else {
  //     return [];
  //   }
  // };
  // handleCheckboxChange = (text, item, event) => {
  //   const value = event.target.checked;
  //   const { applyFilter } = this.props;
  //   let state = this.state;
  //   const { rating, brand_id } = state.param;
  //   if (text === "rating") {
  //     if (value) {
  //       rating.push(item);
  //     } else {
  //       const index = rating.indexOf(item);
  //       rating.splice(index, 1);
  //     }
  //     applyFilter({ rating });
  //   } else {
  //     if (value) {
  //       brand_id.push(item);
  //     } else {
  //       const index = brand_id.indexOf(item);
  //       brand_id.splice(index, 1);
  //     }
  //     applyFilter({ brand_id });
  //   }
  // };

  onSelectLimit = (event) => {
    const { applyFilter } = this.props;
    let value = event.target.value;
    let searchQuery = localStorage.getItem("isSearchValid");
    let search = localStorage.getItem('searchQuery')
    if (searchQuery === 'true' && search) {
      let param = this.props?.location?.search;
      let stringIndex = param.indexOf('limit=')
      let xed;
      if (stringIndex > -1) {
        xed = param.replace(param[stringIndex + 6].valueOf(), '')
        xed = xed.replace(xed[stringIndex + 6].valueOf(), value)
        xed = `${xed}&page=1`
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(xed, this.props.history)
      }
      else {
        param += `&limit=${value}&page=1`
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(param, this.props.history)
      }
    }
    else {
      applyFilter({ limit: value });
    }

  };

  onSorting = (value) => {
    const { filter, applyFilter } = this.props;
    window.scroll({ top: 0 });
    let pageNumber = 1;
    filter["page"] = pageNumber;
    let searchQuery = localStorage.getItem("isSearchValid");
    let search = localStorage.getItem('searchQuery')
    if (searchQuery === 'true' && search) {
      let param = this.props?.location?.search;
      let stringIndex = param.indexOf('order=')
      let xed;
      console.log(value)
      if (stringIndex > -1) {
        if (value === 'desc') {
          xed = param.replace('asc', value)
        }
        else {
          xed = param.replace('desc', value)
        }
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(xed, this.props.history)
      }
      else {
        param += `&order=${value}`
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(param, this.props.history)
      }
    }
    else {
      if (value !== "default") {
        applyFilter({ order: value });
      }
    }


  };

  onSelectPage = (number) => {
    const { applyFilter } = this.props;
    applyFilter({ page: number });
  };

  onPagination = () => {
    const {
      products: { last_page, current_page },
    } = this.props;
    let arr = [];
    if (last_page < 10) {
      for (let number = 1; number <= last_page; number++) {
        arr.push(
          <Pagination.Item
            active={current_page === number}
            onClick={() => {
              this.onSelectPage(number);
              window.scroll({ top: 0 });
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      for (let number = 1; number <= 10; number++) {
        arr.push(
          <Pagination.Item
            active={current_page === number}
            onClick={() => {
              this.onSelectPage(number);
              window.scroll({ top: 0 });
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    return arr;
  };

  getCategoryName = (catId, subCatId) => {
    const category = this.props.category.find((item) => item.slug === catId);
    if (category) {
      if (subCatId) {
        let subCategory;
        if (category?.sub_category) {
          subCategory = category.sub_category.find(
            (item) => item.slug === subCatId
          );
          return [category, subCategory];
        }
      }
      return [category];
    } else {
      return [];
    }
  };

  onPriceChange = (newValue) => {
    const { applyFilter } = this.props;
    applyFilter({ min_price: newValue[0], max_price: newValue[1] });
  };

  showChild(i) {
    const closeChild = document.querySelectorAll(`ul.category_list li`);
    closeChild.forEach((element) => element.classList.remove("open"));
    const accordions = document.querySelectorAll(`.accordion-${i}`);
    let targetEle = accordions[0].closest(".section");
    targetEle.classList.toggle("open");
  }

  addToCart = (item) => {
    const { showToast, cart, addToCart } = this.props;
    let flag = true;
    if (item) {
      var newArray = this.props.cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} already added in your cart`,
            status: "Warning",
          };
          showToast({ data });
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        this.props.cart.push(item);
        const data = {
          msg: `${item.title} are successfully added in your cart`,
          status: "success",
        };
        showToast({ data });
        localStorage.setItem("cart", JSON.stringify(this.props.cart));
        addToCart(cart);
      }
    }
  };

  handlePageClick = async (data) => {
    window.scroll({ top: 0 });
    let pageNumber = data.selected + 1;
    const { filter, applyFilter } = this.props;
    filter["page"] = pageNumber;

    let searchQuery = localStorage.getItem("isSearchValid");
    let search = localStorage.getItem('searchQuery')
    let brandQuery = localStorage.getItem('brandQuery')

    if(brandQuery){
      applyFilter(filter);
    }
    else if (searchQuery === 'true' && search) {
      let param = this.props?.location?.search;
      let stringIndex = param.indexOf('page=')
      let xed;
      // console.log(stringIndex,pageNumber)
      if (stringIndex > -1) {
        if (Number(pageNumber) > 9) {
          console.log(param[stringIndex + 5].valueOf())
          xed = param.replace(param[stringIndex + 5].valueOf(), '')
          xed = xed.replace(xed[stringIndex + 5].valueOf(), pageNumber)
        }
        else if (Number(pageNumber) > 99) {
          xed = param.replace(param[stringIndex + 5].valueOf(), '')
          xed = xed.replace(xed[stringIndex + 5].valueOf(), '')
          xed = xed.replace(xed[stringIndex + 5].valueOf(), pageNumber)
        }
        else {
          console.log(param[stringIndex + 5].valueOf())
          xed = param.replaceAll(param[stringIndex + 5].valueOf(), pageNumber)
        }
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(xed, this.props.history)
      }
      else {
        param += `&page=${pageNumber}`
        localStorage.setItem('isSearchValid', 'true')
        this.props.getSearchProduct(param, this.props.history)
      }
    }
    else {
      applyFilter(filter);
    }



  };



  render() {
    const {
      // sideCategory,
      history,
      // brands,
      // addToCartList,
      applyFilter,
      category_banner,
      // offerList,
      products,
      categories,
      best_selling_product,
      category,
      product_max_price,
      match: { params },
      match: {
        params: { cat_id, sub_catId },
      },
      filter,
    } = this.props;
    const { brands, maximumPrice, show } = this.state;
    const { data } = this.props.latest_products;
    console.log(products?.data)
    return (

      <>
        {this.getCategoryName(params.cat_id)[0] && (
          <SeoSetting
            title={`${this.getCategoryName(params.cat_id, params.sub_catId)[1]
              ? this.getCategoryName(
                params.cat_id,
                params.sub_catId
              )[1]?.title?.toUpperCase()
              : this.getCategoryName(
                params.cat_id,
                params.sub_catId
              )[0]?.title?.toUpperCase()
              }-BellstoneOnline`}
            metaDescription={`${this.getCategoryName(params.cat_id, params.sub_catId)[1]
              ? this.getCategoryName(params.cat_id, params.sub_catId)[1]
                .description
              : this.getCategoryName(params.cat_id, params.sub_catId)[0]
                .description
              }-BellstoneOnline`}
          />
        )}

        <div className="bread_crum">
          <Container>
            <Row>
              <ul>
                <li>
                  <Link to="/">
                    Home
                    <span>/</span>
                  </Link>
                </li>
                {this.getCategoryName(params.cat_id)[0] && (
                  <li>
                    <Link
                      to={`/${this.getCategoryName(params.cat_id)[0].slug}`}
                    >
                      {this.getCategoryName(params.cat_id)[0].name}
                      <span>/</span>
                    </Link>
                  </li>
                )}
                {params.sub_catId && (
                  <li>
                    {this.getCategoryName(params.cat_id, params.sub_catId)
                      .length > 0 &&
                      this.getCategoryName(params.cat_id, params.sub_catId)[1]
                        .name}
                    <span>/</span>
                  </li>
                )}
              </ul>
            </Row>
          </Container>
          {category_banner !== null ? (
            <div className="full-width-container category-banner">
              {/* {console.log(products.data[0])} */}
              {params.sub_catId ? (
                <img
                  src={
                    products.data.length > 0 &&
                    (products.data[0].subcategory.banner_image !== null
                      ? products.data[0].subcategory.banner_image
                      : CategoryBanner)
                  }
                  alt={"banner"}
                  className="w-100"
                />
              ) : (


                <img
                  src={
                    products.data.length > 0 &&
                    (products.data[0].category.banner_image !== null
                      ? products.data[0].category.banner_image
                      : CategoryBanner)
                  }
                  alt={"banner"}
                  className="w-100"
                />
              )}
            </div>
          ) : null}
        </div>

        {category_banner && (
          <BannerInner
            title="All categories"
            imgalt="new_img"
            image={category_banner.image}
          />
        )}

        {/* <BannerInner
      title="All categories"
      imgalt="new_img"
      image={Bannerimg}
    /> */}
        <section>
          <div className="full-width-container banner">
            <div className="banner-container container">
              <div className="row banner-row">
                <div className="col-md-3 banner-3">
                  <div className="wrap-side-info">
                    <div className="navItem">
                      <Accordion defaultActiveKey="1">
                        <Card className="all_category_card">
                          <Accordion.Toggle as={Card.Header} eventKey="1">
                            All Categories
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <ul className="category_list">
                              <div className="all-button">
                                <button
                                  onClick={() => {
                                    applyFilter({
                                      page: 1,
                                      category_slug: undefined,
                                      sub_category_slug: undefined,
                                    });
                                    history.push("/view-all");
                                  }}
                                >
                                  All
                                </button>
                              </div>
                              {/* {categories.map((item) => {
                            const { id, name, product_count, sub_category } = item;
                            if (sub_category.length > 0) {
                              sub_category.map((subCat) => {
                                return (
                                  <li key={subCat.id}>
                                    <Link to={`/category-detail/${subCat.id}`} onClick={() => applyFilter({ category_id: subCat.id })}>
                                      {subCat.name}
                                    </Link>
                                  </li>
                                );
                              });
                            }

                            return (
                              <li key={id}>
                                <Link to={`/category-detail/${id}`} onClick={() => applyFilter({ category_id: id })}>
                                  {name} <span>({product_count})</span>
                                </Link>
                              </li>
                            );
                          })} */}

                              {category.map((item, i) => {
                                const { sub_category, id, name, slug } = item;
                                if (sub_category.length > 0) {
                                  return (
                                    <li className={this.state.class}>
                                      <NavLink
                                        to={`/${slug !== null ? slug : id}`}
                                        onClick={() => {
                                          applyFilter({
                                            category_slug: item.slug,
                                            sub_category_slug: undefined,
                                            page: 1,
                                          });
                                          window.scroll({ top: 0 });
                                        }}
                                      >
                                        {name}
                                      </NavLink>
                                      <div
                                        className={`back_button accordion-${i}`}
                                        onClick={() => this.showChild(i)}
                                      >
                                        <span>
                                          <FaAngleDown />
                                        </span>
                                      </div>
                                      <ul className="subCategory">
                                        {sub_category.map((subCat) => (
                                          <li>
                                            <NavLink
                                              to={`/${slug !== null ? slug : id
                                                }/${subCat.slug !== null
                                                  ? subCat.slug
                                                  : subCat.id
                                                }`}
                                              onClick={() =>
                                                applyFilter({
                                                  page: 1,
                                                  sub_category_slug:
                                                    subCat.slug,
                                                  category_slug: undefined,
                                                })
                                              }
                                            >
                                              {subCat.name}
                                            </NavLink>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  );
                                }
                                return (
                                  <li>
                                    <NavLink
                                      to={`/${slug !== null ? slug : id}`}
                                      onClick={() =>
                                        applyFilter({
                                          page: 1,
                                          category_slug: item.slug,
                                          sub_category_slug: undefined,
                                        })
                                      }
                                    >
                                      {item.name}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Collapse>
                        </Card>

                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="3">
                            Brands
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="3">
                            <ul className="category_list">
                              {brands.map((item) => {
                                const { id, name, product_count } = item;
                                return (
                                  <li key={id}>
                                    <Link
                                      to={
                                        params.id
                                          ? `/${params.id}`
                                          : `/view-all`
                                      }
                                      onClick={() =>
                                        applyFilter({
                                          limit: 24,
                                          page: 1,
                                          order: 'asc',
                                          brand_id: id
                                        })
                                      }
                                    >
                                      {name} <span>({product_count})</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="section_none">
                          <Accordion.Toggle as={Card.Header} eventKey="4">
                            Best Seller
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="4">
                            <ul className="category_list best_seller_wrap">
                              {best_selling_product.data.map((item) => {
                                const { id, title, regular_price, sale_price, category, subcategory, slug
                                } =
                                  item;
                                return (
                                  <li key={id}>
                                    <Link
                                      to={`/${category?.slug}/${subcategory?.slug}/${slug}`}
                                      // onClick={() => this.getProductDetail(id)}
                                      className="best_seller"
                                    >
                                      <span className="seller_name">
                                        {title.slice(0, 100)}
                                      </span>
                                      {sale_price !== null ? (
                                        <>
                                          <span className="regural_price">
                                            Regular Price:
                                            <del>
                                              <BiRupee />
                                              {Number(
                                                regular_price.toLocaleString(
                                                  "en-IN"
                                                )
                                              ).toFixed(0)}
                                            </del>
                                          </span>
                                          <span className="offer_price">
                                            Special Price:
                                            <b>
                                              <BiRupee />
                                              {Number(
                                                sale_price.toLocaleString(
                                                  "en-IN"
                                                )
                                              ).toFixed(0)}
                                            </b>
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="offer_price">
                                            Special Price:
                                            <b>
                                              <BiRupee />
                                              {Number(
                                                sale_price.toLocaleString(
                                                  "en-IN"
                                                )
                                              ).toFixed(0)}
                                            </b>
                                          </span>
                                        </>
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                  <div className="rang_slider  latest-product">
                    <div className="heading">
                      <h6>Price</h6>
                    </div>
                    <RangeSlider
                      handleChange={this.onPriceChange}
                      maxValue={product_max_price}
                    />
                  </div>
                  <div className="latest-product">
                    <div className="heading">
                      <h6>Latest Product</h6>
                    </div>
                    {data.map((item, index) => {
                      const {
                        id,
                        title,
                        image_url,
                        sale_price,
                        avg_rating,
                        slug,
                      } = item;
                      return (
                        <div className="wrap-product" key={id}>
                          <div className="media">
                            <Link
                              to={`/${cat_id}/${sub_catId}/${slug !== null ? slug : id
                                }`}
                              onClick={() => {
                                this.getProductDetail(id, slug);
                              }}
                            >
                              <img alt="img" src={image_url} className="mr-3" />
                            </Link>
                            <div className="media-body">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id={`tooltip-top`}
                                    style={{
                                      "font-size": "12px",
                                      "text-transform": "capitalize",
                                    }}
                                  >
                                    {title}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/${cat_id}/${sub_catId}/${slug !== null ? slug : id
                                    }`}
                                  onClick={() => {
                                    this.getProductDetail(id, slug);
                                  }}
                                >
                                  <h6 className="mt-0">
                                    {title.slice(0, 100)}
                                  </h6>
                                </Link>
                              </OverlayTrigger>

                              <div className="review">
                                <ReactStars
                                  count={5}
                                  onChange={this.ratingChanged}
                                  size={16}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                  value={avg_rating}
                                />
                              </div>
                              <p>
                                <BiRupee />
                                {Number(sale_price).toFixed(0)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-md-9 banner-9">
                  <div className="result">
                    <div className="row result-row">
                      <div className="col-lg-5 col-md-3 result-5">
                        <Link
                          className={
                            this.state.selectedView === "grid" ? "active" : ""
                          }
                        >
                          <i
                            className="fa fa-th-large"
                            onClick={() => {
                              this.changeView("grid");
                            }}
                          ></i>
                        </Link>
                        <Link
                          className={
                            this.state.selectedView === "list" ? "active" : ""
                          }
                        >
                          <i
                            className="fa fa-th-list"
                            onClick={() => {
                              this.changeView("list");
                            }}
                          ></i>
                        </Link>
                        <Link>
                          {/* <p>
                        Showing {this.props.products.from}–
                        {this.props.products.to} of{" "}
                        {this.props.products.total} results
                      </p> */}
                        </Link>
                      </div>
                      <div className="col-lg-7 col-md-9 result-7">
                        <span>Shop order</span>
                        <select
                          name="webmenu"
                          id="webmenu"
                          onChange={(e) => this.onSorting(e.target.value)}
                        >
                          <option value="default" selected>
                            Sorting
                          </option>
                          <option value="asc">Low - High</option>
                          <option value="desc">High - Low</option>
                        </select>
                        <span className="pl-3">Show</span>
                        <select
                          name="webmenu"
                          onChange={(e) => this.onSelectLimit(e)}
                        >
                          <option selected={true} defaultValue="24">
                            24
                          </option>
                          <option value="36">36</option>
                          <option value="48">48</option>
                          <option value="60">60</option>
                          <option value="72">72</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* {this.state.selectedView==='grid' ? <GridView /> : <ListView /> } */}

                  {this.state.selectedView === "grid" ? (
                    <div className="row featured-row mt-4">
                      {products.data.map((item, index) => {
                        const {
                          offers,
                          title,
                          avg_rating,
                          image_url,
                          part_number,
                          sale_price,
                          id,
                          product_review_count,
                          brand_value,
                          stock_qty,
                          regular_price,
                          slug,
                          category,
                          subcategory,
                        } = item;

                        return (
                          <div
                            className="item col-lg-3 col-md-6 col-sm-6"
                            key={id}
                          >
                            <div className="card">
                              <Link
                                className={stock_qty <= 0 && "outofstock"}
                                to={`/${category?.slug}/${subcategory?.slug}/${slug !== null ? slug : id
                                  }`}
                              >
                                <img
                                  src={image_url}
                                  className="card-img-top"
                                  alt="Prod1"
                                />
                                {/* <span>-17%</span> */}
                              </Link>
                              <div className="card-body featured-body">
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 0, hide: 400 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-top`}
                                      style={{
                                        "font-size": "12px",
                                        "text-transform": "capitalize",
                                      }}
                                    >
                                      {title}
                                    </Tooltip>
                                  }
                                >
                                  <Link
                                    to={`/${category?.slug}/${subcategory?.slug
                                      }/${slug !== null ? slug : id}`}
                                  >
                                    <h6 className="card-title">
                                      {title.slice(0, 30)}
                                    </h6>
                                  </Link>
                                </OverlayTrigger>
                                {/* <small>Part Number: {part_number}</small> */}
                                <p>
                                  Brand:{" "}
                                  {brand_value &&
                                    brand_value.name.slice(0, 100)}
                                </p>
                                {offers && (
                                  <span className="offer-tag">
                                    {offers.discount}%
                                  </span>
                                )}
                                 <div className="review_inner --m">
                                    <span className="text left">({avg_rating||0})</span>
                                    <ReactStars count={1} onChange={this.ratingChanged} size={22} edit={false} isHalf={true} color="#008000" activeColor="#008000" value={avg_rating} />
                                    <span className="text right">({product_review_count})Reviews</span>
                                  </div>

                                <div className="cart-detail  2">
                                  {sale_price !== null ? (
                                    <h5>
                                      <BiRupee />
                                      {Number(
                                        sale_price
                                      ).toFixed(0)}
                                    </h5>
                                  ) : (
                                    <h5>
                                      <BiRupee />
                                      {regular_price !== null &&
                                        Number(
                                          regular_price
                                        ).toFixed(0)}
                                    </h5>
                                  )}

                                 
                                </div>
                                {sale_price !== null && (
                                  <>
                                    <del>
                                      <BiRupee />
                                      {regular_price !== null &&
                                        Number(
                                          regular_price
                                        ).toFixed(0)}
                                    </del>{" "}
                                    <small>
                                      {(
                                        ((Number(regular_price) -
                                          Number(sale_price)) /
                                          Number(regular_price)) *
                                        100
                                      ).toFixed(0)}{" "}
                                      % OFF
                                    </small>
                                  </>
                                )}
                                <div className="review actions-btn">

                                  <div className="add-wishlist" data-tip="Add to Wishlist" onClick={() => this.saveProductByUser(id)}>
                                    <i className="fa fa-heart"></i>
                                  </div>
                                  <i className={`fa fa-shopping-cart ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && this.addToCart(item)}></i>
                                </div>
                              </div>

                              {/* <ul className="social">
                                <li>
                                  <div
                                    data-tip="Add to Wishlist"
                                    onClick={() => this.saveProductByUser(id)}
                                  >
                                    <i className="fa fa-heart"></i>
                                  </div>
                                </li>
                              </ul> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="row listing-row mt-4">
                      {this.props.products.data.map((item) => {
                        const {
                          id,
                          title,
                          avg_rating,
                          image_url,
                          part_number,
                          regular_price,
                          sale_price,
                          stock_qty,
                          slug,
                          subcategory,
                          category,
                        } = item;
                        return (
                          <div className="item" key={id}>
                            <div className="card item-card mb-3">
                              <div className="row item-row no-gutters">
                                <div className="col-md-3 left-3">
                                  <Link
                                    to={`/${category?.slug}/${subcategory?.slug
                                      }/${slug !== null ? slug : id}`}
                                  >
                                    <img
                                      src={image_url}
                                      className="card-img-top"
                                      alt="Prod1"
                                    />
                                  </Link>
                                  <span>sale</span>
                                </div>
                                <div className="col-md-6 item-6">
                                  <div className="card-body">
                                    <Link
                                      to={`/${category?.slug}/${subcategory?.slug
                                        }/${slug !== null ? slug : id}`}
                                    >
                                      <h6 className="card-title">
                                        {title.slice(0, 30)}
                                      </h6>
                                    </Link>
                                    <div className="review">
                                      <ReactStars
                                        count={5}
                                        onChange={this.ratingChanged}
                                        size={16}
                                        isHalf={true}
                                        edit={false}
                                        activeColor="#ffd731"
                                        value={avg_rating}
                                      />
                                      <span className="text">1 Review</span>
                                    </div>
                                    <div className="des">
                                      <ul>
                                        <li>Bulb Technology: Halogen</li>
                                        <li>Bulb Color: Clear</li>
                                        <li>Package Quantity: {stock_qty}</li>
                                        <li>Power: 40 MW</li>
                                        <li>Voltage: 12 VDC</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 right-3">
                                  {sale_price !== null ? (
                                    <h5>
                                      <span>
                                        {Number(
                                          sale_price
                                        ).toFixed(2)}
                                      </span>
                                      <span>
                                        <del>
                                          {Number(
                                            regular_price
                                          ).toFixed(0)}
                                        </del>
                                      </span>
                                    </h5>
                                  ) : (
                                    <h5>
                                      <span>
                                        {Number(
                                          regular_price
                                        ).toFixed(0)}
                                      </span>
                                    </h5>
                                  )}

                                  <div className="add">
                                    <Link
                                    // onClick={() => addToCartList(item)}
                                    >
                                      Add to cart
                                    </Link>
                                  </div>
                                  <p>
                                    <i className="fa fa-heart"></i>
                                    <Link
                                      onClick={() => this.saveProductByUser(id)}
                                    >
                                      Add to wishlist
                                    </Link>
                                  </p>
                                  {/* <p>
                                <MdCompareArrows /> 
                                  <Link >Add to compare</Link>
                                </p> */}
                                  <div className="sku">
                                    {/* <p>Part number: {part_number}</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {products.data.length > 0 ? (
                    <div className="card pagination hello">
                      {/* <Pagination>
                      <Pagination.Prev
                        disabled={this.props.products.current_page === 1}
                        onClick={() => {
                          applyFilter({
                            page: this.props.products.current_page - 1,
                          });
                          window.scroll({ top: 0 });
                        }}
                      />
                      {this.onPagination()}
                      <Pagination.Next
                        disabled={this.props.products.current_page === this.props.products.last_page}
                        onClick={() => {
                          applyFilter({
                            page: this.props.products.current_page + 1,
                          });
                          window.scroll({ top: 0 });
                        }}
                      />
                    </Pagination> */}
                      <div className="pagaination-wrap">
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(
                            products.total / products.per_page
                          )}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={Number(products.current_page - 1)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="not-product">
                      <h4>No Products Found...!</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    AppReducer: {
      latest_products,
      products,
      home: { best_selling_product, category },
      sideCategory,
      categories,
      brands,
      filter,
      category_banner,
      offerList,
      cart,
      product_max_price,
    },
    authReducer: { user },
  } = state;
  return {
    latest_products,
    products,
    sideCategory,
    best_selling_product,
    user,
    categories,
    brands,
    filter,
    category_banner,
    offerList,
    category,
    cart,
    product_max_price,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLatestProduct: () => dispatch(globalAction(GET_LATEST_PRODUCTS)),
    // getCategories: () => dispatch(globalAction(GET_CATEGORY)),
    saveProduct: (data) => dispatch(globalAction(SAVE_PRODUCT, data)),
    getProductDetailById: (params) =>
      dispatch(globalAction(GET_PRODUCT_DETAILS, params)),
    getSearchProduct: (param, history) =>
      dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: history })),
    // getBrands: () => dispatch(globalAction(GET_BRAND_LIST)),
    // addToCartList: (data) => dispatch(globalAction(ADD_TO_CART, data)),
    showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
    applyFilter: (data) => dispatch(globalAction(APPLY_FILTER, data)),
    getHomeData: () => {
      dispatch(globalAction(GET_HOME_DATA));
    },
    addToCart: (data) => {
      dispatch(globalAction(ADD_TO_CART, data));
    },

    // getOfferList: () => dispatch(globalAction(GET_OFFER_LIST)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);