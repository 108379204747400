
import React, { Component } from 'react'
import banner from '../../../assets/blog-banner.jpg'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import parse from 'react-html-parser'
import '../blog/blog.scss'
import { ADD_LIKE_UNLIKE, GET_BLOG, GET_BLOG_CATEGORY_LIST, globalAction, SHOW_TOAST } from '../../../redux';
import moment from 'moment';
import { getBlogs, getPopularBlogsApi } from '../../../api';


class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 5

        }
    }

    componentDidMount() {
        window.scroll({ top: 0 })
        const { page } = this.state;
        const { getBLogs, match: { params: { id } }, location: { state }, getBlogCategory } = this.props;
        if (id) {
            getBLogs("?category_id=" + id)
        } else if (state) {
            getBLogs("?search=" + state.search)
        } else {
            getBLogs(`?limit=${page}`)
        }
        getPopularBlogsApi().then(res => {
            const { data, status } = res;
            if (status === 200) {
                this.setState(data.popular_blogs)
            }
        })
        getBlogCategory();
    }



    onLikeUnlike = (id, status) => {
        const { user, addLikeUnlike, showToast, history } = this.props;
        if (user) {
            addLikeUnlike({ user_id: user.id, blog_id: id, status })
        } else {
            const data = {
                msg: "Please login or Register to like this product.",
                status: 'warning'
            }
            showToast({ data })
            setTimeout(() => {
                history.replace('/login', { from: "/blog" })
            }, 1000);
        }
    }

    handleLoadMore = ((e, page) => {
        e.preventDefault();
        this.setState({ page: page + 5 })
        this.props.getBLogs(`?limit=${page + 5}`)
    })

    render() {
        const { blog, getBLogsList, blog_categoryList, history } = this.props;
        const { data, page } = this.state;
        return (
            <>
                <div className="full-width-container blog-banner">
                    <img src={banner} alt={banner} className="w-100" />
                    <h1>Blogs</h1>
                </div>
                <div className="full-width-container blog">
                    <div className="container blog-container">
                        <div className="row blog-row">
                            <div className="col-md-8 blog-8">
                                {blog.data.length>0
                                ?
                                <article>
                                    {blog.data.map(item => {

                                        const { id, image, title, body, user, created_at, like_count } = item;
                                        return (
                                            <div class="card blog-card mb-4" key={id}>
                                                <div class="row no-gutters">
                                                    <div class="col-md-5">
                                                        <Link to={`/blog-detail/${id}`}><img src={image} class="card-img" alt="..." /></Link>
                                                    </div>
                                                    <div class="col-md-7 blog-content">
                                                        <div class="card-body">
                                                            <Link to={`/blog-detail/${id}`} class="card-title">{title}</Link>
                                                            <Link to={`/blog-detail/${id}`} className="heading"><p>{parse(body.length > 410 ? body.substring(0, 410) + "..." : body)}</p></Link>

                                                        </div>
                                                        <div className="object-flex">
                                                            {user &&
                                                                <div className="object-img">
                                                                    <div class="media">
                                                                        <img src={user && user.profile_image} class="mr-3" alt="profile" />
                                                                        <div class="media-body">
                                                                            <h6 class="mt-0">{user && user.name}</h6>
                                                                            <p>{moment(created_at, "YYYYMMDD").fromNow()}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className="object-icon">
                                                                {/* <Link><i class="fa fa-bookmark"></i></Link> */}
                                                                <span >({like_count}) <i class="fa fa-thumbs-up" onClick={() => this.onLikeUnlike(id, 1)}></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {blog.current_page === blog.last_page
                                        ?
                                        ''
                                        :
                                        <div className="load-more" >
                                            <Link
                                                to={`/blog/${page + 1}`}
                                                onClick={(e) => { this.handleLoadMore(e, page) }}
                                            >
                                                Load more posts
                                            </Link>
                                        </div>
                                    }


                                </article>
                                :
                                <div className="no-blog-wrap">
                                    <h1> No blog found in this category</h1>
                                </div>
                                }
                            </div>
                            <div className="col-md-4 blog-4">
                                <div className="widget" >
                                    <div className="bs-widget-content">
                                        <h4 className="bs-widget-title">Categories</h4>
                                        <ul className="services-widget-content">
                                            {blog_categoryList.map(item => {
                                                const { name, id } = item
                                                return (
                                                    <li key={id}>
                                                        <Link
                                                         onClick={async () => {
                                                            await getBLogsList("?category_id=" + id)
                                                            await setTimeout(() => {
                                                                history.replace(`/blog/${id}`)
                                                            }, 100);
                                                        }}>{name}
                                                            <span className="float-end">
                                                                <i className="fa fa-long-arrow-right" />
                                                            </span>
                                                        </Link>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </div>
                                <div className="widget-area">
                                    <div className="heading">
                                        <h6>Most Popular</h6>
                                    </div>
                                    {data && data.map(item => {
                                        const { id, image, title, created_at } = item;
                                        return (
                                            <div className="sidebar-widget mb-4" key={id}>
                                                <div class="media sidebar-media">
                                                    <Link to={`/blog-detail/${id}`}><img src={image} class="mr-3" alt="blog2" /></Link>
                                                    <div class="media-body sidebar-body">
                                                        <Link to={`/blog-detail/${id}`}><h6 class="mt-0">{title}</h6></Link>
                                                        <ul>
                                                            <li>{moment(created_at, "YYYYMMDD").fromNow()}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}


                                    {/* <div className="sidebar-widget mb-4">
                                        <div class="media sidebar-media">
                                            <Link><img src={blog2} class="mr-3" alt="blog2" /></Link>
                                            <div class="media-body sidebar-body">
                                                <Link><h6 class="mt-0">9 Things I Love About Shaving My Head During Quarantine</h6></Link>
                                                <ul>
                                                    <li>September 15, 2021</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sidebar-widget mb-4">
                                        <div class="media sidebar-media">
                                            <Link><img src={blog2} class="mr-3" alt="blog2" /></Link>
                                            <div class="media-body sidebar-body">
                                                <Link><h6 class="mt-0">9 Things I Love About Shaving My Head During Quarantine</h6></Link>
                                                <ul>
                                                    <li>September 15, 2021</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sidebar-widget mb-4">
                                        <div class="media sidebar-media">
                                            <Link><img src={blog2} class="mr-3" alt="blog2" /></Link>
                                            <div class="media-body sidebar-body">
                                                <Link><h6 class="mt-0">9 Things I Love About Shaving My Head During Quarantine</h6></Link>
                                                <ul>
                                                    <li>September 15, 2021</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const { AppReducer: { blog, blog_categoryList }, authReducer: { user } } = state;
    return { blog, user, blog_categoryList }
}
const mapDispatchToProps = dispatch => {
    return {
        getBLogs: (data) => dispatch(globalAction(GET_BLOG, data)),
        addLikeUnlike: (data) => dispatch(globalAction(ADD_LIKE_UNLIKE, data)),
        showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
        getBLogsList: (data) => dispatch(globalAction(GET_BLOG, data)),
        getBlogCategory: () => dispatch(globalAction(GET_BLOG_CATEGORY_LIST)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Blog)