import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "./userSidebar";
import UserDetails from "./userDetails/userDetails";
import UserAccount from "./userAccount/userAccount";
import SavedProducts from "./savedProducts/savedProducts";
// import OrderList from './myOrders/MyOrder'
// import OrderDetail from './myOrders/orderDetail';
import { Switch, Route } from "react-router-dom";
import "./userDashboard.scss";
import MyOrder from "./myOrders/MyOrder";
import ChooseAddress from "./Choose-Address/ChooseAddress";
import UpdateAddress from "./UpdateAddress/UpdateAddress";
import NewAddress from "./NewAddress/AddNewAddress";
import { useSelector, useDispatch } from "react-redux";
import { globalAction, GET_USER_SAVED_ADDRESS } from "../../redux";
import OrderDetail from "../userDashboard/OrderDetail/OrderDetail";
import BulkEnquiry from "./Bluk-Enquiry/BulkEnquiry";

// import NotFound from '../notFound/notFound';

export default function UserDashboard(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  useEffect(() => {
    if (user !== null) {
      dispatch(globalAction(GET_USER_SAVED_ADDRESS, user.id));
    }
  }, [dispatch]);

  return (
    <>
      <Row className="main">
        <Col md={3} className="sidbar">
          <Sidebar />
        </Col>
        <Col md={9} className="dashboard-right">
          <Switch>
            {/* <Route exact path={`/dashboard/order-detail/:id`} component={OrderDetail} /> */}
            <Route exact path="/dashboard/my-account" component={UserAccount}  />
            <Route exact path="/dashboard/saved-products" component={SavedProducts} />
            <Route exact path="/dashboard/my-order" component={MyOrder} />
            <Route exact path="/dashboard" component={UserDetails} />
            <Route exact path="/dashboard/Address" component={ChooseAddress} />
            <Route exact path="/dashboard/Update-address" component={UpdateAddress} />
            <Route exact path="/dashboard/new-address" component={NewAddress} />
            <Route exact path="/dashboard/order-detail/:id" component={OrderDetail} />
            <Route exact path="/dashboard/bulk-enquiry" component={BulkEnquiry} />

            {/* <Route component={NotFound} /> */}
          </Switch>
        </Col>
      </Row>
    </>
  );
}
