import React from "react";
import "./banner.scss";
// import Bannerimg from "../../layouts/image/contactimg.jpg";
import { Container } from "react-bootstrap";

function BannerInner({ title, image,  imgalt}) {
  return (
    <section className="banner header_banner banner-inner">
      <div className="banner_inner">
        <div className="banner_img">
          <img src={image} alt={imgalt} />
        </div>
        <div className="banner_content">
          <Container>
            <h1>{title}</h1>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default BannerInner;
