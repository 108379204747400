
import React, { Component } from 'react'
import parse from 'react-html-parser'
import banner from '../../../assets/blog-banner.jpg';
import { Link } from 'react-router-dom';
import tempUser from '../../../assets/users.png'
import { Form, Col, Button } from 'react-bootstrap';
import '../blog_detail/blog_detail.scss'
import { connect } from 'react-redux'
import { globalAction } from '../../../redux';
import { ADD_BLOG_COMMENT, ADD_LIKE_UNLIKE, GET_BLOG, GET_BLOG_CATEGORY_LIST, GET_BLOG_COMMENTS, GET_BLOG_DETAILS, SHOW_LOADING, SHOW_TOAST } from '../../../redux/actionTypes';
import moment from 'moment';
import AnimatedLogo from '../../AnimatedLogo/AnimatedLogo'
//import {ShareSocial} from 'react-share-social' 
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon, WhatsappIcon } from "react-share";
import SeoSetting from '../../../components/pages/seoSetting/seoSetting';

import Api from '../../../api/globals/api_constants'

const { liveUrl } = Api;
let supersonicUrl = "https://admin.supersonicparts.com/";
class BlogDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blog: {
                title: "",
                body: "",
                image: null,
                updated_at: "",
                tags: null,
                created_at: null,
                user: null,
                seo_title: "",
                meta_keywords: "",
                seo_category: "",
                seo_tags: "",
                meta_descriptions: ""


            },
            popular_tags: {
                tags: null
            },
            recent_posts: [],
            blogCategory: [],
            comment: "",
        }
    }

    componentDidMount() {
        const { match: { params: { id } }, showLoading, getBlogCategory, blog_categoryList } = this.props;
        console.log(blog_categoryList)
        showLoading()
        // getBlogCategoryApi().then(res=>{
        //     const {status,data}=res;
        //     if(status===200){
        //         this.setState({blogCategory:data.blog_category.data})
        //         this.getBlogComments("?id="+id)
        //     }
        // })
        if (blog_categoryList.length <= 0) {
            getBlogCategory()
        }

        this.getBlogById(id)

    }
    getBlogById = async (id) => {
        const { getBlogDetails } = this.props;
        await getBlogDetails(id)
        await this.getBlogComments("?id=" + id)


    }
    onSubmitComment = (e) => {
        e.preventDefault();
        const { match: { params: { id } }, user, showToast, history, addComment } = this.props;
        const { comment } = this.state;
        if (user) {
            addComment({ user_id: user.id, blog_id: id, comment })
        } else {
            const data = {
                msg: "Please login or Register to comment on this blog.",
                status: 'warning'
            }
            showToast({ data })
            setTimeout(() => {
                history.replace('/login', { from: "/blog-detail/" + id })
            }, 1000);
        }
    }

    getBlogComments = (param) => {
        const { getBlogsComment } = this.props;
        getBlogsComment(param)
    }

    onCommnetPagination = (page, text) => {
        const { match: { params: { id } } } = this.props;
        if (text === 'next') {
            this.getBlogComments(`?id=${id}&page=${page + 1}`)
        } else {
            this.getBlogComments(`?id=${id}&page=${page - 1}`)
        }
    }


    onLikeUnlike = (status) => {
        const { match: { params: { id } }, user, addLikeUnlike, showToast, history } = this.props;
        if (user) {
            addLikeUnlike({ user_id: user.id, blog_id: id, status })
        } else {
            const data = {
                msg: "Please login or Register to like this product.",
                status: 'warning'
            }
            showToast({ data })
            setTimeout(() => {
                history.replace('/login', { from: "/blog-detail/" + id })
            }, 1000);
        }
    }

    onSearchBlog = async () => {
        const { getBLogsList, history } = this.props;
        const { search } = this.state;
        console.log('search', search)
        await getBLogsList("?search=" + search)
        await setTimeout(() => {
            history.replace({
                pathname: '/blog',
                state: { search }
            })
        }, 1000);


    }
    onChangeText = (text, value) => {
        let state = this.state;
        state[text] = value;
        this.setState(state)
    }

    render() {
        const { blogDetails: { title, body, created_at, comment_count, like_count, image,
            seo_title,
            meta_keywords,
            seo_category,
            seo_tags,
            meta_descriptions }, popular_tags, recent_posts, blogComments, user, getBLogsList, history, isLoading, history: { location: { pathname } }, blog_categoryList } = this.props;
            console.log(this.props)
        return (
            <>
                <SeoSetting 
                title={seo_title} 
                metaDescription={meta_descriptions} 
                metaKeywords={meta_keywords}
                tags={seo_tags}
                metaCategory={seo_category}/>
           
                
                {isLoading ? <AnimatedLogo /> :
                    <>
                        <div className="full-width-container blog-banner">
                            <img src={banner} alt={banner} className="w-100" />
                            {/* <h1>Blog Detail</h1> */}
                        </div>
                        <div id="main-content" className="blog-page">
                            <div className="container-md">
                                <div className="row clearfix">
                                    <div className="col-md-8 blog-8">
                                        <div className="blogs_content">

                                        </div>
                                        <h4 className="blog-title">
                                            <Link>{title}</Link>
                                        </h4>
                                        {/* <div className="content pt-3">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div> */}
                                        <img src={image} className="w-100 pt-4 pb-4" alt="car1" />
                                        <div className="content">
                                            {parse(body)}
                                        </div>
                                        {/* <div className="blog-quote mt-4 mb-4">
                                    <h6>Designing Ethically, Optimizing Videos, And Shining The Spotlight On Our SmashingConf SpeakersAn Interview With Rémi Parmentier: A SmashingConf Austin Speaker</h6>
                                    <span><i class="fa fa-quote-left pr-3" />Norman S. Roberts</span>
                                </div> */}
                                        <div className="row blog-share-area">
                                            <div className="col-md-4">
                                                <div className="social-tag">
                                                    {/* <span>Tags :  {tags}</span> */}
                                                    {/* <Link>Business Cart, </Link>
                                            <Link>Design &amp; Branding</Link> */}
                                                    {user &&
                                                        <div className="like-section">
                                                            {console.log(like_count)}
                                                            <span>({like_count && like_count})</span>
                                                            {like_count <= 0
                                                                &&
                                                                <i className="fa fa-thumbs-up" onClick={() => this.onLikeUnlike(1)} />
                                                            }

                                                            {like_count >= 1
                                                                &&
                                                                <i className="fa fa-thumbs-down" onClick={() => this.onLikeUnlike(2)} />
                                                            }


                                                            {/* <span>({deslike_count && deslike_count})</span>   
                                            
                                            {/* <button onClick={()=>this.onLikeUnlike()}>Like ({like_count && like_count}) / Unlike</button> */}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="footer_social justify-content-md-end">
                                                    <span className="date-view"> <i className="fa fa-calendar pr-2" />{moment(created_at).format("LL")}</span>

                                                    <span>Share :  </span>
                                                    <div className="share-social">
                                                        <FacebookShareButton
                                                            url={supersonicUrl.substring(0, liveUrl.length - 1) + pathname}
                                                            title={"Facebook"}
                                                            hashtag={"#hashtag"}
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton
                                                            url={liveUrl.substring(0, liveUrl.length - 1) + pathname}
                                                            title={"Facebook"}
                                                            hashtag={"#hashtag"}
                                                        >
                                                            <TwitterIcon size={32} round />
                                                        </TwitterShareButton>
                                                        <LinkedinShareButton
                                                            url={liveUrl.substring(0, liveUrl.length - 1) + pathname}
                                                            title={"Linkdin"}
                                                            hashtag={"#hashtag"}
                                                        >
                                                            <LinkedinIcon size={32} round />
                                                        </LinkedinShareButton>
                                                        <EmailShareButton
                                                            url={liveUrl.substring(0, liveUrl.length - 1) + pathname}
                                                            title={"Email"}
                                                            hashtag={"#hashtag"}
                                                        >
                                                            <EmailIcon size={32} round />
                                                        </EmailShareButton>
                                                        <WhatsappShareButton
                                                            url={liveUrl.substring(0, liveUrl.length - 1) + pathname}
                                                            title={"Whatsapp"}
                                                            hashtag={"#hashtag"}
                                                        >
                                                            <WhatsappIcon size={32} round />
                                                        </WhatsappShareButton>

                                                    </div>

                                                    {/* <Link>
                                                <i className="fa fa-facebook-f" />
                                            </Link>
                                            <Link>
                                                <i className="fa fa-twitter" />
                                            </Link>
                                            <Link>
                                                <i className="fa fa-youtube" />
                                            </Link>
                                            <Link>
                                                <i className="fa fa-linkedin" />
                                            </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gauthors">
                                            <div className="media gauthors-media">
                                                <img src={this.props.blogDetails.user && this.props.blogDetails.user.profile_image} className="mr-4" alt="blog-user" />
                                                <div className="media-body gauthors-body">
                                                    <h5 className="mt-0">{this.props.blogDetails.user && this.props.blogDetails.user.name}</h5>
                                                    <p>Author</p>
                                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="write">
                                    <h4><Link>Write a review</Link></h4>
                                </div> */}
                                        <div className="comments">
                                            <h5>Comment ({comment_count && comment_count})</h5>
                                            <div className="comment-option">
                                                {blogComments && blogComments.data.map(item => {
                                                    const { user, id, comment } = item;
                                                    return (
                                                        <div className="media mb-4" key={id}>
                                                            <Link className="comment-img"><img src={user.profile_image !== null ? user.profile_image : tempUser} className="mr-3" alt="user" /></Link>
                                                            <div className="media-body">
                                                                <h6 className="mt-0"><Link>{user.name}</Link></h6>
                                                                {/* <span>static</span> */}
                                                                <p>{comment}</p>
                                                                {/* <Link className="reply-comments">Reply Comments<i className="fa fa-arrow-right pl-2" /></Link> */}
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                                <div className="see-more">
                                                    {blogComments.current_page > 1 ? <button onClick={() => this.onCommnetPagination(blogComments.current_page, "prev")}>Prev</button> : null}

                                                    {blogComments.current_page < blogComments.last_page ?
                                                        <button onClick={() => this.onCommnetPagination(blogComments.current_page, "next")}>Next</button>
                                                        : null}
                                                </div>


                                            </div>
                                        </div>
                                        <div className="contact-form-area">


                                            <h5>Reply Comments</h5>

                                            <Form className="contact-form" onSubmit={this.onSubmitComment} id="abc">
                                                <Form.Row>
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Control type="text" as="textarea" rows={5} placeholder="Comments"
                                                            onChange={(e) => this.setState({ comment: e.target.value })}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter your comments.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" className="submit">
                                                        <Button className="btn" type="submit">Post</Button>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>

                                        </div>
                                    </div>
                                    <div className="col-md-4 blog-4">
                                        <div className="blogs-standard-right">
                                            <div className="widget px-0 py-0 mb-30">
                                                <form className="widget-search" action="#">
                                                    <input type="text" placeholder="Search Keywoards" onChange={(e) => this.onChangeText('search', e.target.value)} />
                                                    <span><i className="fa fa-search" onClick={() => this.onSearchBlog()} /></span>
                                                </form>
                                            </div>
                                            <div className="widget" >
                                                <div className="bs-widget-content">
                                                    <h4 className="bs-widget-title">Categories</h4>
                                                    <ul className="services-widget-content">
                                                        {blog_categoryList.map(item => {
                                                            const { name, id } = item
                                                            return (
                                                                <li key={id}>
                                                                    <Link onClick={async () => {
                                                                        await getBLogsList("?category_id=" + id)
                                                                        await setTimeout(() => {
                                                                            history.replace(`/blog/${id}`)
                                                                        }, 100);
                                                                    }}>{name}
                                                                        <span className="float-end">
                                                                            <i className="fa fa-long-arrow-right" />
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="widget">
                                                <div className="bs-widget-content">
                                                    <h5 className="bs-widget-title">Recent Post</h5>
                                                    {recent_posts.map(item => {
                                                        const { id, created_at, image } = item;
                                                        return (
                                                            <div className="media object-flex mb-4" key={id}>
                                                                <Link to={`/blog-detail/${id}`} onClick={() => this.getBlogById(id)}>
                                                                    <img src={item.image} className="mr-3" alt="object" />
                                                                </Link>

                                                                <div className="media-body object-body">
                                                                    <Link to={`/blog-detail/${id}`} onClick={() => this.getBlogById(id)}>
                                                                        <h6 className="mt-0">{item.title}</h6>
                                                                    </Link>

                                                                    <p><i className="fa fa-calendar" /> {moment(created_at).format("LL")}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                    {/* <div className="media object-flex">
                                                <img src={object} className="mr-3" alt="object" />
                                                <div className="media-body object-body">
                                                    <h6 className="mt-0">Designing Ethically, Optimizing Videos And Shining The Spotlight</h6>
                                                    <p><i className="fa fa-calendar" /> 20 Jan 2021</p>
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>
                                            {/* <div className="widget">
                                        <div className="bs-widget-content">
                                            <h5 className="bs-widget-title">Popular Tags</h5>
                                            <div className="cloudtag">
                                                {popular_tags.tags && popular_tags.tags.split(",").map((item,index)=>{
                                                    return<p key={""+index}>{item}</p>
                                                })}
                                               
                                               
                                            </div>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { authReducer: { user }, AppReducer: { blogComments, blogDetails, popular_tags, recent_posts, blog_categoryList }, errorReducer: { isLoading } } = state;
    return { user, blogComments, blogDetails, popular_tags, recent_posts, isLoading, blog_categoryList }
}
const mapDispatchToProps = dispatch => {
    return {
        showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
        showLoading: () => dispatch(globalAction(SHOW_LOADING)),
        addComment: (data) => dispatch(globalAction(ADD_BLOG_COMMENT, data)),
        getBlogsComment: (data) => dispatch(globalAction(GET_BLOG_COMMENTS, data)),
        getBLogsList: (data) => dispatch(globalAction(GET_BLOG, data)),
        addLikeUnlike: (data) => dispatch(globalAction(ADD_LIKE_UNLIKE, data)),
        getBlogDetails: (data) => dispatch(globalAction(GET_BLOG_DETAILS, data)),
        getBlogCategory: () => dispatch(globalAction(GET_BLOG_CATEGORY_LIST)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail)