import React from "react";
import "./slider.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { BiRupee } from "react-icons/bi";
import CivilLab2 from "../../../assets/product/civil_lab2.jpg";
import CivilLab4 from "../../../assets/product/civil_lab4.jpg";
import { globalAction, SAVE_PRODUCT, SHOW_TOAST } from "../../../redux";

function CivilLabSlider({ products, addToCart }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };
  return (
    <section className="civil_lab_slider">
      <Container>
        <Row>
          <Col>
            {products && products.length > 0 && (
              <OwlCarousel
                className="owl-theme"
                nav={true}
                dots={false}
                loop={true}
                items={2}
                autoplay={true}
                smartSpeed={500}
                slideBy={1}
                responsive={{
                  0: {
                    items: 1,
                  },
                  576: {
                    items: 2,
                  },
                }}
              >
                {products &&
                  products.map((item, index) => {
                    const { id, title, image_url, regular_price, sale_price, stock_qty,slug,category,subcategory } = item;
                    if (index > 1) {
                      return (
                        <div class="item" key={id}>
                          <div className="product">
                            <Link className={stock_qty <= 0 && "outofstock"}
                                to={`/${category.slug}/${subcategory.slug}/${slug!==null?slug:id}`}
                             >
                              <img src={image_url} alt="img" />
                            </Link>
                            <div className="product_body">
                              <p>
                              <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {title}
                          </Tooltip> }
                          >
                                <Link 
                                   to={`/${category.slug}/${subcategory.slug}/${slug!==null?slug:id}`}
                                >{title.slice(0, 30)}...</Link>
                            
                                </OverlayTrigger>    <span>
                                  Regural Price: <BiRupee />
                                  {regular_price}
                                </span>
                                <span>
                                  Special Price: <BiRupee />
                                  {sale_price}
                                </span>
                              </p>
                              <div className="product_icon">
                                <span className="wishlist">
                                  <AiTwotoneHeart onClick={() => saveProductByUser(id)} />
                                </span>
                                <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && addToCart(item)}>
                                  <FaShoppingCart />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </OwlCarousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CivilLabSlider;
