import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Logo from "../../../assets/logo.png";
import { AiOutlineMail } from "react-icons/ai";
import { FaPhoneAlt, FaMobileAlt } from "react-icons/fa";
import FooterBottom from "./FooterBottom";
import { Link, withRouter } from "react-router-dom";
import { footeraboutUsApi } from "../../../api/Api_Call";
import parse from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { GET_CATEGORY, globalAction, GET_SEARCH_PRODUCT, APPLY_FILTER } from "../../../redux";

function Footer({ history }) {
  const {
    categories,
    home: { brands },
  } = useSelector((state) => state.AppReducer);
  const [about, setabout] = useState(null);
  const [search, setSearch] = useState("");
  const [selected_category, setSelected_category] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalAction(GET_CATEGORY));
  }, [dispatch]);

  useEffect(() => {
    footeraboutUsApi().then((res) => {
      const { data, status } = res;
      if (status === 200) {  
        setabout(data.footer);
      }
    });
  }, []);

  const onSearch = (e, categoryId = "", brandId) => {
    window.scroll({ top: 0 })
    // e.preventDefault();
    let param = "";
    if (categoryId !== "") {
      param += `?category_slug=${categoryId}&order=asc`;
    } else {
      param += `?brand_id=${brandId}&order=asc`;
    }
    localStorage.setItem('isSearchValid', 'true')
    localStorage.setItem('brandQuery', brandId)
    dispatch(globalAction(APPLY_FILTER, {brand_id:brandId, category_slug: undefined, sub_category_slug: undefined,page:1 }))
    // dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: history,brandName:brandName }));
  };

  const clickOnBottomLink = (e, categoryId,brandId) => {
    window.scroll({ top: 0 })
    dispatch(globalAction(APPLY_FILTER, { category_slug: categoryId, sub_category_slug: undefined }))
   
  }


  return (
    <footer class="footer spacing">
      <Container>
        <Row>
          <Col sm={6} md={3} className="about_bellstone">
            <Row>
              <Col className="footer_logo">
                <Link to="/" onClick={()=>{window.scroll({top:0})}}>
                  <img src={about && about.logo} alt="logo" />
                  <span>Online Industrial Departmental Store</span>
                </Link>
              </Col>
            </Row>
            <p>{parse(about && about.about_us)}</p>
            <Link to="/About-Us" onClick={()=>{window.scroll({top:0})}} className="nav-link button">
              About Us
            </Link>
          </Col>
          <Col sm={6} md={3} className="footer_menu">
            <h3 className="title">Customer Services</h3>

            <Nav className="flex-column">
              <Link to="/Privacy-policy" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Privacy Policy
              </Link>
              <Link to="/terms-condition" onClick={()=>{window.scroll({top:0})}}  className="nav-link">
                Terms & Conditions
              </Link>
              <Link to="/delivery-info" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Delivery Information
              </Link>
              <Link to="/return" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Returns & Refunds
              </Link>
              <Link to="/payment-methods" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Payment Methods
              </Link>
              <Link to="/sell-on-bellstone" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Sell On Bellstone
              </Link>
              <Link to="/bulk-order" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Bulk Order
              </Link>
              <Link to="/offline-business" onClick={()=>{window.scroll({top:0})}} className="nav-link">
                Offline Business
              </Link>
            </Nav>
          </Col>

          <Col sm={6} md={3} className="footer_menu">
            <h3 className="title">Important Links</h3>
            <Nav className="flex-column">
              <Link to="/contact-us" onClick={()=>{window.scroll({top:0})}}>Contact Us</Link>
              {/* <Link to="" onClick={()=>{window.scroll({top:0})}} >Sitemap</Link>
              <Link to="" onClick={()=>{window.scroll({top:0})}}>Track Order</Link> */}
              <Link to="">News & Events</Link>
              {/* <Link to="/product-feedback" onClick={()=>{window.scroll({top:0})}}>Give Feedback</Link> */}
              <Link to="/blog" onClick={()=>{window.scroll({top:0})}}>Blog</Link>
            </Nav>
          </Col>
          <Col sm={6} md={3} className="contact_us">
            <h3 className="title">Contact Us</h3>
            <ul address>
              <li>{about && about.address}</li>
              <li>
                <Link to="/" onClick={() => window.open(`mailto:${about.email}`)}>
                  <span>
                    <AiOutlineMail />
                  </span>
                  {about && about.email}
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => window.open(`tel:${about && about.phoneno}`)}>
                  <span>
                    <FaPhoneAlt />
                  </span>
                  {about && about.phoneno}
                </Link>
              </li>
              <li>
                <span>
                  <Link to="/" onClick={() => window.open(`tel:${about && about.mobile}`)}>
                    <span>
                      <FaMobileAlt />
                    </span>
                    {about && about.mobile}
                  </Link>
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="footer_links">
            <ul className="top categories">
              <li>Top Categories :</li>

              {about!==null &&
                (about.top_category).map((category, i) => {
                  let categoryId = category.id;
                  let brandName =''
                 
                    return (
                      <li>
                        <Link to={`/${category.slug}`} 
                        // onClick={(e) => onSearch(e, categoryId, brandName)}
                        onClick={(e) => clickOnBottomLink(e, category.slug, brandName)}
                      

                        >   
                         {category.name} |
                        </Link>
                      </li>
                    );
                  
                })}
            </ul>
            <ul className="top brands">
              <li>Top Brands :</li>
              {about!==null &&
                (about.top_brand).map((item, i) => {
                  let brandId = item.id;
                  let brandName = item.name;
                  let categoryId = "";
                  
                    return (
                      <li>
                        <Link to={`/category-detail`} 
                        onClick={(e) => onSearch(e, categoryId, brandId,brandName)}>
                          {item.name} |
                        </Link>
                      </li>
                    );
                  
                })}
            </ul>
          </Col>
        </Row>
      </Container>
      <FooterBottom data={about} />
    </footer>
  );
}

export default withRouter(Footer);
