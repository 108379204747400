import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAboutApi } from "../../../api";
import parser from 'react-html-parser'
import "./AboutUs.scss";

function AboutUs() {
  const [state, setState] = useState({
    title:"",
    description:"",
    description_2:''
  })
  
  useEffect(() => {
    document.title = "About Us";
    getAboutApi().then(res => {
      const { status, data } = res;
      if (status === 200) {
        setState(data.about_us)
      }
    })
  }, []);

  return (
    <section className="payment_methods">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/About-us">About Us</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>{state.title}</h5>
              </div>
              <div className="common_pages_content">
                <div className="common_pages_inner">
                  {parser(state.description)}
                </div>
                <div className="common_pages_inner">
                  {parser(state.description_2)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutUs;
