import React, { useState, useEffect } from "react";
import "./orderDetails.scss";
import { Row, Col, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import { withRouter } from "react-router";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_LOADING, GET_ORDER_DETAILS, globalAction, SHOW_LOADING, CANCEL_ORDER } from "../../../redux";
import { Link } from "react-router-dom";


function OrderDetail(props) {
  const dispatch = useDispatch();
  const { orderDetail } = useSelector((state) => state.AppReducer);
  const { isLoading, visibleMessage, message } = useSelector((state) => state.errorReducer);
  const [totalGST, settotalGST] = useState(0)
  const [itemtotal, setItemtotal] = useState(0)
  const [cancelModalFlag, setCancelModalFlag] = useState(false)
  const [cancellationInput, setCancellationInput] = useState('No reason given by customer')
  const {
    match: {
      params: { id },
    },
  } = props;
  useEffect(() => {
    dispatch(globalAction(GET_ORDER_DETAILS, id));
    dispatch(globalAction(SHOW_LOADING));
  }, []);
  useEffect(() => {
    calculateTotalGST(orderDetail)
    calculateTotal(orderDetail)
  }, [orderDetail])

  const calculateTotalGST = (orderDetail) => {
    let total = 0
    orderDetail && (orderDetail.order_product).map((item, id) => {
      let amount = item.product.sale_price !== null
        ? Math.ceil(
          ((Number(item.product.sale_price) /
            100) *
            Number(item.product.tax)
          ) * item.qty)
        : Math.ceil(
          ((Number(item.product.regular_price) /
            100) *
            Number(item.product.tax))
          * item.qty)

      total = total + amount
    })
    settotalGST(total)
    return total;
  }

  const calculateTotal = (orderDetail) => {
    let total = 0
    orderDetail && (orderDetail.order_product).map((item, id) => {
      let amount = item.product.sale_price !== null
        ? Math.ceil(
          Number(item.product.sale_price)
           * item.qty)
        : Math.ceil(
          Number(item.product.regular_price) 
          * item.qty)

      total = total + amount
    })
    setItemtotal(total)
    return total;
  }


  const CancelOrderModal = () => {
    return (
      <div className="cancel-order">
        <Modal
          show={cancelModalFlag}
          onHide={() => { setCancelModalFlag(!cancelModalFlag) }}
          backdrop="static"
          keyboard={true}
          className="cancel-order-inner"
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to Cancel this order..?
            <div className="cancel-reason-wrap">
              <label htmlFor="cancel-reason">Cancellation Reason<span>(optional)</span></label>
              <textarea type="text" rows="5" onChange={(e) => { setCancellationInput(e.target.value) }} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => { setCancelModalFlag(!cancelModalFlag) }}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => { CancelOrder(id, cancellationInput) }}>Cancel Order</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )

  }
  const CancelOrder = ((id, reason) => {
    let tempData = {
      id: id,
      cancellation_reason: reason
    }
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(CANCEL_ORDER, tempData));
    setCancelModalFlag(false)
  })


  return isLoading ? (
    <AnimatedLogo />
  ) : (
    orderDetail !== undefined && (
      <div className="order_detail">
        <div className="my-heading">
          <h4>Order Detail</h4>

          {
            (orderDetail.invoice_url !== undefined || orderDetail.invoice_url !== null)
            &&
            <div className="download-invoce">
              <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           View/Download Invoice
                          </Tooltip> }
                          >
              <Link 
              onClick={()=>{window.open(`${orderDetail && orderDetail.invoice_url}`)}}
              >Invoice</Link>
               </OverlayTrigger>
            </div>
          }
          <div className="order_button">
            <button className="track_order_button">Track Order</button>
            {orderDetail.order_status === 'cancelled'
              ?
              <div className="cancel-watermark"> Cancelled </div>
              :

              <button onClick={() => { setCancelModalFlag(!cancelModalFlag) }}>Cancel Order</button>
            }

          </div>
        </div>
        <Row>
          <Col md={12} className="user_account">
            <div className="order_id">
              <ul>
                <li>
                  <strong>Order Number</strong> <span>{orderDetail.order_number}</span>
                </li>
                <li>
                  <strong>Date & Time</strong> <span>{orderDetail.created_at}</span>
                </li>
                <li className={orderDetail.order_status}>
                  <strong>Status</strong> <span>{orderDetail.order_status}</span>
                </li>
                <li>
                  <strong>Payment Method</strong> <span>{orderDetail.payment_type === "cod" ? "Cash on delivery" : "Online Payment"}</span>
                </li>
              </ul>
            </div>
            {orderDetail.payment_type === "cod" && (
              <div className="notes">
                <p>Pay with cash upon delivery. </p>
              </div>
            )}

            <div className="order_description">
              <h3>Order Details</h3>
              <ul className="order_discription">
                <li className="title">
                  <strong className="item">Item</strong>
                  <strong className="Price">Price/Unit</strong>
                  <strong className="Price  qty">Qty.</strong>
                  <strong className="Price">Total</strong>
                </li>
                {orderDetail.order_product.map((item, i) => {
                  console.log(item)
                  return (
                  
                    <li>
                      <Link 
                      to={`/${item.product.category.slug}/${item.product.subcategory.slug}/${item.product.slug}`}
                      >
                        <span className="item">{item.product.title} {item?.variant_name}</span>
                        <p ><span>HSN code:<span> {item.product.hsn_code && item.product.hsn_code}</span></span></p>
                      </Link>
                      <span className="Price">
                      <span>
                          <BiRupee />
                          {item.single_price}
                        </span>
                        <p class="gst-price"> <BiRupee /> {item.product.sale_price !== null
                          ? Math.ceil(
                            (Number(item.product.sale_price) /
                              100) *
                            Number(item.product.tax) +
                            Number(item.product.sale_price)
                          ).toFixed(0)
                          : Math.ceil(
                            (Number(item.product.regular_price) /
                              100) *
                            Number(item.product.tax) +
                            Number(item.product.regular_price)
                          ).toFixed(0)}
                          {/* <span> (Including GST)</span> */}
                          <span> (Including {item.product.tax}% GST)</span>
                          </p>
                       

                      </span>
                      <span className="Price qty">{item.qty}</span>
                      <span className="Price total">
                        <span>
                          <BiRupee />
                        </span>
                        {item.product.sale_price !== null
                          ? Math.ceil(
                            ((Number(item.product.sale_price) /
                              100) *
                              Number(item.product.tax) +
                              Number(item.product.sale_price)
                            ) * item.qty).toFixed(0)
                          : Math.ceil(
                            ((Number(item.product.regular_price) /
                              100) *
                              Number(item.product.tax) +
                              Number(item.product.regular_price))
                            * item.qty).toFixed(0)}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <ul className="order_price">
                {/* <li>
                  <strong>Tax:</strong>
                  <span>
                    <BiRupee />
                    180.00
                  </span>
                </li> */}
                <li>
                  <strong>Shipping Cost:</strong>
                  <span>
                    <BiRupee />
                    {(orderDetail.shiping_fee)}
                  </span>
                </li>
                {/* <li>
                  <strong>Gst Charges:</strong>
                  <span>
                    <BiRupee />
                    {totalGST.toFixed(2)}
                  </span>
                </li> */}
                <li>
                  <strong>Grand Total:</strong>
                  <span>
                    <BiRupee />
                    {Math.ceil(Number(orderDetail.order_price)).toFixed(0)}
                    {/* {orderDetail.order_price} */}
                  </span>
                </li>
              </ul>
            </div>
            <div className="order_address">
              <h3>Shipping Address</h3>
              <ul className="billing_address">
                {orderDetail.shipping_addess_data.full_name && <li>{orderDetail.shipping_addess_data.full_name}</li>}
                {orderDetail.company_name !== null && (
                  <li>
                    <strong>Company:</strong>
                    {orderDetail.company_name}
                  </li>
                )}
                {orderDetail.shipping_addess_data.address_line_1 && (
                  <li>
                    <strong>Address 1:</strong>
                    {orderDetail.shipping_addess_data.address_line_1}
                  </li>
                )}
                {orderDetail.shipping_addess_data.address_line_2 && (
                  <li>
                    <strong>Address 2:</strong>
                    {orderDetail.shipping_addess_data.address_line_2}
                  </li>
                )}
                {orderDetail.shipping_addess_data.state && (
                  <li>
                    <strong>City:</strong>
                    {orderDetail.shipping_addess_data.state}
                  </li>
                )}
                {orderDetail.shipping_addess_data.zip && (
                  <li>
                    <strong>PostCode:</strong>
                    {orderDetail.shipping_addess_data.zip}
                  </li>
                )}
                {orderDetail.shipping_addess_data.country && (
                  <li>
                    <strong>Country:</strong>
                    {orderDetail.shipping_addess_data.country}
                  </li>
                )}
                {(orderDetail.shipping_addess_data.mobile_no && orderDetail.shipping_addess_data.country_code) && (
                  <li>
                    <strong>Phone Number:</strong>
                    +{orderDetail.shipping_addess_data.country_code}-{orderDetail.shipping_addess_data.mobile_no}
                  </li>
                )}
                {orderDetail.shipping_addess_data.fax_nummber && (
                  <li>
                    <strong>Fax Number: </strong>
                    {orderDetail.shipping_addess_data.fax_nummber}
                  </li>
                )}
                {orderDetail.email && (
                  <li>
                    <strong>Email Address:</strong>
                    {orderDetail.email}
                  </li>
                )}
              </ul>

              <h3>Billing Address</h3>

              <ul className="billing_address">
                {orderDetail.billing_address_data.full_name && <li>{orderDetail.billing_address_data.full_name}</li>}
                {orderDetail.company_name !== null && (
                  <li>
                    <strong>Company:</strong>
                    {orderDetail.company_name}
                  </li>
                )}
                {orderDetail.billing_address_data.address_line_1 && (
                  <li>
                    <strong>Address 1:</strong>
                    {orderDetail.billing_address_data.address_line_1}
                  </li>
                )}
                {orderDetail.billing_address_data.address_line_2 && (
                  <li>
                    <strong>Address 2:</strong>
                    {orderDetail.billing_address_data.address_line_2}
                  </li>
                )}
                {orderDetail.billing_address_data.state && (
                  <li>
                    <strong>City:</strong>
                    {orderDetail.billing_address_data.state}
                  </li>
                )}
                {orderDetail.billing_address_data.zip && (
                  <li>
                    <strong>PostCode:</strong>
                    {orderDetail.billing_address_data.zip}
                  </li>
                )}
                {orderDetail.billing_address_data.country && (
                  <li>
                    <strong>Country:</strong>
                    {orderDetail.billing_address_data.country}
                  </li>
                )}
                {(orderDetail.billing_address_data.mobile_no && orderDetail.billing_address_data.country_code) && (
                  <li>
                    <strong>Phone Number:</strong>
                    +{orderDetail.billing_address_data.country_code}-{orderDetail.billing_address_data.mobile_no}
                  </li>
                )}
                {orderDetail.billing_address_data.fax_nummber && (
                  <li>
                    <strong>Fax Number: </strong>
                    {orderDetail.billing_address_data.fax_nummber}
                  </li>
                )}
                {orderDetail.email && (
                  <li>
                    <strong>Email Address:</strong>
                    {orderDetail.email}
                  </li>
                )}
              </ul>
              {(orderDetail.gst_number === "" || orderDetail.company_name === "" || orderDetail.company_address === "" || orderDetail.gst_billing === "yes") && (
                <>
                  <h3>GST Billing</h3>
                  <ul className="billing_address">
                    {orderDetail.company_name !== null && (
                      <li>
                        <strong>GST Number:</strong>
                        {orderDetail.gst_number}
                      </li>
                    )}
                    {orderDetail.billing_address_data.address_line_1 && (
                      <li>
                        <strong>Compnay Name </strong>
                        {orderDetail.company_name}
                      </li>
                    )}
                    {orderDetail.billing_address_data.address_line_2 && (
                      <li>
                        <strong>Company Addredd</strong>
                        {orderDetail.company_address}
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </Col>
        </Row>

        {CancelOrderModal()}
      </div>
    )
  );
}

export default withRouter(OrderDetail);
