import React, { useState } from "react";
import "./contactUs.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiPhone, FiMail } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { RiShareCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { contactApi } from "../../../api";
import { useDispatch } from "react-redux";
import { globalAction, SHOW_TOAST } from "../../../redux";
import BestBrands from "../../layouts/slider/BestBrands";
import ValuedCusmomer from "../../layouts/slider/ValuedCustomers";
import { useRef } from "react";


function ContactUs() {
  const dispatch = useDispatch();
  const ref1=useRef()

  const initialState = {
    email: "",
    name: "",
    subject: "",
    message: "",
  };
  const [contactData, setContactData] = useState({});

  const submitContact = (e) => {
    e.preventDefault();
    contactData.user_id = 3;
    console.log(contactData);
    contactApi(contactData).then((res) => {
      const { data, status } = res;
      if (status === 200) {
        dispatch(globalAction(SHOW_TOAST, { data }));
        ref1.current.reset()
        setContactData({});
      }
    });
  };

  const onChangeText = (text, value) => {
    contactData[text] = value;
    setContactData(contactData);
  };

  return (
    <section className="contact">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="contact_inner spacing">
        <Container>
          <Row>
            <Col xs={12} className="col">
              <Row>
                <Col md={12} className="slider_title contact_title">
                  <h5>Contact With US!</h5>
                </Col>

                <Col lg={6} className="contact_content">
                  <ul>
                    <li>
                      <span>
                        <FiPhone />
                        Call US:
                      </span>
                      <span> <a href="tel:+91-11-45158809">+91-11-45158809</a></span>
                  
                    </li>
                    <li>
                      <span>
                        <FiMail />
                        Email Address
                      </span>
                      <span><a href="mailto:saburi@bellstoneonline.com">saburi@bellstoneonline.com</a></span>
                    </li>
                    <li>
                      <span>
                        <IoLocationOutline />
                        Store Location:
                      </span>
                      <span>3755, Chawri Bazar, Delhi-110006(India)</span>
                    </li>
                    {/* <li>
                      <span>
                        <RiShareCircleFill />
                        Follow US:
                      </span>
                      <p>
                        <Link to="/" className="facebook" onClick={() => window.open("https://www.facebook.com/BellstoneOnline")}>
                          <ImFacebook />
                        </Link>
                        <Link to="/" className="twitter" onClick={() => window.open("https://twitter.com/Bellstoneonline")}>
                          <ImTwitter />
                        </Link>
                        <Link to="/" className="instagram">
                          <FaInstagram />
                        </Link>
                        <Link to="/" className="pint" onClick={() => window.open("https://in.pinterest.com/bellstoneonline/")}>
                          <FaPinterest />
                        </Link>
                      </p>
                    </li> */}
                  </ul>
                </Col>
                <Col lg={6}>
                  <div className="contact_form">
                    <h4>Get In Touch</h4>
                    <p>You email address will not be published. Required fields are marked(*)</p>
                    <Form onSubmit={submitContact} ref={ref1}>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={contactData.name} type="text" placeholder="Name" required 
                            onChange={(e) => onChangeText("name", e.target.value)} />
                          </Form.Group>
                        </Col>
                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" placeholder="Last Name" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" value={contactData.subject} placeholder="Subject" required onChange={(e) => onChangeText("subject", e.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={contactData.email} placeholder="Enter email" required onChange={(e) => onChangeText("email", e.target.value)} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" value={contactData.message} rows={3} onChange={(e) => onChangeText("message", e.target.value)} />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="form_button">
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="getin_touch"></Col>
          </Row>
        </Container>
      </div>
      <BestBrands />
      <ValuedCusmomer />
    </section>
  );
}

export default ContactUs;
