import React, { useState, useEffect, useRef } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { addAddressApi, getCountryApi, getStateApi } from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import {
  ADD_ADDRESS,
  GET_USER_SAVED_ADDRESS,
  globalAction,
  SHOW_LOADING,
  SHOW_MESSAGE,
  REMOVE_MESSAGE,
  HIDE_MESSAGE,
} from "../../../redux";
import { withRouter, useHistory } from "react-router";

function AddressForm(props) {
  let history = useHistory();
  const { message } = useSelector((state) => state.errorReducer);
  const [countryData, setcountryData] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [newAddress, setnewAddress] = useState({
    user_id: props.userId,
    full_name: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    state: "",
    zip: 0,
    mobile_no: "",
    default_address: "no",
    email: "",
  });
  const [newBillAddress, setnewBillAddress] = useState({
    user_id: props.userId,
    full_name: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    state: "",
    zip: 0,
    mobile_no: "",
  });

  const [gstInfo, setGstInfo] = useState({
    gst_number: "",
    company_name: "",
    company_address: "",
  });
  const [isGSTBill, setIsGSTBill] = useState("no");
  const [isBillingSame, setisBillingSame] = useState(false);
  const [makeDefault, setmakeDefault] = useState(false);

  const dispatch = useDispatch();
  const getStateData = async (id) => {
    await getStateApi(id).then(async (res) => {
      const { data, status } = res;
      if (status === 200) {
        await setstateData(data.state_list);
      }
    });
  };

  const onChangeText = (text, e) => {
    console.log(text)
    if (text === "country") {
      getStateData(e.target.value);
    }
    let inputData = newAddress;
    inputData[text] = e.target.value;
    setnewAddress(inputData);
  };

  const onChangeText2 = (text, e) => {
    if (text === "country") {
      getStateData(e.target.value);
    }

    let inputData = newBillAddress;
    inputData[text] = e.target.value;
    setnewBillAddress(inputData);
  };

  const checkFieldValidation = (fieldName, e, forDelivery = true, forBilling) => {
    if (fieldName === 'email') {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) {
        alert('Enter Valid Email ID')
        e.target.value = ''
        if (forDelivery) {
          let inputData = newAddress;
          inputData[fieldName] = '';
          setnewAddress(inputData);
        }
        if (forBilling) {
          let inputData = newBillAddress;
          inputData[fieldName] = ''
          setnewBillAddress(inputData);
        }
      }
    }
    if (fieldName === 'mobile_no') {
      if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(e.target.value)) {
        alert('Enter Valid Mobile Number')
        e.target.value = ''
        if (forDelivery) {
          let inputData = newAddress;
          inputData[fieldName] = '';
          setnewAddress(inputData);
        }
        if (forBilling) {
          let inputData = newBillAddress;
          inputData[fieldName] = ''
          setnewBillAddress(inputData);
        }
      }
    }


  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "gst_billing") {
      setIsGSTBill(value);
    }
    let temp = gstInfo;
    temp[name] = value;
    setGstInfo(temp);
  };

  const checkBillingAddress = (e) => {
    setisBillingSame(!isBillingSame);
  };

  const Validation = () => {
    if (props.userId !== "") {
      delete newAddress.email;
    } else {
      delete newBillAddress.user_id;
      delete newAddress.user_id;
    }

    delete newAddress.address_line_2;
    delete newBillAddress.address_line_2;
    let isValid = true;
    for (const property in newAddress) {
      if (String(newAddress[property]).trim() === "") {
        isValid = false;
      }
    }
    if (!isBillingSame) {
      for (const property in newBillAddress) {
        if (String(newBillAddress[property]).trim() === "") {
          isValid = false;
        }
      }
    }
    if (isGSTBill === "yes") {
      for (const property in gstInfo) {
        if (String(gstInfo[property]).trim() === "") {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const finalShippingAddress = (e, item) => {
    console.log(Validation())
    console.log(Validation())
    console.log(e.currentTarget)
    if (Validation()) {
      ////
      let finalObject = {
        user_id: props.userId,
        full_name: newAddress.full_name,
        address_line_1: newAddress.address_line_1,
        address_line_2: newAddress.address_line_2,
        country: Number(newAddress.country),
        state: Number(newAddress.state),
        zip: Number(newAddress.zip),
        mobile_no: Number(newAddress.mobile_no),
        default_address: makeDefault ? "yes" : "no",
        isBillingAddress: "no",
      };

      if (props !== undefined) {
        dispatch(globalAction(ADD_ADDRESS, finalObject));
        dispatch(globalAction(SHOW_LOADING));
      }

      countryData &&
        countryData.map((item, index) => {
          if (item.id === Number(newAddress.country)) {
            newAddress["country"] = item.name;
          }
        });
      stateData &&
        stateData.map((item, index) => {
          if (item.id === Number(newAddress.country)) {
            newAddress["state"] = item.name;
          }
        });

      let finalOrderDetails = {
        delivery_address: newAddress,
        billing_address: newBillAddress,
        isGSTBill: isGSTBill,
        gstInfo: gstInfo,
      };

      if (isBillingSame) {
        finalOrderDetails["billing_address"] = newAddress;
        finalObject["isBillingAddress"] = "yes";
        // dispatch(globalAction(ADD_ADDRESS, finalObject));
        // dispatch(globalAction(SHOW_LOADING));
      } else {
        let finalObjectBilling = {
          user_id: props.userId,
          full_name: newBillAddress.full_name,
          address_line_1: newBillAddress.address_line_1,
          address_line_2: newBillAddress.address_line_2,
          country: Number(newBillAddress.country),
          state: Number(newBillAddress.state),
          zip: Number(newBillAddress.zip),
          mobile_no: Number(newBillAddress.mobile_no),
          default_address: "no",
          isBillingAddress: "yes",
        };
        if (props !== undefined) {
          dispatch(globalAction(ADD_ADDRESS, finalObjectBilling));
          dispatch(globalAction(SHOW_LOADING));
        }
        countryData &&
          countryData.map((item, index) => {
            if (item.id === Number(newBillAddress.country)) {
              newBillAddress["country"] = item.name;
            }
          });
        stateData &&
          stateData.map((item, index) => {
            if (item.id === Number(newBillAddress.country)) {
              newBillAddress["state"] = item.name;
            }
          });
        finalOrderDetails["billing_address"] = newBillAddress;
      }

      if (props.userId === "") {
        finalOrderDetails.email = newAddress.email;
        finalOrderDetails.userType = "guest";
        finalOrderDetails.name = newAddress.full_name;
        finalOrderDetails.mobile = newAddress.mobile_no;
      }

      localStorage.setItem(
        "finalOrderDetails",
        JSON.stringify(finalOrderDetails)
      );
      console.log('finalOrderDetails', finalOrderDetails)
      // ////
      // history.push("/order-summary");
    } else {
      let data = {
        msg: `Please fill up the all fields.`,
        status: "Warning",
      };

      dispatch(globalAction(SHOW_MESSAGE, data));
      setTimeout(() => {
        dispatch(globalAction(HIDE_MESSAGE));
        dispatch(globalAction(REMOVE_MESSAGE));
      }, 2000);
    }
  };

  useEffect(() => {
    getCountryApi().then((res) => {
      const { data, status } = res;
      if (status === 200) {
        setcountryData(data.country_list);
      }
    });
  }, []);

  useEffect(() => {
    if (message === "Address added successfully") {
      localStorage.setItem("finalShippingAddress", JSON.stringify(newAddress));
      setnewAddress({
        user_id: props.userId,
        full_name: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        state: "",
        zip: 0,
        mobile_no: "",
      });
      history.push("/order-summary");
    }
  }, [message]);

  console.log('newAddress', newAddress)
  return (
    <div className={`addressform`}>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Full Name<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Full name"
              onChange={(e) => onChangeText("full_name", e)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Mobile<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              type="number"
              placeholder="Mobile"
              onChange={(e) => onChangeText("mobile_no", e)}
              onBlur={(e) => checkFieldValidation('mobile_no', e)}
              required
            />
          </Form.Group>
        </Row>
        {props.userId === "" && (
          <Form.Group className="mb-3" controlId="formGridEmail">
            <Form.Label>Email<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              onBlur={(e) => checkFieldValidation('email', e)}
              onChange={(e) => onChangeText("email", e)}
              required
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Address Line 1<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Address Line1"
            onChange={(e) => onChangeText("address_line_1", e)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Address Line1"
            onChange={(e) => onChangeText("address_line_2", e)}
            required={false}
          />
        </Form.Group>

        <Row className="mb-0">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Country<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => onChangeText("country", e)}
              required
            >
              <option>Select Country</option>
              {countryData &&
                countryData.map((item) => {
                  const { id, name } = item;
                  return (
                    <option
                      key={id}
                      selected={id === "101" ? true : false}
                      value={id}
                    >
                      {name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => onChangeText("state", e)}
              required
            >
              <option value="">Select State</option>
              {stateData &&
                stateData.map((item, i) => {
                  const { id, name } = item;
                  return (
                    <option key={id + i} value={id}>
                      {name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              type="number"
              placeholder="zip"
              onChange={(e) => onChangeText("zip", e)}
              required
            />
          </Form.Group>
        </Row>
      </Form>
      <Accordion defaultActiveKey="0" className="shipping_address_form">
        <Card>
          <Card.Header>

            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <Form.Check
                type="checkbox"
                name="Billing_Address_as_a_Delivery_Address"
                value={isBillingSame}
                onChange={(e) => {
                  checkBillingAddress(e);
                }}
              />
            </Accordion.Toggle>
            <Form.Label>Billing Address as a Delivery Address</Form.Label>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Full Name<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full name"
                    onChange={(e) => onChangeText2("full_name", e)}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Mobile<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Mobile"
                    onBlur={(e) => checkFieldValidation('mobile_no', e, false, true)}
                    onChange={(e) => onChangeText2("mobile_no", e)}
                    required
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Address Line 1<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Address Line1"
                  onChange={(e) => onChangeText2("address_line_1", e)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress22">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Address Line"
                  onChange={(e) => onChangeText2("address_line_2", e)}
                  required={false}
                />
              </Form.Group>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Country<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => onChangeText2("country", e)}
                    required
                  >
                    <option>Select Country</option>
                    {countryData &&
                      countryData.map((item) => {
                        const { id, name } = item;
                        return (
                          <option
                            key={id}
                            selected={id === "101" ? true : false}
                            value={id}
                          >
                            {name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>State<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => onChangeText2("state", e)}
                    required
                  >
                    <option value="">Select State</option>
                    {stateData &&
                      stateData.map((item, i) => {
                        const { id, name } = item;
                        return (
                          <option key={id + i} value={id}>
                            {name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Zip<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="zip"
                    onChange={(e) => onChangeText("zip", e)}
                    required
                  />
                </Form.Group>
              </Row>
            </Form>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Check
          type="checkbox"
          label="Yes, I would like to receive tracking information via SMS (SMS and data rates may apply)."

        />
      </Form.Group>

      <div>
        <input
          type="checkbox"
          id="make_default_address"
          name="make_default_address"
          Defaultvalue={makeDefault}

          onChange={(e) => {
            setmakeDefault(!makeDefault);
          }}
        />
        <label for="make_default_address">
          Would you like to make this address default for future orders.?
        </label>
      </div>

      <div className="gst-form-wrap">
        <p>GST Billing ?</p>
        <div>
          <input
            type="radio"
            id="gstYes"
            name="gst_billing"
            value="yes"
            required
            defaultChecked={isGSTBill === "yes"}
            onChange={(e) => handleChange(e)}
          />
          <label for="gstYes">Yes</label>
          <input
            type="radio"
            id="gstNo"
            name="gst_billing"
            value="no"
            defaultChecked={isGSTBill === "no"}
            required
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label for="gstno">No</label>
        </div>
        <div className={`gst-form-main-wrap ${isGSTBill}`}>
          <div className="gst-form-inner">
            <div className="input">
              <label for="gst_number">GST number</label>
              <input
                type="text"
                id="gst_nummber"
                name="gst_number"
                required
                defaultValue={gstInfo.gst_number}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="input">
              <label for="company_name">Company name</label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                defaultValue={gstInfo.company_name}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="input">
              <label for="company_address">Company Address</label>
              <input
                type="text"
                id="company_address"
                name="company_address"
                required
                defaultValue={gstInfo.company_address}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="delivery-btn wrap save">
        <button
          className="button"
          onClick={(e) => {
            finalShippingAddress(e);
          }}

        // to="/order-summary"
        >
          Save & Deliver to this address
        </button>
      </div>
    </div>
  );
}

export default withRouter(AddressForm);
