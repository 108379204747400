import React, { Component } from 'react'
import { Col, Row, Tab, Tabs, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux'
import { globalAction, CHANGE_PASSWORD, UPDATE_PROFILE } from '../../../redux';
import './userAccount.scss'

class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updatePassword: {
                old_password: "",
                new_password: "",
                confirm_password: "",
            },
            passwordValidated: false,
            contactValidated: false,
            tempPhoto:null
        }
    }
    componentDidMount(){
          const { user } = this.props
          this.setState({...this.state,...user})
    }
    changeText = async (value, text) => {
        let state = this.state;
        if (text === "old_password" || text === "new_password" || text === "confirm_password") {
            state["updatePassword"][text] = value.target.value

        }else{
            state[text]=value.target.value
        }
        this.setState(state)
    }

    updatePassword = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const { send_updatePassword, user } = this.props;
            let state = this.state;
            state['updatePassword']["id"] = user.id
            send_updatePassword(state.updatePassword)
        }

        this.setState({ passwordValidated: true });


    }

    onSelectImage=async(value)=>{
        let file = await value.target.files[0];
            let reader = await new FileReader(file);
            if (file) {
                reader.readAsDataURL(file);
            }

            reader.onload = async (e) => {
                // setImage(e.target.result)
                // setIsImage(true)
                this.setState({tempPhoto:e.target.result,photo:file})
            //    await updateProfileImage(file)
            //    await this.setState({...this.state})
                // uploadProfileImageApi(file,token).then(res=>{
                //     const {status,data}=res;
                //     if(status===200){
                //         console.log(data.data)
                //     }
                // })
               // await setState({...state,image:file})
            }
    }
    contactComponent = () => {
        const { contactValidated,tempPhoto,name,profile_image,email,mobile } = this.state;
        console.log(this.state)
        //const { user } = this.props
        return (
            <>
                <div className="contact-heading">
                    Contact Information
                </div>
                <Form noValidate validated={contactValidated} onSubmit={this.saveContact}>
                    <Row className="contact-row">
                        <Col md={8}>
                        <Form.Group as={Col} md="12" className="contact-6" controlId="un">
                            <label>Name*</label>
                            <Form.Control
                                required
                                defaultValue={name}
                                type="text"
                                onChange={(value) => this.changeText(value, 'name')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your name.
                        </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col} md="12" className="contact-6" controlId="ue">
                            <label>Email*</label>
                            <Form.Control
                                required
                                disabled
                                defaultValue={email}
                                type="text"
                                onChange={(value) => this.changeText(value, 'email')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your email.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="contact-6" controlId="un">
                            <label>Phone*</label>
                            <Form.Control
                                required
                                value={mobile}
                                defaultValue="tel"
                                onChange={(value) => this.changeText(value, 'mobile')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your contact number.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>
                        <Col md={4}>
                        <Form.Group as={Col} md="12" className="contact-6">
                           
                           <div className={tempPhoto || profile_image ?"profile-image border-0":"profile-image"}>
                           {tempPhoto ||profile_image?<img src={tempPhoto!==null?tempPhoto:profile_image} alt="user"/>:
                           <Form.Label  htmlFor="user-profile">Upload Profile +</Form.Label>
                           }
                           <Form.Control id="user-profile" type="file" onChange={(event) => this.onSelectImage(event)}/>
                           {tempPhoto ||profile_image ?  <Form.Label  htmlFor="user-profile">Change image</Form.Label>:null}
                          
                          </div>
                            
                           
                       </Form.Group>
                        </Col>
                    
                       
                        <Form.Group as={Col} md="12" className="contact-6 eqy-btn" controlId="un">
                            <Button type="submit" className="">Save</Button>
                        </Form.Group>
                    </Row>
                </Form>
                {/* <Row className="contact-row">
                    <Col md={6} className="contact-6">Name :<input defaultValue={user.name} /></Col>
                    <Col md={6} className="contact-6">Email :<input defaultValue={user.email} /></Col>
                    <Col md={6} className="contact-6">Phone :<input defaultValue={user.mobile} /></Col>
                </Row> */}

            </>
        )
    }
    saveContact = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
           const {name,email,photo,id,mobile}=this.state;
           const {updateProfile}=this.props;
           const finalObject={name,email,photo,user_id:id,mobile}
           console.log(finalObject)
           for(const key in finalObject){
               if(finalObject[key]===null|| finalObject[key]===undefined){
                    delete finalObject[key]
               }
           }
           console.log(finalObject)
           updateProfile(finalObject)
        }

        this.setState({ contactValidated: true });


    }
    changePasswordComponent = () => {
        const { passwordValidated } = this.state;
        return (
            <div>

                <Form noValidate validated={passwordValidated} onSubmit={this.updatePassword}>
                    <Row className="contact-row">
                        <Form.Group as={Col} md="6" className="contact-6" controlId="op">
                            <label>Current Password*</label>
                            <Form.Control
                                required
                                type="text"
                                onChange={(value) => this.changeText(value, 'old_password')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your current password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="contact-6" controlId="np">
                            <label>New Password*</label>
                            <Form.Control
                                required
                                type="text"
                                onChange={(value) => this.changeText(value, 'new_password')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your new password.
                    </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="contact-6" controlId="cp">
                            <label>Confirm new Password *</label>
                            <Form.Control
                                required
                                type="text"
                                onChange={(value) => this.changeText(value, 'confirm_password')}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your confirm password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="contact-6 eqy-btn" controlId="un">
                            <Button type="submit" className="">Save</Button>
                        </Form.Group>
                    </Row>
                    {/* <Button type="submit">Save</Button> */}
                </Form>
                {/* <Col md={6} className="contact-6">Current Password :<input onChange={(value)=>this.changeText(value,"old_password")}/></Col>
                <Col md={6} className="contact-6">New Password :<input onChange={(value)=>this.changeText(value,"new_password")}/></Col>
                <Col md={6} className="contact-6">Confirm Password :<input onChange={(value)=>this.changeText(value,"confirm_password")}/></Col> */}


            </div>
        )
    }
    addressComponent=()=>{
        return(
            <div></div>
        )
    }

    render() {
       
        return (
            <>
                <div className="my-heading">My Account</div>
                <Row>
                    <div className="col-md-12 user-account">
                        <Tabs defaultActiveKey="contact" transition={false} id="noanim-tab-example" >
                            <Tab eventKey="contact" title="Contact Information" >

                                {this.contactComponent()}
                            </Tab>
                            <Tab eventKey="update_password" title="Change Password">
                                {this.changePasswordComponent()}
                            </Tab>
                            {/* <Tab eventKey="address" title="Saved Address">
                                {this.addressComponent()}
                            </Tab> */}
                        </Tabs>
                    </div>
                </Row>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const { user } = state.authReducer;
    return { user }
}
const mapDispatchToProps = dispatch => {
    return {
        send_updatePassword: (data) => dispatch(globalAction(CHANGE_PASSWORD, data)),
        updateProfile: (data) => dispatch(globalAction(UPDATE_PROFILE, data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)