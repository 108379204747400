import React, { useEffect } from "react";
import AddressForn from "../Choose-Address/AddressForn";
import { useSelector } from "react-redux";

function AddNewAddress() {
  const { user } = useSelector((state) => state.authReducer);

  return (
    <div className="choose-address-wrap new-address">
      <div className="container">
        <div className="ca-top-section">
          <h1>Fill Delivery Address</h1>
        </div>
        <AddressForn userId={user !== null ? user.id : ""} />
      </div>
    </div>
  );
}

export default AddNewAddress;
