//Api_Constants.js
let dev= 'https://bellstoneadmin.imperialitforweb.com/';
let live="https://bellstoneonline.in/"
//let STRIPE_KEY="pk_test_51JLO25FQD7T2lW2j8J9r24cmddidIUkxkNJ1EaMmfC9w2QO4gFLvtDqdozs4Qa0EYsaeXHvSbaeLKkfjBVKk5eOP00NhS9uTxV"
let Apis = {
    //Main Urls
    // STRIPE_KEY,
    baseUrl: live,
    liveUrl:live,
    mid: 'api/',

    //Apis
    // register: 'register',
    home: 'home',
    login: 'login',
    signup:'registration',
    contactus:'contact_us',
    categoryList: "category_list",
    product_list: "product_list",
    brand_list:'brand_list',
    product_details: "product_details",
    global_product_search:"global_product_search",
    subscribe: "subscribe",
    about_us:"about_us",
    save_product_list:"save_product_list",
    add_save_product: "add_save_product",
    delete_save_product: "delete_save_product",
    latest_products: "latest_product_list",
    add_review: "add_review",
    return_and_refund: "return_and_refund",
    payment_methods: "payment_methods",
    delivery_information: "delivery_information",
    privacy_policy: "privacy_policy",
    terms_condition: "terms_condition",
    footer:"footer",
    header:"header",
    add_bulk_enquiry:"add_bulk_enquiry",
    check_delivery:"check_delivery",
    get_saved_address_by_userId:"address_list", 
    country:"country",
    state:"state",
    add_address:"add_address",
    delete_address:"delete_address",
    update_address:"update_address",
    add_order:"add_order",
    orders:'orders',
    callback:'callback',
    order_details:"order_details",
    about_us:"about_us",
    bulk_enquiry_list:'bulk_enquiry_list',
    compare_products:'compare_products',
    delete_bulk:'delete_bulk',
    signup_verify:'signup_verify',
    blog_list: 'blog',
    blog_category: "blog_category",
    popular_blogs: "popular_blogs",
    blog_details: 'blog_details',
    comment_list: "comment_list",
    add_comment: "add_comment",
    subcategory:'subcategory',
    review_register:'review_register',
    payment_setting:'payment_setting',
    add_like_unlike:"add_like_unlike",
    estimate_shipping:'estimate_shipping',
    order_cancel:'order_cancel',
    forgot_password:'forgot_password',
    reset_password:'reset_password',
    change_password:'change_password',
    update_profile:'update_profile',
    add_to_cart:'add_to_cart',
    update_qty:'update_qty',
    delete_add_to_cart:'delete_add_to_cart',
    add_to_cart_list:'add_to_cart_list',
    delete_all_add_to_cart:'delete_all_add_to_cart',
    recent_orders:'recent_orders',
    vendor_details:'vendor_details',
    resend_email_verify:'resend_email_verify'
}
export default Apis