import React, { useEffect ,useState} from "react";
import "./privacyPolicy.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPrivacyPolicyApi } from "../../../api";
import parser from 'react-html-parser'

function PrivacyPolicy() {
  const [state, setState] = useState({
    title:"",
    description:""
  })
  useEffect(() => {
    document.title = "Privacy Policy";
    getPrivacyPolicyApi().then(res => {
      const { status, data } = res;
      if (status === 200) {
        setState(data.privacy_policy)
      }
    })
  }, []);

  return (
    <section className="privacy_policy">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/Privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>Privacy Policy</h5>
              </div>
              <div className="common_pages_content">
                {/* <div className="date">
                  <span>Last updated on</span> <span>29-March-2017</span>
                </div> */}
                <div className="common_pages_inner">
                {parser(state.description)}
                  {/* <h6>ACCOUNT</h6>
                  <p>If you want to use the website, you are responsible for maintaining the confidentiality of your account and password and to prevent unauthorized access to your account. You agree to accept responsibility for all activities that occur under your account.</p>
                  <p>Please ensure that the details you provide with us are correct & complete and inform us immediately of any changes to the information that you provided when registering your account. You can access and update much of the information you provided us with your account of the website. Bellstoneonline.com reserves the right to refuse, access to the website, terminate accounts, remove or edit the contents at any time without notice to you.</p>
                  <h6>PRIVACY</h6>
                  <p>The personal information / data provided to us by you during the course of usage stands strictly confidential and in accordance with the Privacy. If you object to your information being transferred or used, please do not use the website.</p>
                  <h6>E- COMMUNICATION</h6>
                  <p>You agree and acknowledge that the website is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location. You further agree and acknowledge that bellstoneonline.com is only a facilitator and cannot be a party in any manner any transactions on the website. Thus, the contract of sale of products on the website shall be a strictly bipartite contract between you and the sellers i.e. bellstoneonline.com.</p>
                  <h6>ACCESS TO BELLSTONEONLINE.COM</h6>
                  <p>We strive to ensure that availability of the website will be uninterrupted and that transmissions are without any error. However, due to the nature of the transaction/communication through internet, it cannot be guaranteed. Your access to the website may also be occasionally restricted to allow for the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such restriction.</p>

                  <h6>LICENSE FOR WEBSITE ACCESS</h6>
                  <p>We grant you a limited license to access and make personal use of this website, but not to download or modify (except with express written consent of the website and / or its affiliates) as may be applicable. This license does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another seller.</p>
                  <p>This website or any portion of this website (including but not limited to any copyrighted material, trademarks, or other proprietary information) shall not be reproduced, duplicated, copied for any commercial purpose without express written consent of bellstoneonline.com or its affiliates, as may be applicable.</p>
                  <p>No one may use our trademarks namely (Bellstone Hi Tech International, logo, Copy Right or other proprietary information of bellstoneonline.com and its affiliates) without express written consent.</p>

                  <h6>INTELLECTUAL PROPERTY (TRADEMARKS, COPYRIGHT AND DATABASE RIGHTS)</h6>
                  <p>All contents included on the website, such as text, graphics, logos, button icons, images, data compilations, and software, is the property of bellstoneonline.com & its affiliates or its content suppliers and is protected by Indian law. Bellstoneonline.com and its affiliates respect the intellectual property of others. Users are prohibited from using, modifying, copying, distributing, e that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such contents and materials.</p>

                  <h6>YOUR CONDUCT</h6>
                  <p>You must not use the website in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any manner. You must not use the website for any of the following: </p>
                  <ul>
                    <li>for fraudulent purposes, or in connection with a criminal offence or other unlawful activity;</li>
                    <li>to send, use or reuse any material that does not belong to you; or in a illegal manner,</li>
                    <li>to cause annoyance, inconvenience or needless anxiety.</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PrivacyPolicy;
