import React, { useState, useEffect } from "react";
import "./navigation.scss";
import { Container, Row, Col } from "react-bootstrap";
import { FiGrid } from "react-icons/fi";
import { FaAngleDown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { APPLY_FILTER, globalAction } from "../../../redux";
import { HeaderApi } from "../../../api/Api_Call";

function Navigation() {
  const { categories } = useSelector((state) => state.AppReducer);
  const { home } = useSelector((state) => state.AppReducer);
  const dispatch = useDispatch();
  const [show, setshow] = useState(false);
  const [activeCategory, setActiveCategory] = useState(false);
  const { category } = home;

  const [HeaderCategory, SetHeaderCategory] = useState(null);

  useEffect(() => {
    HeaderApi().then((res) => {
      const { data, status } = res;
      if (status === 200) {
        SetHeaderCategory(data.header.category);
      }
    });
  }, []);

  function showChild_2(i) {
    const closeChild = document.querySelectorAll(`.navigation .all_category .category > ul>li`);
    closeChild.forEach((element) => element.classList.remove("open"));
    const accordions = document.querySelectorAll(`.accordion1-${i}`);
    let targetEle = accordions[0].closest(".section");
    targetEle.classList.toggle("open");
  }

  return (
    <section className="navigation">
      <Container>
        <Row>
          <Col md={2} className="all_category">
            <span>
              <FiGrid />
            </span>

            <Link className="all_category_title" to="/view-all" onClick={()=>{localStorage.setItem('isSearchValid','false')}}> 
              All Categories
            </Link>
            <span className="child_trigger">
              <FaAngleDown />
            </span>

            <div className="category">
              <ul>
                {category.map((item, i) => {
                  const { sub_category, id, name,slug } = item;
                  if (sub_category.length > 0) {
                    return (
                      <li className={`section`}>
                        <NavLink
                          to={`/${slug!==null?slug:id}`}
                          onClick={() => {
                            dispatch(globalAction(APPLY_FILTER, { category_slug: item.slug, sub_category_slug: undefined }));
                            document.getElementById("header").classList.remove("active_cat");
                          }}
                        >
                          {name}
                        </NavLink>
                        <div className={`back_button accordion1-${i}`} onClick={() => showChild_2(i)}>
                          <span>
                            <FaAngleDown />
                          </span>
                        </div>

                        <div className="sub_category">
                          <h5>{name}</h5>
                          <ul>
                            {sub_category.map((subCat) => (
                              <li>
                                <NavLink
                                  to={`/${slug!==null?slug:id}/${subCat.slug!==null?subCat.slug:subCat.id}`}
                                  onClick={() => {
                                    dispatch(globalAction(APPLY_FILTER, { sub_category_slug: subCat.slug, category_slug: undefined }));
                                    document.getElementById("header").classList.remove("active_cat");
                                  }}
                                >
                                  {subCat.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                  return (
                    <li>
                      <NavLink to={`/${id}`} onClick={() => dispatch(globalAction(APPLY_FILTER, { category_slug: item.slug, sub_category_slug: undefined }))}>
                        {item.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>

          <Col md={10} className="menu">
            <nav className="navigation_menu">
              <ul>
                {HeaderCategory &&
                  HeaderCategory.map((item, index) => {
                    if (index <= 5) {
                      const { sub_category, id, name,slug } = item;

                      if (sub_category.length > 0) {
                        return (
                          <li>
                            <NavLink to={`/${slug!==null? slug:id}`} onClick={() => dispatch(globalAction(APPLY_FILTER, { category_slug: item.slug, sub_category_slug: undefined,page:1 }))}>
                              {name}
                              <div className="back_button" onClick={() => setshow(!show)}>
                                <span>
                                  <FaAngleDown />
                                </span>
                              </div>
                            </NavLink>

                            <ul>
                              {sub_category.map((subCat) => (
                                <li>
                                  <NavLink to={`/${slug!==null?slug:id}/${subCat.slug!==null?subCat.slug:subCat.id}`} onClick={() => dispatch(globalAction(APPLY_FILTER, { sub_category_slug: subCat.slug, category_slug: undefined ,page:1}))}>
                                    {subCat.name}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </li>
                        );
                      }
                      return (
                        <li>
                          <NavLink to={`/${id}`} onClick={() => dispatch(globalAction(APPLY_FILTER, { category_slug: item.slug, sub_category_slug: undefined,page:1 }))}>
                            {item.name}
                          </NavLink>
                        </li>
                      );
                    }
                  })}

                {categories.length > 5 && (
                  <li>
                    <NavLink to="/view-all" onClick={() => dispatch(globalAction(APPLY_FILTER, { category_slug: null, sub_category_slug: null,page:1 }))}>View All</NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Navigation;
