import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    DELETE_ADDRESS,
    GET_USER_SAVED_ADDRESS,
    globalAction,
    SHOW_LOADING,
} from "../../../redux";
import { Link, withRouter } from "react-router-dom";
import DeliveryImg from "../../../assets/Delivery.png";
import "./ChooseAddress.scss";
import { FaCheck, FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import AddressForm from "./AddressForn";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";

function ChooseAddress({ history }) {
    const dispatch = useDispatch();
    const { user, savedAddresses } = useSelector((state) => state.authReducer);
    const { isLoading, } = useSelector(
    (state) => state.errorReducer
  );
    const [fromFlag, setfromFlag] = useState(false);
    const [isGSTBill, setIsGSTBill] = useState("no");
    const [gstInfo, setGstInfo] = useState({
        gst_number: "",
        company_name: "",
        company_address: "",
    });
    const [getAddressInfo, setGetAddressInfo] = useState({delivery_address:{},
        billing_address:{}});
    const [flag, setflag] = useState(true);

    useEffect(() => {
        if (user !== null) {
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(GET_USER_SAVED_ADDRESS, user.id));
        }
        if (savedAddresses.length > 0) {
            savedAddresses.map((item, index) => {
                if (item.default_address === "yes") {
                    getAddressInfo["delivery_address"] = item;
                    getAddressInfo["billing_address"] = item;
                }
            });
        }
    }, [dispatch]);
    useEffect(() => { }, [flag]);

    const handleAddressDelete = (e, item) => {
        const { id, user_id } = item;
        dispatch(globalAction(DELETE_ADDRESS, { id: id, user_id: user_id }));
        dispatch(globalAction(SHOW_LOADING));
    };

    const handleAddressEdit = (e, item) => {
        localStorage.setItem("UpdateAddress", JSON.stringify(item));
    };

    const finalShippingAddress = (e, item) => {
        let finalOrderDetails = {
            "delivery_address": getAddressInfo.delivery_address,
            "billing_address": getAddressInfo.billing_address,
            "isGSTBill":isGSTBill,
            'gstInfo':gstInfo
        }
        localStorage.setItem("finalOrderDetails", JSON.stringify(finalOrderDetails));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "gst_billing") {
            setIsGSTBill(value);
        }
        let temp = gstInfo;
        temp[name] = value;
        setGstInfo(temp);
    };

    const selectAddressFor = (e, item) => {
        const { name, value } = e.target;
        let temp = getAddressInfo;
        temp[name] = item;
        setGetAddressInfo(temp);
        setflag(!flag);
    };

    return (
        <div className="choose-address-wrap dashboard">
            {isLoading && <AnimatedLogo/>}
            <div className="container">
                <div className="ca-top-section">
                    <h1>
                        {savedAddresses && savedAddresses.length > 0
                            ? "   Select delivery address"
                            : "Fill Delivery Address"}
                    </h1>
                </div>
                <div className="ca-main-body">
                    <Link
                        to="/dashboard/new-address"
                        className="ca-new-saved ca-saved"
                        onClick={() => {
                            window.scroll({ top: 0 });
                            setfromFlag(!fromFlag);
                        }}
                    >
                        <img src={DeliveryImg} alt="Add new address" />
                        <h5>Add new Address</h5>
                    </Link>

                    <div className="ca-saved-addresses">
                        {savedAddresses &&
                            savedAddresses.length > 0 &&
                            savedAddresses.map((item, index) => {
                                return (
                                    <div key={index}
                                        className={`ca-saved-items ca-saved ${item.default_address}`}
                                    >
                                        <h5>{item.full_name}</h5>
                                        <p>{item.address_line_1}</p>
                                        <p>
                                            {item.country_data.name},{item.state_data.name} {item.zip}
                                        </p>
                                        <p>{item.mobile_no}</p>
                                        <div className="address-edit-remove">
                                            <Link
                                                to="/dashboard/Update-address"
                                                onClick={(e) => {
                                                    handleAddressEdit(e, item);
                                                }}
                                            >
                                                <span>
                                                    <FaEdit /> Edit
                                                </span>
                                            </Link>
                                            <span
                                                onClick={(e) => {
                                                    handleAddressDelete(e, item);
                                                }}
                                            >
                                                <RiDeleteBin2Line /> Remove
                                            </span>
                                        </div>
                                        {getAddressInfo.delivery_address !== undefined &&
                                            getAddressInfo.billing_address !== undefined ? (
                                            <div className="address-choice">
                                                {getAddressInfo.delivery_address.id !== item.id ? (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="delivery_address"
                                                            name="delivery_address"
                                                            value={item}
                                                            onChange={(e) => {
                                                                selectAddressFor(e, item);
                                                            }}
                                                        />
                                                        <label htmlFor="delivery_address">
                                                            Delivery Address
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <p><FaCheck/> Selected for delivery</p>
                                                )}
                                                {getAddressInfo.billing_address.id !== item.id ? (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="billing_address"
                                                            name="billing_address"
                                                            value={item}
                                                            onChange={(e) => {
                                                                selectAddressFor(e, item);
                                                            }}
                                                        />
                                                        <label htmlFor="billing_address">Billing Address</label>
                                                    </div>
                                                ) : (
                                                    <p><FaCheck/> Selected for Billing</p>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* <div className="delivery-btn wrap">
                                            <Link onClick={(e) => { finalShippingAddress(e, item) }} className="button" to="/order-summary">Deliver to this address</Link>
                                        </div> */}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ChooseAddress);
