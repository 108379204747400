import React, { useEffect, useState } from "react";
import "./product-detail.scss";
// import Header from "../../layouts/header/Header";
// import Footer from "../../layouts/footer/Footer";
import RelatedProducts from "../../layouts/slider/RelatedProducts";
import FrequentlyBoughtTogether from "../../layouts/frequently-bought/Frequently-bought-together";
import ReviewDiscription from "../../layouts/review-discription/Review-discription";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  FormControl,
  Form,
  FormLabel,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
// import Item_img1 from "../../../assets/product/civil_lab1.jpg";
// import Drillimage from "../../../assets/product/drill.jpg";
// import Item_img2 from "../../../assets/product/drill2.jpg";
// import Item_img3 from "../../../assets/product/drill3.jpg";
// import Item_img4 from "../../../assets/product/drill4.jpg";
import { AiFillStar, AiTwotoneHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactImageMagnify from "react-image-magnify";

import {
  GET_PRODUCT_DETAILS,
  globalAction,
  ADD_TO_CART,
  SHOW_TOAST,
  CHECK_DELIVERY,
  SET_DELIVERY_STATUS,
  SAVE_PRODUCT,
  ADD_COMPARE,
  ADD_TO_COMPARE,
  ADD_TO_CART_INTO_DB,
  DELETE_ADD_TO_CART_FROM_DB,
  UPDATE_QTY_ADD_TO_CART_INTO_DB,
  SET_BUY_NOW_ITEM
} from "../../../redux";
import { BiRupee } from "react-icons/bi";
import { FaMinus, FaPlus, FaSignal } from "react-icons/fa";
import CustomModal from "../../CustomModal/CustomModal";
import { GiCheckMark } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import AddToCompareBox from "../AddToCompare/AddToCompareBox";
import { MdCompareArrows } from "react-icons/md";
import SeoSetting from "../seoSetting/seoSetting";

function ProductDetail({ history, match }) {
  const dispatch = useDispatch();
  const { products_detail, related_products, Check_Delivery } = useSelector(
    (state) => state.AppReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { visibleMessage, isLoading } = useSelector(
    (state) => state.errorReducer
  );
  const { cart, compareList } = useSelector((state) => state.AppReducer);
  const [totalPrice, settotalPrice] = useState(0);
  const [imageIndex, setimageIndex] = useState(0);
  const [productIndex, setproductIndex] = useState(-1);
  const [modalFlag, setModalFlag] = useState(false);
  const [pincode, setPincode] = useState("");
  // const [tempImage, settempImage] = useState(null)
  const [itemQuantity, setItemQuantity] = useState(1);
  const [quantityAlert, setQuantity] = useState("");
  const [selectedVariant, setSelectedVariant] = useState(undefined);
  const [defaultSalePrice, setdefaultSalePrice] = useState(undefined);
  const [defaultRegularPrice, setdefaultRegularPrice] = useState(undefined);

  const modalToggle = () => {
    setModalFlag(!modalFlag);
  };

  const productId = match.params.id;
  const { cat_id, sub_catId } = match.params;


  const calculateTotalPrice = (cart) => {
    let total = 0;
    if (cart.length > 0) {
      cart.map((item, i) => {
        total += parseFloat(item.sale_price * item.quantity);
        return total;
      });
      settotalPrice(Number(total).toFixed(0));
    }
  };

  const findIteminCartList = (cart, id) => {
    if (cart.length > 0) {
      let index;
      index = cart.findIndex((data) => data.id == id);
      setproductIndex(index);
      return index;
    }
  };

  const addToCart = (item, buyNow = false) => {
    let itemWithQuantiy = { ...item, quantity: itemQuantity };
    let updateCart = localStorage.getItem("cart");
    let updateCartFlag = true;
    updateCart = JSON.parse(updateCart);
    console.log('addToCart',updateCart)
    if (updateCart !== null) {
      const index = findIteminCartList(updateCart, id);
      if (index > -1) {
        let selectVariantSalePriceSame =
          selectedVariant &&
          selectedVariant.sale_price === updateCart[index].sale_price
            ? true
            : false;
        let selectVariantRegularPriceSame =
          selectedVariant &&
          selectedVariant.regular_price === updateCart[index].regular_price
            ? true
            : false;
        // console.log('selectVariantRegularPriceSame',selectVariantRegularPriceSame)
        // console.log('selectVariantRegularPriceSame',selectVariantRegularPriceSame)
        if (selectedVariant === undefined) {
          selectVariantSalePriceSame = true;
          selectVariantRegularPriceSame = true;
        }
        if (
          updateCart[index].quantity == itemWithQuantiy.quantity &&
          selectVariantSalePriceSame &&
          selectVariantRegularPriceSame
        ) {
          updateCartFlag = false;
        } else {
          updateCart[index] = itemWithQuantiy;
        }
      } else {
        updateCart.push(itemWithQuantiy);
      }
    } else {
      updateCart = [itemWithQuantiy];
    }
    if (updateCartFlag) {
      const data = {
        msg: `${item.title} are successfully added in your cart`,
        status: "success",
      };
      localStorage.setItem("cart", JSON.stringify(updateCart));
      console.log('updateCart',updateCart)
      dispatch(globalAction(ADD_TO_CART, updateCart));
      let findItem=cart.find((findItem,i)=>item.id===updateCart[i].id)
      if(user){
        if(findItem?.order_id){
          dispatch(globalAction(UPDATE_QTY_ADD_TO_CART_INTO_DB, {data:updateCart[0],userId:user.id}));
        }
        else{
          dispatch(globalAction(ADD_TO_CART_INTO_DB, {userId:user.id}));
        }
      }
      dispatch(globalAction(SHOW_TOAST, { data }));
      calculateTotalPrice(updateCart);
    } else {
      const data = {
        msg: `Already added in your cart with same quantity`,
        status: "Warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
    if (buyNow) {
      history.push("/cart");
    }
    localStorage.removeItem('buyNow')
  };

  const buyNow=(item)=>{
    let itemWithQuantiy = { ...item, quantity: itemQuantity };
    localStorage.setItem('buyNow',JSON.stringify([itemWithQuantiy]))
    dispatch(globalAction(SET_BUY_NOW_ITEM,[itemWithQuantiy]))
    history.push("/cart");
  }

  /** Change  Items Quantity in cart */
  const quantityChange = (text, id, quantityLimit) => {
    if (text === "add") {
      let value = itemQuantity + 1;
      setQuantity("");
      if (value > quantityLimit) {
        value = quantityLimit;
        setQuantity(
          `Only ${quantityLimit} item per customer. If you buy in bulk contact us.`
        );
      }
      setItemQuantity(value);
    } else {
      if (itemQuantity > 1) {
        let value = itemQuantity - 1;
        setQuantity("");
        if (value < quantityLimit) {
          value = quantityLimit;
          setQuantity(`Minimum ${quantityLimit} quantity item per customer`);
        }
        setItemQuantity(value);
      }
    }

  };

  /** Delete Items in cart */
  const DeleteCardItems = (id) => {
    let index = cart.findIndex(function (el) {
      return el.id === id;
    });

    if (index !== -1) {
      if(user){
        dispatch(globalAction(DELETE_ADD_TO_CART_FROM_DB, {data:cart[index],userId:user.id} ));
      }
      cart.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
      const data = {
        msg: `Item successfully deleted in your cart`,
        status: "success",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    // console.log(preStoredItems)
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
      const index = findIteminCartList(preStoredItems, id);
      if (index > -1) {
        setItemQuantity(preStoredItems[index].quantity);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const { id } = match.params;
    findIteminCartList(cart, id);
  }, [visibleMessage]);

  const getProductDetail = async (id) => {
    dispatch(globalAction(GET_PRODUCT_DETAILS, id));
    await history.replace(`/${cat_id}/${sub_catId}/${id}`);
    window.scroll({ top: 0 });
    setSelectedVariant(undefined);
    // showLoading()
    // await getProductDetailById(id);
  };

  useEffect(() => {
    const { id } = match.params;
    getProductDetail(id);
  }, []);

  useEffect(() => {
    setItemQuantity(products_detail.minimum_qty_per_order);
  }, [products_detail]);

  /** Check Delivery  */
  const CheckShippingPlaces = (e, pincode, weigth, cod = 0) => {
    e.preventDefault();
    let weightInKg = weigth;
    if (weight.slice(-2) === "gm" || weight.slice(-2) === "GM") {
      weightInKg = Number(weight.substring(0, weight.length - 2)) / 1000;
    }
    if (pincode.trim() !== "" && weightInKg > 0) {
      dispatch(
        globalAction(CHECK_DELIVERY, {
          delivery_postcode: pincode,
          cod: cod,
          weight: weightInKg,
        })
      );
    }
  };

  useEffect(() => {
    let reqData = { msg: "", COD: "" };
    dispatch(globalAction(SET_DELIVERY_STATUS, reqData));
    setPincode("");
  }, [products_detail.id]);

  const handleAddToCompare = (
    id,
    categoryId,
    subCatId,
    productName,
    imageUrl,
    categorySlug,
    subCategorySlug,
    slug
  ) => {
    
    const { data, products } = compareList;

    if (data.includes(slug)) {
      dispatch(
        globalAction(SHOW_TOAST, {
          data: {
            msg: "Already added in compare list",
            status: "warning",
          },
        })
      );
    } else if (
      data.length === 0 ||
      (compareList.category.id === categoryId &&
        compareList.category.subCategoryId === subCatId)
    ) {
      compareList["category"]["id"] = categoryId;
      compareList["category"]["subCategoryId"] = subCatId;
      compareList["categorySlug"] = categorySlug;
      compareList["subCategorySlug"] = subCategorySlug;
      if (products.length < 3) {
        products.push({ title: productName, image_url: imageUrl });
        data.push(slug);
        dispatch(globalAction(ADD_TO_COMPARE, compareList));
      }
      if (data.length === 3) {
        window.scroll({ top: 0 });
        history.replace("/compare-product");
      }
    } else if (
      compareList.category.id !== categoryId ||
      compareList.category.subCategoryId !== subCatId
    ) {
      dispatch(
        globalAction(SHOW_TOAST, {
          data: {
            msg: "You can only compare similar product",
            status: "warning",
          },
        })
      );
    }
  };

  useEffect(() => {}, [compareList]);

  const {
    id,
    sub_category_name,
    subcategory_id,
    category_id,
    title,
    tax,
    regular_price,
    sale_price,
    product_images,
    image_url,
    description,
    avg_rating,
    product_review_count,
    stock_qty,
    stock_availability,
    brand_value,
    color,
    model_no,
    weight,
    category_name,
    maximum_qty_per_order,
    product_reviews,
    product_variants,
    additional_information,
    meta_descriptions,
    seo_title,
    seo_category,
    seo_tags,
    meta_keywords,
    product_key_features,
    type,
    minimum_qty_per_order,
    category,
    subcategory,
    slug
  } = products_detail;

  console.log("products_detail", products_detail);

  const selectProductVariant = (e, sVariant) => {
    setSelectedVariant(sVariant);
    products_detail["sale_price"] = sVariant.sale_price;
    products_detail["regular_price"] = sVariant.regular_price;
    products_detail["variant_type"] = sVariant.attribute.name;
    products_detail["variant_id"] = sVariant.id;
    products_detail["variant_name"] = sVariant.variant_name;
    products_detail["same_price"] = sVariant.same_price;
    products_detail["title"] = sVariant.product.title +' '+ sVariant.variant_name;
  };

  const resetProductVariant = (e) => {
    products_detail["sale_price"] = selectedVariant.product.sale_price;
    products_detail["regular_price"] = selectedVariant.product.regular_price;
    products_detail["title"] = selectedVariant.product.title;
    products_detail["variant_type"] = undefined;
    products_detail["variant_id"] = undefined;
    products_detail["variant_name"] = undefined;
    products_detail["same_price"] = undefined;
    setSelectedVariant(undefined);
  };

  // console.log('selectedVariant', selectedVariant)
  return (
    <>
      <SeoSetting
        title={`${seo_title || title}-BellstoneOnline`}
        metaDescription={meta_descriptions}
        metaKeywords={meta_keywords}
        tags={seo_tags}
        metaCategory={seo_category}
      />
      <section className="addTocart">
        <div className="bread_crum">
          <Container>
            <Row>
              <ul>
                <li>
                  <Link to="/">
                    Home
                    <span>/</span>
                  </Link>
                </li>

                <li>
                  <Link
                   to={`/${cat_id}`}>
                    {category_name}
                    <span>/</span>
                  </Link>
                </li>
                {sub_catId && (
                  <li>
                    <Link
                    to={`/${cat_id}/${sub_catId}`}
                    >
                      {sub_category_name && sub_category_name}
                      <span>/</span>
                    </Link>
                  </li>
                )}

                <li>
                  {title}
                  <span>/</span>
                </li>
              </ul>
            </Row>
          </Container>
        </div>
        <div className="addtocart_inner spacing cm-button-style">
          <Container>
            <Row>
              <Col md={5} lg={4}>
                <div className="item_img_wrap">
                  <div className="item_img">
                    <ReactImageMagnify
                      style={{ zIndex: 998 }}
                      smallImage={{
                        alt: "Wristwatch by Versace",
                        isFluidWidth: true,
                        src:
                          product_images && product_images.length > 0
                            ? product_images[imageIndex].image
                            : image_url,
                        sizes:
                          "(max-width: 480px) 100vw, (max-width: 1200px) 40vw, 500px",
                      }}
                      largeImage={{
                        src:
                          product_images && product_images.length > 0
                            ? product_images[imageIndex].image
                            : image_url,
                        width: 800,
                        height: 800,
                        shouldUsePositiveSpaceLens: false,
                      }}
                      enlargedImageContainerDimensions={{
                        width: "140%",
                        height: "100%",
                      }}
                      // enlargedImagePosition="over"
                      lensStyle={{
                        background: "hsla(0, 0%, 100%, .7)",
                      }}
                    />
                    {/* <img src={image_url} alt="drill" /> */}
                    {/* <span>-20%</span> */}
                  </div>
                  <aside className="image_slider">
                    {product_images.length > 0 && (
                      <OwlCarousel
                        className="owl-theme"
                        items={3}
                        autoplay={false}
                        dots={false}
                        nav={true}
                        slideBy={1}
                      >
                        {product_images.map(({ id, image }, index) => (
                          <div class="item" key={id}>
                            <img
                              src={image}
                              alt="Bellstone"
                              onClick={() => setimageIndex(index)}
                            />
                          </div>
                        ))}
                      </OwlCarousel>
                    )}
                  </aside>
                </div>
              </Col>
              <Col md={7} lg={5}>
                <div className="item_content ">
                {  console.log(title)}
                  <h5>{title}</h5>
                  <p className="review">
                    <span className="star">
                      {avg_rating ?Number(avg_rating).toFixed(0) : 0}
                      <AiFillStar />
                    </span>
                    <span className="reviews">
                      {product_review_count > 0?`(${product_review_count}) Reviews ` : "review(0)"}
                    </span>
                   
                  </p>
                  {/* <span className="buy"> 23 people bought this recently</span> */}

                  <p>
                    <span className="offer_price">
                      <BiRupee />
                      {sale_price
                        ? Number(sale_price).toFixed(0)
                        : Number(regular_price).toFixed(0)}
                    </span>
                    {sale_price && (
                      <del className="old_price">
                        <BiRupee />
                        {Number(regular_price).toFixed(0)}
                      </del>
                    )}
                    {sale_price && (
                      <small>
                        {(
                          ((Number(regular_price) - Number(sale_price)) /
                            Number(regular_price)) *
                          100
                        ).toFixed(2)}{" "}
                        % OFF
                      </small>
                    )}
                    {tax && (
                      <span className="gst">
                        GST @ {tax}% Inclusive Price:
                        <span className="gst-price">
                          <BiRupee />
                          {sale_price !== null
                            ? Math.ceil(
                                (Number(sale_price) / 100) * Number(tax) +
                                  Number(sale_price)
                              ).toFixed(0)
                            : Math.ceil(
                                (Number(regular_price) / 100) * Number(tax) +
                                  Number(regular_price)
                              ).toFixed(0)}
                        </span>
                      </span>
                    )}
                  </p>

                  <div className="detail">
                    <p>Model: {model_no && model_no}</p>
                    <p>Brand: {brand_value && brand_value.name}</p>
                    <p>Weight: {weight && weight}</p>
                    <p>Color: {color && color}</p>
                  </div>
                  {product_variants !== undefined &&
                    Object.keys(product_variants).length > 0 && (
                      <div className="product-variant-wrap">
                        {Object.entries(product_variants).map((key, i) => {
                          let variantTitle = key[0];
                          let variantData = key[1];
                          return (
                            <div className="p-variant-item">
                              <h6>{variantTitle}</h6>
                              {/* {console.log(variantData)} */}
                              <div className="p-variant-">
                                {/* {selectedVariant !== undefined && (
                                  <span
                                    onClick={(e) => {
                                      resetProductVariant();
                                    }}
                                  >
                                    Default
                                  </span>
                                )} */}
                                {variantData.map((vd, i) => {
                                  return (
                                    <span
                                      className={
                                        selectedVariant &&
                                        selectedVariant.id === vd.id
                                          ? "active"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        selectProductVariant(
                                          e,
                                          vd,
                                          ProductDetail
                                        );
                                      }}
                                    >
                                      {vd.variant_name}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  {/* <p className="about_product">
              <span>Select Power Input</span>
              <span className="selected">350 W</span> <span>360 W</span> <span>450 W</span> <span>550 W</span>
              <span>850 W</span>
            </p> */}

                  <p
                    onClick={() => {
                      handleAddToCompare(
                        id,
                        category_id,
                        subcategory_id,
                        title,
                        image_url,
                        category.slug,
                        subcategory.slug,
                        slug
                      );
                    }}
                    className="add-to-compare"
                  >
                    <MdCompareArrows /> Add to Compare
                  </p>

                  <div className="quantity"></div>
                  {stock_availability === "in_stock" ? (
                    <>
                      {type === "Heavy Weight" ? (
                        <button type="submit" className="cart_button buy_now">
                          Send Enquiry
                        </button>
                      ) : (
                        (selectedVariant !== undefined || product_variants.length<1 ) &&
                        <>
                        <div className="button_section">
                          <div>
                            <div className="si-qty-count">
                              <span
                                className="minus"
                                onClick={() => {
                                  quantityChange(
                                    "sub",
                                    id,
                                    minimum_qty_per_order
                                  );
                                }}
                              >
                                <FaMinus />
                              </span>
                              <span className="count">{itemQuantity}</span>
                              <span
                                className="plus"
                                onClick={() => {
                                  quantityChange(
                                    "add",
                                    id,
                                    maximum_qty_per_order
                                  );
                                }}
                              >
                                <FaPlus />
                              </span>
                            </div>
                            <button
                              type="submit"
                              className="cart_button"
                              onClick={() => addToCart(products_detail)}
                            >
                              Add to cart
                            </button>
                            <div
                              className="product_icon product-page"
                              onClick={() => {
                                saveProductByUser(id);
                              }}
                            >
                              <span className="wishlist">
                                <AiTwotoneHeart />
                                <span className="cm-add-to-wishlist">
                                  {" "}
                                  Add to wishlist
                                </span>
                              </span>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="cart_button buy_now"
                            onClick={() => buyNow(products_detail)}
                          >
                            Buy Now
                          </button>
                        </div>
                        <p className="check-quantiy">{quantityAlert}</p>
                      </>
                        
                       
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="product_icon product-page out of stock"
                        onClick={() => {
                          saveProductByUser(id);
                        }}
                      >
                        <span className="wishlist">
                          <AiTwotoneHeart />
                          <span className="cm-add-to-wishlist">
                            {" "}
                            Add to wishlist
                          </span>
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="cart_button buy_now out-of-stock"
                        disabled={true}
                      >
                        Out Of Stock
                      </button>
                    </>
                  )}
                </div>
              </Col>
              <Col md={12} lg={3} className="bulk_order">
                <div className="check_delivery">
                  <h6>Check delivery and payment options ?</h6>
                  <form
                    onSubmit={(e) =>
                      CheckShippingPlaces(e, pincode, products_detail.weight)
                    }
                  >
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Area PIN Code"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                      />
                      <Button
                        variant="outline-secondary"
                        type="submitt"
                        id="button-addon2"
                      >
                        Check
                      </Button>
                    </InputGroup>
                  </form>
                  {Check_Delivery.msg !== "" && (
                    <div
                      className={`check_delivery_wrap ${
                        Check_Delivery.msg === "Not Available" ? "error" : ""
                      }`}
                    >
                      <ul>
                        <li>
                          {Check_Delivery.msg === "Not Available"
                            ? "Delivery not available"
                            : "Delivery is available"}
                        </li>
                      </ul>
                    </div>
                  )}
                  <p>Shipping charge applicable as per serviceability</p>
                </div>
                <div className="get_bulk">
                  <p>Want to buy even more quantity?</p>
                  <button
                    type="submit"
                    class="button"
                    to="/bulk-order"
                    onClick={() => modalToggle()}
                  >
                    GET BULK QUOTE NOW
                  </button>
                </div>
                {/* <div className="offers">
                <p>
                  Get GST invoice and save up to 28% on business purchases.
                  <Link to="">view more</Link>
                </p>
              </div> */}
              </Col>
            </Row>
          </Container>
        </div>

        <ReviewDiscription
          description={description}
          productReviews={product_reviews}
          productId={productId}
          additional_information={additional_information}
          keyFeatures={product_key_features}
          cat_slug={cat_id}
          sub_catSlug={sub_catId}
        />
        <RelatedProducts
          related_products={related_products}
          getProductDetail={getProductDetail}
          handleAddToCompare={handleAddToCompare}
          cat_slug={cat_id}
          sub_catSlug={sub_catId}
        />
        <CustomModal
          modalFlag={modalFlag}
          modalToggle={modalToggle}
          products_detail={products_detail}
          cat_slug={cat_id}
          sub_catSlug={sub_catId}
        />

        <AddToCompareBox />
      </section>
    </>
  );
}

export default ProductDetail;
