import React, { useEffect } from "react";
import "./slider.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { BiRupee } from "react-icons/bi";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { globalAction, SAVE_PRODUCT, SHOW_TOAST, ADD_TO_CART, ADD_TO_CART_INTO_DB } from "../../../redux";

// import CartImage from "../image/product/123.jpg";
// import CartImage2 from "../image/product/box2_2.jpg";
// import CartImage3 from "../image/product/ladd18.jpg";
// import CartImage4 from "../image/product/tet_1_1.jpg";
// import CartImage5 from "../image/product/tr.jpg";

function BestSellingProducts({ best_selling_product }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { cart } = useSelector((state) => state.AppReducer);

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} already added in your cart`,
            status: "Warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} are successfully added in your cart`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if(user){
          dispatch(globalAction(ADD_TO_CART_INTO_DB, {userId:user.id}));
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

 

  const { data } = best_selling_product;
  return (
    <section className="best_selling_product product_cart">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>Best Selling Products</h3>
          </Col>
          {data.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              items={6}
              autoplay={false}
              smartSpeed={500}
              nav={true}
              slideBy={2}
              responsive={{
                0: {
                  items: 1,
                  slideBy: 1,
                },
                417: {
                  items: 2,
                  slideBy: 2,
                },
                576: {
                  items: 3,
                  slideBy: 3,
                },
                1000: {
                  items: 4,
                  slideBy: 4,
                },
              }}
            >
              {data.map((item) => {
                const { title, id, regular_price, sale_price, image_url, stock_qty,slug,category,subcategory } = item;
                return (
                  <div class="item" key={id}>
                    <div className="cart">
                      <Link className={stock_qty <= 0 && "outofstock"} 
                      to={`/${category.slug}/${subcategory.slug}/${slug!==null?slug:id}`}
                      >
                        <img src={image_url} alt="selling img" />
                      </Link>
                      <div className="cart_body">
                        <div className="product-heading">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {title}
                          </Tooltip> }
                          >

                            <Link 
                              to={`/${category.slug}/${subcategory.slug}/${slug!==null?slug:id}`}
                             className="d-inline-flex align-items-center" >
                              <h5>{title.slice(0, 30)}...</h5>
                            </Link>

                          </OverlayTrigger>
                        </div>
                        <p>
                          <span>Regular Price:</span>
                          <span>
                            <BiRupee />
                            {regular_price}
                          </span>
                        </p>
                        <p>
                          <span>Special Price:</span>
                          <span>
                            <BiRupee />
                            {sale_price}
                          </span>
                        </p>
                        <div className="product_icon">
                          <span className="wishlist">
                            <AiTwotoneHeart onClick={() => saveProductByUser(id)} />
                          </span>
                          <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && addToCart(item)}>
                            <FaShoppingCart />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default BestSellingProducts;
