import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLogo from "../../../assets/logo.png";
import { globalAction, RESEND_OTP, SHOW_LOADING, SIGNUP_VERIFY } from "../../../redux";
import './OTPVerify.css'
import AnimateIcon from '../../AnimatedLogo/AnimatedLogo'
import { useHistory } from "react-router";




function OTPVerify() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { visibleMessage, isLoading, message } = useSelector(state => state.errorReducer)
    const { user } = useSelector(state => state.authReducer)
    const [userEmail, setUserEmail] = useState(undefined);
    const [OTP, setOTP] = useState(undefined);
    const [checkEmpty, setcheckEmpty] = useState(false)
    const [resendBtnDisable, setResendBtnDisable] = useState(false)
    const [resendTime, setResendTime] = useState(60)

    useEffect(() => {
        setUserEmail(localStorage.getItem("bellStone_verifyEmail"));
        if (user !== null) {
            history.push('/dashboard')
        }
        return () => {
            setUserEmail(localStorage.getItem(undefined));
        };


    }, []);

    useEffect(() => {
        if (resendBtnDisable && resendTime>0) {
            setTimeout(() => {
                setResendTime(resendTime - 1)
            }, 1000);
        }
        else{
            setResendBtnDisable(false)
            setResendTime(60)
            
        }
    }, [resendBtnDisable, resendTime]);




    const handleSubmit = (() => {
        if (OTP.trim() === '') {
            setOTP(undefined)
            setcheckEmpty(true)
        }
        else {

            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(SIGNUP_VERIFY, { OTP: OTP, email: userEmail, cmhistory: history }))

        }

    })


    const handleResend = (() => {
        setResendBtnDisable(true)
    
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(RESEND_OTP, { email: userEmail }))


    })






    console.log(resendBtnDisable)

    return (
        <div className="otp-verify-main-wrap">
            {isLoading && <AnimateIcon />}
            <div className="otp-verify-inner">
                <div className="logo section">
                    <div className="logo">
                        <img src={MainLogo} alt="Bellstone" />
                    </div>
                </div>
                <div className="otp-content">
                    <h3>Verify your email</h3>
                    <p>
                        You need to verified your email account for first time to login your
                        acccount.{" "}
                        We already sent a verification code to your email{" "}
                        <span className="otp-email">{`( ${userEmail} )`}</span>
                    </p>
                    <p
                        className={`otp-resend ${resendBtnDisable?'--disable':''}`}
                        onClick={() => {!resendBtnDisable&& handleResend()}}
                    >Resend Code({resendTime}s)</p>
                </div>
                <div className={`otp-form ${checkEmpty === true ? 'error' : ''}`}>
                    <input
                        type="text"
                        onChange={(e) => {
                            setOTP(e.target.value); setcheckEmpty(false)
                        }}
                        id="otp" name="otp"
                    />
                    {
                        checkEmpty &&
                        <small className='error-req'>*Required</small>
                    }
                </div>
                {
                    OTP !== undefined &&
                    <div className="otp-btn animate-pop-in">
                        <button onClick={() => handleSubmit()} className="button" type='button'>
                            Verify
                        </button>
                    </div>
                }

            </div>
        </div>
    );
}

export default OTPVerify;
