import React from "react";
import { Navigation } from "react-minimal-side-navigation";
import { withRouter } from "react-router-dom";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import "./sidebar.scss";
function UserSidebar(props) {
  return (
    <Navigation
      // you can use your own router's api to get pathname
      activeItemId={props.location.pathname}
      onSelect={({ itemId }) => {
        console.log(itemId)
        props.history.push(itemId);
      }}
      items={[
        {
          title: "Dashboard",
          itemId: "/dashboard",
          elemBefore: () => <i className="fa fa-th-large"></i>,
        },
        {
          title: "My Account",
          itemId: "/dashboard/my-account",
          elemBefore: () => <i className="fa fa-user"></i>,
        },
        {
          title: "Saved Address",
          itemId: "/dashboard/Address",
          elemBefore: () => <i className="fa fa-save"></i>,
        },
        {
          title: "My Order",
          itemId: "/dashboard/my-order",
          elemBefore: () => <i className="fa fa-shopping-cart"></i>,
        },
        {
          title: "Saved Products",
          itemId: "/dashboard/saved-products",
          elemBefore: () => <i className="fa fa-save"></i>,
        },
        {
          title: "Bulk Enquiry",
          itemId: "/dashboard/bulk-Enquiry",
          elemBefore: () => <i className="fa fa-comments"></i>,
        },
        // {
        //   title: "Help-desk",
        //   itemId: "/dashboard/help-desk",
        //   elemBefore: () => <i className="fa fa-comments"></i>,
        // },
      ]}
    />
  );
}
export default withRouter(UserSidebar);
