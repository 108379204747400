import React, { useState, useEffect } from 'react'
import { Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BulkEnquiry.css'
import { globalAction } from "../../../redux/actions";
import { GET_BULK_ENQUIRY_LIST, SHOW_LOADING,DELETE_BULK_ENQUIRY } from "../../../redux/actionTypes";
import { useSelector, useDispatch } from 'react-redux'
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import { FcNext, FcPrevious } from "react-icons/fc";
import moment from "moment";
import Toast from "../../Toast/Toast";


function BulkEnquiry(props) {
    const dispatch = useDispatch();
    const { user: { id } } = useSelector(state => state.authReducer)
    const { bulkEnquiryList } = useSelector(state => state.AppReducer)
    const { status, message, visibleMessage,isLoading } = useSelector((state) => state.errorReducer);
    const [tempNumber, setTempNumber] = useState(0)

    const [limit, setlimit] = useState(5);
    useEffect(() => {
        let data = `?user_id=${id}&limit=${limit}`
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(GET_BULK_ENQUIRY_LIST, data))
    }, [])

  



    const selectPage = (pageNumber) => {
        window.scroll({ top: 0 })
        let data = `?user_id=${id}&limit=${limit}&page=${pageNumber}`
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(GET_BULK_ENQUIRY_LIST, data))
    }

    const deleteEnquiry = (Itemid,currentPage) => {
        let data = `?user_id=${id}&limit=${limit}&page=${currentPage}`
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(DELETE_BULK_ENQUIRY,{Itemid:Itemid,data:data}))   
    }


    


    return (
        isLoading
            ?
            <AnimatedLogo />
            :
            <div className="bulk-enquiry-wrap">
                  <Toast message={message} status={status} show={visibleMessage} />
                <div class="my-heading">All Enquires</div>
                <div className="bulk-wrap">
                    {(bulkEnquiryList.data !== undefined || bulkEnquiryList.length>0) && (bulkEnquiryList.data).length>0 ?


                      (bulkEnquiryList.data).map((item, i) => {
                            return (
                                <div className="bulk-item">
                                    <h5>Enquiry Id : {`#${item.id} `}<span className="bulk-request-date">{`( ${moment(item.created_at).format("LL")} )`}</span> <span className="cm-btn request_status">Pending</span></h5>
                                    <div className="bulk-content-wrap">
                                        <ul className="content-col">
                                            <li >
                                                <Link className="productName" to={`/product-detail/${item.product.id}`}> <p>{item.product.title}</p> </Link>
                                            </li>
                                            <li>
                                                <small>Product quantity which you inquired ?</small>
                                                <p>{item.qty} <small>Pieces</small></p>
                                            </li>
                                            <li>
                                                <small>Your Addditional Message for bellstone is</small>
                                                <p>{(item.message)}</p>
                                            </li>
                                        </ul>
                                        <div className="btn-col">
                                            <Link to={`/product-detail/${item.product.id}`} className="cm-btn" >Product Info</Link>
                                            <button className="cm-btn cancel-request"
                                             onClick={(()=>{deleteEnquiry(item.id,bulkEnquiryList.current_page)})}
                                              >Delete Request</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="no-bulk-item-wrap">
                            <div className="no-bulk-item">
                                <h1>No enquiries</h1>
                            </div>
                        </div>
                    }
                </div>
                {(bulkEnquiryList !== null) ?
                    <div className="pagination-wrap">
                        <div >
                            {bulkEnquiryList.current_page > 1 &&

                                <div className="prev-page"
                                    onClick={((e) => { selectPage(bulkEnquiryList.current_page - 1) })}>
                                    <FcPrevious />  <span> Prev</span>
                                </div>
                            }
                        </div>

                        {bulkEnquiryList.last_page !== bulkEnquiryList.current_page &&
                            <div className="next-page" onClick={((e) => { selectPage(bulkEnquiryList.current_page + 1) })}>
                                <span> Next</span> <FcNext />
                            </div>
                        }

                    </div>

                    : ''
                }


            </div>

    )
}

export default BulkEnquiry
