import React, { useState, useEffect } from "react";
import "./AddToCompare.css";
import { useSelector, useDispatch } from "react-redux";
import { ImCross } from "react-icons/im";
import {
    ADD_TO_CART,
    ADD_TO_CART_INTO_DB,
    ADD_TO_COMPARE,
    GET_COMPARED_PRODUCTS,
    GET_SEARCH_PRODUCT,
    globalAction,
    SAVE_PRODUCT,
    SHOW_TOAST,
} from "../../../redux";
import { Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import ReactStars from "react-rating-stars-component";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import { FaSearch } from "react-icons/fa";
import Demoimg from '../../../assets/product/123.jpg'
import parser from 'react-html-parser'


function AddToCompare() {
    const { cart } = useSelector((state) => state.AppReducer);
    const {
        compareList: { products },
        compareList,
        compareProducts,
        searchForCompare
    } = useSelector((state) => state.AppReducer);
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.errorReducer);
    const { user } = useSelector((state) => state.authReducer);

    const saveProductByUser = (product_id) => {
        if (user) {
            // saveProduct({product_id,user_id:user.id})
            dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
        } else {
            const data = {
                msg: "Please login or Register to save this product.",
                status: "warning",
            };
            dispatch(globalAction(SHOW_TOAST, { data }));
        }
    };
    const addToCart = (item) => {
        let flag = true;
        if (item) {
            var newArray = cart.filter(function (el) {
                if (el.id === item.id) {
                    const data = {
                        msg: `${item.title} are successfully added in your cart`,
                        status: "Warning",
                    };
                    dispatch(globalAction(SHOW_TOAST, { data }));
                    flag = false;
                }
            });
            if (flag) {
                item.quantity = 1;
                cart.push(item);
                const data = {
                    msg: `${item.title} are successfully added in your cart`,
                    status: "success",
                };
                dispatch(globalAction(SHOW_TOAST, { data }));
                localStorage.setItem("cart", JSON.stringify(cart));
                dispatch(globalAction(ADD_TO_CART, cart));
                if(user){
                    dispatch(globalAction(ADD_TO_CART_INTO_DB, {userId:user.id}));
                  }
            }
        }
    };

    const productSearchHandle = ((e) => {
        const { category, categorySlug, subCategorySlug } = compareList;
        let searchText = e.target.value
        if (category.id !== undefined && category.subCategoryId !== undefined) {
            let param = `?category_slug=${categorySlug}&subCategorySlug=${subCategorySlug}&limit=100&search=${searchText.trim() !== '' ? searchText : ''}`;
            dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: undefined, getForCompare: true }))
        }
    })


    const handleAddToCompare = (id, categoryId, subCatId, productName, imageUrl,slug) => {
        const { data, products,categorySlug, subCategorySlug} = compareList;
        if (data.includes(id)) {
            dispatch(
                globalAction(SHOW_TOAST, {
                    data: {
                        msg: "Already added in compare list",
                        status: "warning",
                    },
                }),
            );
        } else if (true || (compareList.category.id === categoryId && compareList.category.subCategoryId === subCatId)) {
            compareList["category"]["id"] = categoryId;
            compareList["category"]["subCategoryId"] = subCatId;
            compareList["categorySlug"] = categorySlug;
            compareList["subCategorySlug"] = subCategorySlug;
            products.push({ title: productName, image_url: imageUrl });
            data.push(slug);
            dispatch(globalAction(ADD_TO_COMPARE, compareList));
            dispatch(globalAction(GET_COMPARED_PRODUCTS, compareList));

        }
    }

    const removeComparedProduct = ((name, dataItem) => {
        let tempProduct = compareProducts.filter(function (item) {
            return item.name !== name
        })
        let tempdata = compareList.data
        tempdata = tempdata.filter(function (item) {
            return item !== dataItem
        })

        let tempCompareList = {
            data: tempdata,
            products: tempProduct,
            category: {
                id: compareList.category.id,
                subCategoryId: compareList.category.subCategoryId,
            },
            categorySlug:compareList?.categorySlug,
            subCategorySlug:compareList?.subCategorySlug
        }
        dispatch(globalAction(ADD_TO_COMPARE, tempCompareList))
        localStorage.setItem("BS_ComparedProductList", JSON.stringify(tempCompareList))
    })


    useEffect(() => {
        const { data, category, categorySlug, subCategorySlug } = compareList;
        let param;
        if (data && data.length > 0) {
            dispatch(globalAction(GET_COMPARED_PRODUCTS, compareList));
        }
        if (category.id !== undefined && category.subCategoryId !== undefined ) {
            param = `?category_slug=${categorySlug}&subCategorySlug=${subCategorySlug}&limit=100`;
            dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: undefined, getForCompare: true }))
        }
    }, [compareList]);


    // console.log("compareProducts", compareProducts);
    // console.log("searchForCompare", searchForCompare);

    return (
        <div className="add-to-compare-main-wrap">
            <div className="top-header-section">
                <h4>Compare Products</h4>
            </div>
            {isLoading ? (
                <AnimatedLogo />
            ) : (
                <div className="atc-list-inner">
                    {compareProducts.length > 0 && (
                        compareProducts.map((item, index) => {
                            console.log(item)
                            const {
                                title,
                                id,
                                image_url,
                                regular_price,
                                sale_price,
                                weight,
                                description,
                                avg_rating,
                                additional_information,
                                product_review_count,
                                brand_name,
                                stock_qty,
                                category,
                                sub_category_name,
                                slug
                            } = item;
                            return (

                                <div
                                    className="atc-list-inner-item animate-pop-in"
                                    style={{ width: `${100 / compareProducts.length}%` }}
                                    key={item.id}
                                >
                                    <div className="img-wrap">
                                        <Link to={`product-detail/${slug}`}>
                                            {" "}
                                            <img src={image_url} alt={title} />
                                        </Link>
                                    </div>

                                    <div className="name ">
                                        <h5>
                                            <Link  to={`/${compareList?.categorySlug}/${sub_category_name}/${slug}`} >{title}</Link>
                                        </h5>
                                        <p>
                                            <strong>by </strong>
                                            {brand_name}
                                        </p>
                                    </div>
                                    <div className="rating">
                                        <ReactStars
                                            edit={false}
                                            value={
                                                avg_rating !== null ? Number(avg_rating).toFixed(2) : 0
                                            }
                                        />
                                        <p>Reviews({product_review_count})</p>
                                    </div>
                                    <div className="price">
                                        <h5>
                                            Price : <BiRupee />
                                            {sale_price === null ? regular_price : sale_price}
                                        </h5>
                                        {sale_price !== null ? (
                                            <del>
                                                {" "}
                                                <BiRupee />
                                                {regular_price}
                                            </del>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {weight && (
                                        <div className="price">
                                            <h5>Weight :{weight}</h5>
                                        </div>
                                    )}

                                    {/* {description && (
                                        <div className="desc">
                                            <h5>Description</h5>
                                           
                                            {parser(description)}
                                        </div>
                                    )} */}

                                    {additional_information && (
                                        <div className="additional-info">
                                            <h5>Additional Information</h5>
                                            <p>{additional_information}</p>
                                        </div>
                                    )}

                                    <div className="add-to-wishlist-btn">
                                        {stock_qty > 0 ? (
                                            <button
                                                className="button add-to-cart"
                                                onClick={() => addToCart(item)}
                                            >
                                                Add to cart
                                            </button>
                                        ) : (
                                            <button className="button add-to-cart out-of-stock">
                                                Out of Stock
                                            </button>
                                        )}

                                        <button
                                            className="button wishlist"
                                            onClick={() => {
                                                saveProductByUser(id);
                                            }}
                                        >
                                            Add to wishlist
                                        </button>
                                    </div>
                                    {compareProducts.length !== 1 ?
                                        <div
                                            className="remove-product"
                                            onClick={() => { removeComparedProduct(title,item?.slug) }}
                                        >
                                            <ImCross />
                                        </div>
                                        :
                                        ''
                                    }

                                </div>


                            );
                        })
                    )}

                    {(compareProducts.length) < 4 &&
                        <div className="atc-search-box-wrap">
                            <div className="atc-search-box-inner">
                                <div className="atc-search-title">
                                    <h3>Add product to compare </h3>
                                </div>
                                <div className="atc-search-wrap">
                                    <input
                                        type='text'
                                        name="atc-search"
                                        placeholder="Search... "
                                        onChange={(e) => { productSearchHandle(e) }}>

                                    </input>
                                    <div className="atc-search-icon">
                                        <FaSearch />
                                    </div>

                                </div>
                                <div className="atc-search-items">
                                    {searchForCompare.length > 0 ?
                                        searchForCompare.map((item, i) => {
                                            return (
                                                <div
                                                    className={`atc-search-item animate-pop-in ${(compareList.data).find((ele) => { return (item.id == ele) }) ? "cm-hide" : ''}`}
                                                    onClick={() => { handleAddToCompare(item.id, item.category_id, item.subcategory_id, item.title, item.image_url,item.slug) }}

                                                >
                                                    {/* id, categoryId, subCatId, productName, imageUrl */}
                                                    <ul>
                                                        <li className="atc-search-img"
                                                        >
                                                            <img src={item.image_url} alt={item.title} />
                                                        </li>
                                                        <li className="atc-search-item-content">
                                                            <h6>
                                                                {item.title}
                                                            </h6>
                                                            <p>
                                                                <BiRupee />{item.sale_price !== null ? item.sale_price : item.regular_price}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )

                                        })
                                        :
                                        <div className="no-product-found">
                                            <p>No product found..!</p>
                                        </div>
                                    }

                                </div>

                            </div>

                        </div>

                    }
                </div>
            )
            }
        </div >
    );
}

export default AddToCompare;
