import React, { useEffect ,useState} from "react";
import "./termsCondition.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getTermsConditionApi } from "../../../api";
import parser from 'react-html-parser'

function TermsCondition() {
  const [state, setState] = useState({
    title:"",
    description:""
  })
  useEffect(() => {
    document.title = "Terms & Condition";
    getTermsConditionApi().then(res => {
      const { status, data } = res;
      if (status === 200) {
        setState(data.terms_condition)
      }
    })
  }, []);
  return (
    <section className="term_condition">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms and Conditions</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>TERMS & CONDITIONS OF SALE:(BETWEEN SELLER AND THE CUSTOMER)</h5>
              </div>
              <div className="common_pages_content">
                <div className="date">
                  <span>Last updated on</span> <span>29-March-2017</span>
                </div>
                <div className="common_pages_inner">
                {parser(state.description)}
                  {/* <h6>Please read these conditions carefully before placing an order for any products with the (the website). These conditions signify your agreement to be bound by these conditions here in.</h6>

                  <p>When you use bellstoneonline.com in service, you will also be subject to the terms and conditions applicable to that service ("Terms"). If these Conditions of Sale are inconsistent with such Terms, the Terms will be controlled by the management of company website.</p>
                  <p>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>
                  <p>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>
                  <h6>FOR INTERNATIONAL</h6>
                  <p>Prices Ex Delhi Warehouse, Duty & Taxes, Shipping, Clearance Country Extra on Customers Own Cost.</p>

                  <h6>CONDITIONS REGARDING SALE OF PRODUCTS TO CUSTOMER</h6>
                  <p>This section deals with conditions relating to the sale of products on the website by us to you.</p>

                  <h6>OUR CONTRACT</h6>
                  <p>
                    Your order is an offer to us to buy the product(s) in your order. When you place an order to purchase a product from us, you will receive an e-mail/sms confirming receipt of your order and containing the details of your order (referred as the "Order Confirmation E-mail/SMS"). The Order Confirmation E-mail is acknowledgement that we have received your order and does not confirm acceptance of your offer to buy the product(s) as ordered. We only accept your offer and conclude the contract of sale for a product ordered by you. When the ordered goods/product is dispatched to you and an e-mail/SMS confirmation is sent to you that signify that the product has been dispatched to you. If your order is dispatched in more than one packet, you may receive a separate Dispatch
                    Confirmation SMS/ E-mail for each packet, and each Dispatch Confirmation SMS/E-mail and corresponding dispatch will conclude a separate contract of sale between you and us for the product(s) specified in that Dispatch Confirmation SMS/E-mail.
                  </p>

                  <h6>DECLARATION</h6>
                  <p>Your contract is with us and you confirm that the product(s) ordered by you are for your internal / personal purpose and not for re-sale / commercialization. You authorize us to declare and provide declaration to any government authority on your behalf stating the aforesaid purpose of the products ordered by you on the website.</p>

                  <h6>PRICING AND AVAILABILITY</h6>
                  <p>The mentioned in the site are not subject to comparison with the same or similar products and or services available through this site. The pricing is subject to our price policy and shall be determined only at our sole discretion. Your order will be dispatched if the same is available with us. All prices are exclusively of VAT and transportation service unless stated otherwise.</p>

                  <h6>TAXES</h6>
                  <p>You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from us and you agree to bear any and all applicable taxes/VAT, cesses etc. levied thereon.</p>

                  <h6>MODE OF PAYMENT</h6>
                  <p>The customer may pay either through credit / debit card or through Net banking. Cash on Delivery is also available subject to particular scheme as applicable to us.</p>

                  <h6>CANCELLATION & REFUND POLICY:</h6>
                  <ol>
                    <li>
                      Cancellation by us:
                      <ul>
                        <li>There may be certain orders that we are unable to accept the same. We reserve the right, at our sole discretion, to refuse or cancel any order.</li>
                      </ul>
                    </li>

                    <li>
                      Cancellation by users:
                      <ul>
                        <li>You can cancel your order for a product at any time except shipment. Incase of request of order for cancellations, we reserve the right to accept or reject the request of orders of cancellation. If we receive an order for cancellation when the order is not under processed, we shall cancel the order and refund the entire amount within 2 to 7 working days.</li>
                        <li>On the other-hand, a request for cancellation is accepted only if they are made within 24 hours from making of the order on the website. The user agrees not to dispute the decision made by us and accepts our decision regarding the cancellation. The amount will be refunded in the same account as you had made the payment within 2 to 7 working days to initiate the refund.</li>
                      </ul>
                    </li>
                  </ol>

                  <h6>COLORS:</h6>
                  <p>While we have made every effort to display as accurate as possible, the colors of the products that appear on the website, we can not guarantee that your monitor or screen display of any color will be accurate.</p>

                  <h6>DELIVERY INFORMATION</h6>
                  <p>Bellstoneonline.com provides delivery services fastly through standard courier. The shipment goods are to be delivered within 2 to 7 working days from the date of order and 3 to 4 working days from the date of dispatch of goods. Bellstoneonline.com is neither responsible for nor liable to return/refund/replacement/exchange of goods for the delay caused by the transportation services.</p>

                  <h6>TRACKING OF SHIPMENT</h6>
                  <p>Bellstoneonline.com provides details of shipment through email alters/sms to the customer while package is handed over to transportation. The email alerts shall contain the tracking number and expected date of delivery. Customers can track the shipment by using the tracking number. It is ensure that the tracking number may take up to 24 business hours to become active for tracking through website.</p>

                  <h6>RETURNS</h6>
                  <p>Return is made available within 48 hours from the date of delivery of goods and the amount of the articles to be paid to customers through bank as per company policy within 2 to 7 working days from the date of pick up the goods.</p>
                  <p>Returned Product Will Be Accept Only in India.</p>

                  <h6>INCASE OF DELAY</h6>
                  <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause (namely earthquake, war, fire, communal riots, flood, man-power etc.) which is beyond our reasonable control. This condition does not affect your statutory rights.</p>

                  <h6>REPLACEMENT</h6>
                  <p>If the customer received an item in a damaged / defective condition on that event the said item will be replaced. No extra cost will be charged on replacement. Children / Minor</p>
                  <p>Use of bellstoneonline.com is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may purchase only with the involvement of a parent or guardian.</p>

                  <h6>COMMUNICATIONS</h6>
                  <p>When you send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication etc. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order.</p>

                  <h6>LOSSES</h6>
                  <p>We will not be responsible for any business loss (including loss of profits, revenue, contracts, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of goods by us to you was formed.</p>

                  <h6>ALTERATION OR AMENDMENTS TO THE CONDITIONS</h6>
                  <p>We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority. If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>

                  <h6>REVIEWS, COMMENTS, COMMUNICATIONS AND OTHER CONTENTS</h6>
                  <p>Users of this website may post reviews, comments and other content, send communications and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal. Bellstoneonline.com reserves its right to terminate your account on any illegal manner used by the users.</p>
                  <p>You agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such contents and materials.</p>

                  <h6>ARBITRATION:</h6>
                  <p>If at any time, any dispute, difference or controversy arise between the us and you during the course of transactions, in connection with the validity, interpretation, implementation of any provision of this agreement and the policy documents, the disputes shall be referred to Arbitration and the Arbitration proceedings shall be conducted in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time, to be conducted by a sole Arbitrator who shall be appointed by the us / Company. The Arbitration proceedings shall be in English language and the seat of arbitration and the venue of the proceedings shall be at New Delhi.</p>
                  <p> The arbitration award rendered shall be final and binding and shall subject to the provisions of the law for the time being in force be appealed against. The arbitral Tribunal shall be at liberty to award cost of the proceedings etc.</p>

                  <h6>GOVERNING LAW & JURISDICTION:</h6>
                  <p>This agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflicts of laws, principles of rules.</p>
                  <p>This agreement will be subject to the jurisdiction of the courts of the courts at New Delhi irrespective of the court jurisdiction.</p>

                  <h6>BUSINESS HOUR:</h6>
                  <p>The business hour of the company is 10 am to 7 pm (Monday to Saturday) only.</p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TermsCondition;
