import React, { useState, useEffect } from 'react';
import './UpdateAddress.css';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import {
    GET_USER_SAVED_ADDRESS,
    globalAction,
    SHOW_LOADING,
    UPDATE_ADDRESS,
} from "../../../redux";
import { addAddressApi, getCountryApi, getStateApi } from '../../../api';
import { Link} from "react-router-dom";

function UpdateAddress() {
    const { user } = useSelector((state) => state.authReducer)
    const [countryData, setcountryData] = useState([]);
    const [stateData, setstateData] = useState([])
    const [makeDefault, setmakeDefault] = useState(false)
    const [newAddress, setnewAddress] = useState({
        user_id: ' props.userId',
        full_name: '',
        address_line_1: '',
        address_line_2: '',
        country: 0,
        state: 0,
        zip: '',
        mobile_no: ''
    })

    const dispatch = useDispatch();
    const getStateData = async (id) => {
        await getStateApi(id)
            .then(async res => {
                const { data, status } = res;
                if (status === 200) {
                    await setstateData(data.state_list)
                }
            })
        console.log(id)
    }

    const onChangeText = (text, e) => {
        console.log(e.target.value)
        console.log(text)
        if (text === 'country') {
            getStateData(e.target.value);
        }
        let inputData = newAddress;
        inputData[text] = e.target.value;
        setnewAddress(inputData)
    }

    const SavedAddress = (e) => {
        e.preventDefault()
        let finaloject={...newAddress,default_address: makeDefault?'yes':'no'}
        dispatch(globalAction(UPDATE_ADDRESS,finaloject))
        if(user!==null){
            dispatch(globalAction(GET_USER_SAVED_ADDRESS, user.id))
        }
    }

    useEffect(() => {
        getCountryApi().then(res => {
            const { data, status } = res;
            if (status === 200) {
                setcountryData(data.country_list)
            }
        })

        let tempAddress = localStorage.getItem("UpdateAddress");
        tempAddress = JSON.parse(tempAddress);
        getStateData(tempAddress.country)
        setnewAddress(tempAddress)
        setmakeDefault(tempAddress.default_address === "yes" ? true : false)
    }, [])

    return (
        <div className="choose-address-wrap">
            <div className="container">
                <div className="ca-top-section">
                    <h1>
                        Update Address
                    </h1>
                </div>
                <div className="ca-main-body">
                    <div className={`addressform`}>
                        <Form >
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Full Name<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control defaultValue={newAddress.full_name} type="text" placeholder="Full name" onChange={(e) => onChangeText('full_name', e)} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Mobile<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control defaultValue={newAddress.mobile_no} type="number" placeholder="Mobile" onChange={(e) => onChangeText('mobile_no', e)} />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Address Line 1<span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control defaultValue={newAddress.address_line_1} type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_1', e)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress2">
                                <Form.Label>Address Line 2<span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control defaultValue={newAddress.address_line_2} type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_2', e)} />
                            </Form.Group>

                            <Row className="mb-0">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Country<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control as="select" onChange={(e) => onChangeText('country', e)} >
                                        <option >Select Country</option>
                                        {countryData && countryData.map(item => {
                                            const { id, name } = item;
                                            return (
                                                <option key={id} selected={id == (newAddress.country) ? true : false} value={id}>{name}</option>
                                            )
                                        })}

                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control as="select" defaultValue={newAddress.state} onChange={(e) => onChangeText('state', e)} >
                                        <option value="">Select State</option>
                                        {stateData && stateData.map((item, i) => {
                                            const { id, name } = item;
                                            return (
                                                <option key={id + i} selected={id == (newAddress.state) ? true : false} value={id}>{name}</option>
                                            )
                                        })}

                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zip<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control defaultValue={newAddress.zip} type="number" placeholder="zip" onChange={(e) => onChangeText('zip', e)} />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" id="formGridCheckbox">
                                <Form.Check type="checkbox" label="Yes, I would like to receive tracking information via SMS (SMS and data rates may apply)." />
                            </Form.Group>



                        </Form>
                        <div>
                <input
                    type="checkbox"
                    id="make_default_address"
                    name="make_default_address"
                    checked={makeDefault}
                    Defaultvalue={makeDefault}
                    onChange={(e) => { 
                        setmakeDefault(!makeDefault)    
                    }}
                />
                <label for="make_default_address">
                     Would you like to make this address default for future orders.?
                </label>
            </div>

                      

                         <div className="save">
                                <Link to='/dashboard/Address' onClick={(e) => SavedAddress(e)} className="button" type="button">Update and Save</Link>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateAddress
