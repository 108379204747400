import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Pagination, Table } from "react-bootstrap";
import { globalAction } from "../../../redux/actions";
import { GET_MY_ORDER } from "../../../redux/actionTypes";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
//import ReactStars from "react-rating-stars-component";
import "./myorder.scss";

class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
    };
  }
  componentDidMount() {
    const {
      user: { id },
      getMyOrders,
    } = this.props;
    const { limit } = this.state;
    getMyOrders("?id=" + id + "&limit=" + limit);
  }

  onSelectPage = (page) => {
    const {
      user: { id },
      getMyOrders,
    } = this.props;
    const { limit } = this.state;
    console.log("?id=" + id + "&limit=" + limit + "&page=" + page);
    getMyOrders("?id=" + id + "&limit=" + limit + "&page=" + page);
  };

  onPagination = () => {
    const {
      myOrderList: { last_page, current_page },
    } = this.props;
    let arr = [];
    if (last_page < 10) {
      for (let number = 1; number <= last_page; number++) {
        arr.push(
          <Pagination.Item active={current_page === number} onClick={() => this.onSelectPage(number)}>
            {number}
          </Pagination.Item>,
        );
      }
    } else {
      for (let number = 1; number <= 10; number++) {
        arr.push(
          <Pagination.Item active={current_page === number} onClick={() => this.onSelectPage(number)}>
            {number}
          </Pagination.Item>,
        );
      }
    }

    return arr;
  };

  render() {
    const {
      myOrderList: { data, current_page, last_page },
      isLoading,
    } = this.props;
    return (
      <div className="my-order">
        <div className="savedProduct">
          <div className="my-heading">My Order</div>
          {isLoading ? (
            <AnimatedLogo />
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>Order no.</th>
                  <th>Price</th>
                  <th>Payment mode</th>
                  <th>Status</th>
                  <th>Order At</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item) => {
                    const { id, order_number, created_at, order_price, order_status, payment_type } = item;
                    return (
                      <tr key={id + 1}>
                        <td>
                          <Link to={`/dashboard/order-detail/${id}`}>{order_number}</Link>{" "}
                        </td>
                        <td className="price">
                          <span>
                            <BiRupee />
                          </span>
                          {order_price}
                        </td>
                        <td>{payment_type === "cod" ? "Cash on delivery" : "Online Payment"}</td>
                        <td>{order_status}</td>
                        <td>{moment(created_at).format("LL")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </div>
        <div className="card pagination">
          <Pagination>
            <Pagination.Prev
              disabled={current_page === 1}
              onClick={() => {
                this.onSelectPage(current_page - 1);
              }}
            />
            {this.onPagination()}
            <Pagination.Next
              disabled={current_page === last_page}
              onClick={() => {
                this.onSelectPage(current_page + 1);
              }}
            />
          </Pagination>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    AppReducer: { myOrderList },
    authReducer: { user },
    errorReducer: { isLoading },
  } = state;
  return { myOrderList, user, isLoading };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyOrders: (data) => dispatch(globalAction(GET_MY_ORDER, data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyOrder);
