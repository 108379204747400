import React, { Component } from 'react'
import "./notFound.scss"

export default class NotFound extends Component {
    render() {
        return (
            <div className="not-found">
                <h1>
                    404 Not Found Page
                </h1>
            </div>
        )
    }
}
