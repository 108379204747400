//actionTypes.js
export const SIGN_IN = 'SIGN_IN'
export const SIGN_UP = 'SIGN_UP'
export const SET_USER = 'SET_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SHOW_AUTH_LOADING = 'SHOW_AUTH_LOADING'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const CLOSE_LOADING = 'CLOSE_LOADING'
export const SHOW_LOADING = 'SHOW_LOADING'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const GET_HOME_DATA = 'GET_HOME_DATA'
export const SET_HOME_DATA = 'SET_HOME_DATA'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const GET_FILTER= "GET_FILTER"
export const SET_FILTER= "SET_FILTER"
export const APPLY_FILTER= "APPLY_FILTER"
export const SET_CATEGORY = 'SET_CATEGORY'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS'
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const GET_SEARCH_PRODUCT = 'GET_SEARCH_PRODUCT'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SAVE_PRODUCT = 'SAVE_PRODUCT'

export const ADD_COMPARE= "ADD_COMPARE"
export const SET_COMPARE= "SET_COMPARE"
export const SET_COMPARE_PRODUCT= "SET_COMPARE_PRODUCT"
export const SET_SEARCH_PRODUCTS_FOR_COMPARE= "SET_SEARCH_PRODUCTS_FOR_COMPARE"
export const REMOVE_SAVE_PROD= "REMOVE_SAVE_PROD"

export const ADD_TO_CART = 'ADD_TO_CART'
export const CHANGE_CART_QTY = 'CHANGE_CART_QTY'
export const SET_CART_LIST = "SET_CART_LIST"
export const REMOVE_FROM_CART ="REMOVE_FROM_CART"

export const GET_SAVED_PRODUCTS = 'GET_SAVED_PRODUCTS'
export const SET_SAVED_PRODUCTS = 'SET_SAVED_PRODUCTS'

export const GET_LATEST_PRODUCTS = 'GET_LATEST_PRODUCTS'
export const SET_LATEST_PRODUCTS = 'SET_LATEST_PRODUCTS'

export const SET_BULK_ENQUIRY = 'SET_BULK_ENQUIRY';
export const GET_BULK_ENQUIRY_LIST = 'GET_BULK_ENQUIRY_LIST';
export const SET_BULK_ENQUIRY_LIST = 'SET_BULK_ENQUIRY_LIST';
export const DELETE_BULK_ENQUIRY='DELETED_BULK_ENQUIRY'

export const CHECK_DELIVERY="CHECK_DELIVERY";
export const SET_DELIVERY_STATUS="SET_DELIVERY_STATUS";

export const GET_USER_SAVED_ADDRESS="GET_USER_SAVED_ADDRESS";
export const SET_USER_SAVED_ADDRESS="SET_USER_SAVED_ADDRESS";

export const ADD_ADDRESS="ADD_ADDRESS";
export const DELETE_ADDRESS="DELETE_ADDRESS";
export const UPDATE_ADDRESS="UPDATE_ADDRESS";

export const  GET_MY_ORDER="GET_MY_ORDER";
export const SET_MY_ORDER_LIST="SET_MY_ORDER_LIST";
export const SOCIAL_LOGIN="SOCIAL_LOGIN";

export const TOTAL_ORDER="TOTAL_ORDER";
export const SET_TOTAL_ORDER="SET_TOTAL_ORDER";
export const PENDING_ORDER="PENDING_ORDER";

export const GET_ORDER_DETAILS="GET_ORDER_DETAILS";
export const SET_ORDER_DETAILS="SET_ORDER_DETAILS";

export const ADD_TO_COMPARE="ADD_TO_COMPARE";
export const GET_COMPARED_PRODUCTS="GET_COMPARED_PRODUCTS";
export const SET_COMPARED_PRODUCTS="SET_COMPARED_PRODUCTS";

export const SIGNUP_VERIFY='SIGNUP_VERIFY';
export const RESEND_OTP='RESEND_OTP';


export const GET_BLOG = 'GET_BLOG'
export const SET_BLOG = 'SET_BLOG'
export const ADD_BLOG_COMMENT = 'ADD_BLOG_COMMENT'
export const GET_BLOG_COMMENTS = 'GET_BLOG_COMMENTS'
export const SET_BLOG_COMMENTS = 'SET_BLOG_COMMENTS'
export const GET_BLOG_DETAILS= "GET_BLOG_DETAILS"
export const SET_BLOG_DETAILS= "SET_BLOG_DETAILS"
export const GET_BLOG_CATEGORY_LIST= "GET_BLOG_CATEGORY_LIST"
export const SET_BLOG_CATEGORY_LIST= "SET_BLOG_CATEGORY_LIST"
export const ADD_LIKE_UNLIKE = 'ADD_LIKE_UNLIKE'
export const REQUEST_NOT_FOUND= 'REQUEST_NOT_FOUND'
export const REMOVE_REQUEST= 'REMOVE_REQUEST'
export const REGISTER_REVIEW='REGISTER_REVIEW'
export const SET_PRODUCT_MAX_PRICE='SET_PRODUCT_MAX_PRICE'

export const GET_SHIPPING_ESTIMATE='GET_SHIPPING_ESTIMATE'
export const SET_SHIPPING_ESTIMATE='SET_SHIPPING_ESTIMATE'




export const SHOW_TOAST = 'SHOW_TOAST'
export const CANCEL_ORDER = 'CANCEL_ORDER'

export const  FORGET_PASSWORD='FORGET_PASSWORD'

export const ADD_TO_CART_INTO_DB='ADD_TO_CART_INTO_DB'
export const DELETE_ADD_TO_CART_FROM_DB='DELETE_ADD_TO_CART_FROM_DB'
export const DELETE_ALL_ADD_TO_CART_FROM_DB='DELETE_ALL_ADD_TO_CART_FROM_DB'
export const UPDATE_QTY_ADD_TO_CART_INTO_DB='UPDATE_QTY_ADD_TO_CART_INTO_DB'
export const GET_LIST_OF_ADD_TO_CART_INTO_DB='GET_LIST_OF_ADD_TO_CART_INTO_DB'
export const SET_BUY_NOW_ITEM='SET_BUY_NOW_ITEM'
