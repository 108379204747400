import React, { useState, useEffect } from 'react';
import './UpdateAddress.css';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import {
    globalAction,
    SHOW_LOADING,
    UPDATE_ADDRESS,
} from "../../../redux";
import { addAddressApi, getCountryApi, getStateApi } from '../../../api';
import { Link, useHistory } from "react-router-dom";



function UpdateAddress() {
    let history = useHistory()
    const { message } = useSelector(state => state.errorReducer)
    const [countryData, setcountryData] = useState([]);
    const [stateData, setstateData] = useState([])
    const [makeDefault, setmakeDefault] = useState(true)
    const [newAddress, setnewAddress] = useState({
        user_id: ' props.userId',
        full_name: '',
        address_line_1: '',
        address_line_2: '',
        country: 0,
        state: 0,
        zip: '',
        mobile_no: '',
    })



    const dispatch = useDispatch();
    const getStateData = async (id) => {
        await getStateApi(id)
            .then(async res => {
                const { data, status } = res;
                if (status === 200) {
                    await setstateData(data.state_list)
                }
            })
    }

    const onChangeText = (text, e) => {
        if (text === 'country') {
            getStateData(e.target.value);
        }
        let inputData = newAddress;
        inputData[text] = e.target.value;
        setnewAddress(inputData)
    }

    const SavedAddress = (e) => {
        e.preventDefault()
        let finaloject = { ...newAddress, default_address:'yes' }
        // let finaloject = { ...newAddress, default_address: 'yes' }
        dispatch(globalAction(UPDATE_ADDRESS, finaloject))
    }


    useEffect(() => {
        getCountryApi().then(res => {
            const { data, status } = res;
            if (status === 200) {
                setcountryData(data.country_list)
            }
        })

        let tempAddress = localStorage.getItem("UpdateAddress");
        tempAddress = JSON.parse(tempAddress);
        getStateData(tempAddress.country)
        setnewAddress(tempAddress)
        setmakeDefault(tempAddress.default_address === "yes" ? true : false)
    }, [])

    useEffect(() => {
        console.log('msg', message);
        let prevUrl = localStorage.getItem('url')
        if (message === "Address updated successfully") {
            let tempOrderDetails = localStorage.getItem("finalOrderDetails");
            tempOrderDetails = JSON.parse(tempOrderDetails);
            let finaloject = { ...newAddress, default_address: makeDefault ? 'yes' : 'no' }
            console.log(finaloject)
            tempOrderDetails['delivery_address']=finaloject;
            localStorage.setItem("finalOrderDetails", JSON.stringify(tempOrderDetails));
            if(prevUrl==='/order-summary'){
                history.push(prevUrl)
            }
            else{
                history.push('/address')
            }
          
        }
    }, [message])

    return (
        <div className="choose-address-wrap">
            <div className="container">
                <div className="ca-top-section">
                    <h1>
                        Update Address
                    </h1>
                </div>
                <div className="ca-main-body">
                    <div className={`addressform`}>
                        <Form >
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Full Name<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control defaultValue={newAddress.full_name} type="text" placeholder="Full name" onChange={(e) => onChangeText('full_name', e)} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Mobile<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control defaultValue={newAddress.mobile_no} type="number" placeholder="Mobile" onChange={(e) => onChangeText('mobile_no', e)} />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Address Line 1<span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control defaultValue={newAddress.address_line_1} type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_1', e)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress2">
                                <Form.Label>Address Line 2<span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control defaultValue={newAddress.address_line_2} type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_2', e)} />
                            </Form.Group>

                            <Row className="mb-0">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Country<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control as="select" onChange={(e) => onChangeText('country', e)} >
                                        <option >Select Country</option>
                                        {countryData && countryData.map(item => {
                                            const { id, name } = item;
                                            return (
                                                <option key={id} selected={id == (newAddress.country) ? true : false} value={id}>{name}</option>
                                            )
                                        })}

                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State<span style={{color:'red'}}>*</span></Form.Label>
                                    <Form.Control as="select" defaultValue={newAddress.state} onChange={(e) => onChangeText('state', e)} >
                                        <option value="">Select State</option>
                                        {stateData && stateData.map((item, i) => {
                                            const { id, name } = item;
                                            return (
                                                <option key={id + i} selected={id == (newAddress.state) ? true : false} value={id}>{name}</option>
                                            )
                                        })}

                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control defaultValue={newAddress.zip} type="number" placeholder="zip" onChange={(e) => onChangeText('zip', e)} />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" id="formGridCheckbox">
                                <Form.Check type="checkbox" label="Yes, I would like to receive tracking information via SMS (SMS and data rates may apply)." />
                            </Form.Group>



                        </Form>

                        <div>
                            <input
                                type="checkbox"
                                id="make_default_address"
                                name="make_default_address"
                                checked={makeDefault}
                                Defaultvalue={makeDefault}
                                onChange={(e) => {
                                    setmakeDefault(!makeDefault)
                                }}
                            />
                            <label for="make_default_address">
                                Would you like to make this address default for future orders.?
                            </label>
                        </div>

                        <div className="save">
                            <Link to='/address' onClick={(e) => SavedAddress(e)} className="button" type="button">Update and Save</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateAddress
