// Initial State

import { SET_USER,LOGOUT_USER ,SET_USER_SAVED_ADDRESS } from "../actionTypes";

const initialState = {
    user: JSON.parse(localStorage.getItem("bellStone_user")) ,
    isLogin:localStorage.getItem("bellStone_idToken")?true:false,
    savedAddresses:[]
  };
  // Redux: Counter Reducer
  const authReducer = (state = initialState, action) => {
   const{type,payload}=action;
    switch (type) {
      case SET_USER: {
        return {
          ...state,
          user: payload,
          isLogin:localStorage.getItem('bellStone_idToken')?true:false
        };
      }
      case LOGOUT_USER: {
        localStorage.removeItem("bellStone_user")
        localStorage.removeItem("bellStone_idToken")
        localStorage.removeItem('cart')
        return {
          ...state,
          user: null,
          isLogin:false
        };
      }

      case SET_USER_SAVED_ADDRESS:{
        return{
          ...state,
          savedAddresses:payload
        }
      }

      default: {
        return state;
      }
    }
  };
  // Exports
  export default authReducer;