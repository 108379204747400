import React, { useEffect } from "react";
import "./slider.scss";
import { Container, Row, Col, Tooltip,OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import OwlCarousel from "react-owl-carousel";
import { connect } from "react-redux";
import NoImage from "../../../assets/noImage.jpg";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
// import Productimg2 from "../image/product/41fvnfrtcbl.jpg";
// import Productimg3 from "../image/product/41sj2zbfcsl.jpg";
// import Productimg4 from "../image/product/470dbg.jpg";
// import Productimg5 from "../image/product/7822222.jpg";
// import Productimg6 from "../image/product/dn1_1.jpg";
// import Productimg7 from "../image/product/dn1_1.jpg";
// import Productimg8 from "../image/product/ladd18.jpg";
// import Productimg9 from "../image/product/pre6.jpg";
// import Productimg10 from "../image/product/ry.jpg";
// import Productimg11 from "../image/product/soil18.jpg";
import { useDispatch } from "react-redux";
import { globalAction, SHOW_TOAST, ADD_TO_CART, SAVE_PRODUCT, ADD_TO_CART_INTO_DB } from "../../../redux";
import ReactStars from "react-rating-stars-component";

function BestDealOn({ home, cart, user }) {
  const dispatch = useDispatch();
  const { best_deal_product, most_popular_product, new_product } = home;
  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };
  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} are successfully added in your cart`,
            status: "Warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} are successfully added in your cart`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if(user){
          dispatch(globalAction(ADD_TO_CART_INTO_DB, {userId:user.id}));
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  const fivePair = (input) => {
    var temp = input.slice();
    var arr = [];
    while (temp.length) {
      arr.push(temp.splice(0, 5));
    }

    return arr;
  };
  return (
    <section className="best_dealon_wrapp spacing">
      <Container>
        <Row>
          <Col md={12} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>Best Deals On</h3>
              </div>
              {best_deal_product.data.length > 0 && (
                <OwlCarousel className="owl-theme" items={1} autoplay={false} smartSpeed={300} dots={false} nav={true} slideBy={1}>
                  {fivePair(best_deal_product.data).map((bestDeal, index) => (
                    <div class="item" key={index + 1}>
                      {bestDeal.map((item) => {
                        const { id, image_url, title, sale_price, stock_qty,slug ,avg_rating} = item;
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link
                               to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                     
                               >
                                <img className={stock_qty <= 0 && "outofstock"} src={image_url ? image_url : NoImage} alt="" />
                              </Link>
                            </div>
                            <div className="start_card_body">
                            <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {title}
                          </Tooltip> }
                          >
                              <Link 
                               to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                     
                              >{title.slice(0, 30)}...</Link>
                             
                              </OverlayTrigger> <span>
                              <ReactStars count={5}  size={22} edit={false} isHalf={true} color="#9e9e9e" activeColor="#008000" value={avg_rating||0} />
                              </span>
                              <span>
                                <BiRupee /> 
                                {sale_price}
                              </span>
                              <div className="product_icon text_aling">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() => {
                                      saveProductByUser(id);
                                    }}
                                  />
                                </span>
                                <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && addToCart(item)}>
                                  <FaShoppingCart />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
          <Col md={12} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>New Products</h3>
              </div>
              {new_product.data.length > 0 && (
                <OwlCarousel className="owl-theme" items={1} autoplay={false} smartSpeed={300} dots={false} nav={true} slideBy={1}>
                  {fivePair(new_product.data).map((product, index) => (
                    <div class="item" key={"prod" + index + 1}>
                      {product.map((item) => {
                        const { id, image_url, title, sale_price, stock_qty,slug,avg_rating } = item;
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link className={stock_qty <= 0 && "outofstock"} 
                              to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                     
                              >
                                <img src={image_url ? image_url : NoImage} alt="" />
                              </Link>
                            </div>
                            <div className="start_card_body">
                            <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {title}
                          </Tooltip> }
                          >
                              <Link 
                               to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                     
                              >{title.slice(0, 30)}...</Link>
                            
                              </OverlayTrigger>  <span>
                              <ReactStars count={5}  size={22} edit={false} isHalf={true} color="#9e9e9e" activeColor="#008000" value={avg_rating||0} />
                              </span>
                              <span>
                               <BiRupee />
                                {sale_price}
                              </span>
                              <div className="product_icon text_aling">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() => {
                                      saveProductByUser(id);
                                    }}
                                  />
                                </span>
                                <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && addToCart(item)}>
                                  <FaShoppingCart />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
          <Col md={12} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>Most Popular Products</h3>
              </div>
              {most_popular_product.data.length > 0 && (
                <OwlCarousel className="owl-theme" items={1} autoplay={false} smartSpeed={300} dots={false} nav={true} slideBy={1}>
                  {fivePair(most_popular_product.data).map((product, index) => (
                    <div class="item" key={"prod" + index + 1}>
                      {product.map((item) => {
                        const { id, image_url, title, sale_price, stock_qty,slug,category,subcategory,avg_rating} = item;
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link className={stock_qty <= 0 && "outofstock"} 
                               to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                     
                              >
                                <img src={image_url ? image_url : NoImage} alt="" />
                              </Link>
                            </div>
                            <div className="start_card_body">
                            <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {title}
                          </Tooltip> }
                          >
                              <Link 
                           to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                              >{title.slice(0, 30)}...</Link>
                            
                              </OverlayTrigger>  <span>
                              <ReactStars count={5}  size={22} edit={false} isHalf={true} color="#9e9e9e" activeColor="#008000" value={avg_rating||0} />
                              </span>
                              <span>
                              <BiRupee />
                                {sale_price}
                              </span>
                              <div className="product_icon text_aling">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() => {
                                      saveProductByUser(id);
                                    }}
                                  />
                                </span>
                                <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty > 0 && addToCart(item)}>
                                  <FaShoppingCart />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
const mapStateFromProps = (state) => {
  const {
    AppReducer: { home },
    AppReducer: { cart },
  } = state;
  const {
    authReducer: { user },
  } = state;
  return { home, cart, user };
};
export default connect(mapStateFromProps)(BestDealOn);
