import React, { useEffect, useState } from "react";
import "./cart.css";
import { useSelector, useDispatch } from "react-redux";
import {
  globalAction,
  ADD_TO_CART,
  SHOW_TOAST,
  GET_HOME_DATA,
  GET_USER_SAVED_ADDRESS,
  SHOW_LOADING,
  UPDATE_QTY_ADD_TO_CART_INTO_DB,
  DELETE_ADD_TO_CART_FROM_DB,
} from "../../../redux";
import { Container, Row, Col } from "react-bootstrap";
import { ImSad } from "react-icons/im";
import { BiRupee } from "react-icons/bi";
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import ChooseUserType from "../../ChooseUserType/ChooseUserType";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";


function Cart({ history }) {
  const params = useParams()
  const dispatch = useDispatch();
  const { cart, buyNow } = useSelector((state) => state.AppReducer);
  const { visibleMessage, isLoading } = useSelector(
    (state) => state.errorReducer
  );
  const { user, isLogin } = useSelector((state) => state.authReducer);
  const [modalFlag, setModalFlag] = useState(false);
  const [totalPrice, settotalPrice] = useState(0);
  const [gstInfo, setGstInfo] = useState({
    gst_number: "",
    company_name: "",
    company_address: "",
  });
  const [getAddressInfo, setGetAddressInfo] = useState({});
  const [flag, setflag] = useState(true);
  const [totalGSTCharges, settotalGSTCharges] = useState(0);
  // const [buyNow, setBuyNow] = useState(JSON.parse(localStorage.getItem('buyNow')))



  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null && buyNow === undefined) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
    if (buyNow) {
      calculateTotalPrice(buyNow)
    }
  }, [dispatch, buyNow]);



  const calculateTotalPrice = (cart) => {
    let total = 0;
    let AllGSTCharges = 0;
    let totalWeight = 0;
    cart.map((item, i) => {
      let price;
      let GSTcharges;
      price = item.regular_price;
      if (item.sale_price !== null) {
        price = item.sale_price;
      }
      if (item.tax !== null) {
        GSTcharges = Math.ceil((Number(price) / 100) * Number(item.tax));
        price = Math.ceil(
          (Number(price) / 100) * Number(item.tax) + Number(price)
        );
      }

      total += parseFloat(price * item.quantity);
      AllGSTCharges += GSTcharges;
      return total;
    });
    settotalGSTCharges(Number(AllGSTCharges).toFixed(0));
    settotalPrice(Number(total).toFixed(0));
  };

  useEffect(() => {
    dispatch(globalAction(GET_HOME_DATA));
    if (user !== null) {
      dispatch(globalAction(GET_USER_SAVED_ADDRESS, user.id));
    }
    dispatch(globalAction(SHOW_LOADING));
  }, [dispatch]);

  useEffect(() => {
    if (!buyNow) {
      calculateTotalPrice(cart); // for cartList
    }

  }, [visibleMessage]);

  useEffect(() => {
    if (!buyNow) {
      calculateTotalPrice(cart); // for cartList
    }
    // calculateTotalPrice(cart);
  }, [cart]);

  /** Change the quantity of cart items */
  const quantityChange = (text, id, index) => {
    let data = cart[index];
    if (text === "add") {
      let value = data.quantity + 1;
      data = { ...data, quantity: value };
      const index = cart.findIndex((data) => data.id === id);
      cart[index] = data;
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
    } else {
      let value;
      if (data.quantity > 1) {
        value = data.quantity - 1;
        data = { ...data, quantity: value };
        const index = cart.findIndex((data) => data.id === id);
        cart[index] = data;
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
      }
      // changeQuantity({id,value})
    }
    if (user) {
      dispatch(
        globalAction(UPDATE_QTY_ADD_TO_CART_INTO_DB, {
          data: data,
          userId: user.id,
        })
      );
    }
    calculateTotalPrice(cart);
  };

  const quantityChangeForBuyNow = (text, id, index) => {
    let data = buyNow[index];
    if (text === "add") {
      let value = data.quantity + 1;
      data = { ...data, quantity: value };
      const index = buyNow.findIndex((data) => data.id === id);
      buyNow[index] = data;
      localStorage.setItem("buyNow", JSON.stringify(buyNow));
    } else {
      let value;
      if (data.quantity > 1) {
        value = data.quantity - 1;
        data = { ...data, quantity: value };
        const index = buyNow.findIndex((data) => data.id === id);
        buyNow[index] = data;
        localStorage.setItem("buyNow", JSON.stringify(buyNow));
      }
      // changeQuantity({id,value})
    }
    calculateTotalPrice(buyNow);
  };

  console.log('cart Page',cart)


  /** Delete Items in cart */
  const DeleteCardItems = (id) => {
    let index = cart.findIndex(function (el) {
      return el.id === id;
    });
    if (index !== -1) {
      if (user) {
        dispatch(
          globalAction(DELETE_ADD_TO_CART_FROM_DB, {
            data: cart[index],
            userId: user.id,
          })
        );
      }
      cart.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
      const data = {
        msg: `Item successfully deleted in your cart`,
        status: "success",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const modalToggle = () => {
    setModalFlag(!modalFlag);
  };

  const checkUserType = (user, userIslogin, userType) => {
    if (userType === "user") {
      if (user !== null && userIslogin) {
        modalToggle();
      } else {
        history.push("/login");
        modalToggle();
      }
    } else {
      history.push("/add-new-address");
      modalToggle();
    }
  };
  return (
    <div className="cart-page-wrap">
      <Container>
        <Row>
          <Col
            md={{ span: 12, order: 2 }}
            lg={{ span: 8, order: 1 }}
            className="left-col"
          >
            <div className="top-section">
              <h1>Shopping Cart</h1>
            </div>
            <div className="shopping-cart-wrap">
              <div className="sc-top-section">
                <span>Shipping Update</span>
                <p>
                  As major carriers like FedEx and UPS have acknowledged,
                  unprecedented demand may cause 1-2 day shipping delays from
                  the ETA given at checkout. We apologize for any inconvenience.
                </p>
              </div>

              {buyNow !== undefined
                ?
                buyNow?.map((item, i) => {
                  return (
                    <div className="sc-item-wrap">
                      <div className="sc-item-row">
                        <div className="sc-item-col cm-col-2">
                          <div className="col-name">
                            <h6>Name</h6>
                          </div>
                          <div className="sc-item-info">
                            <div className="sc-item-img">
                              <Link
                                to={`/${item.category.slug}/${item.subcategory.slug
                                  }/${item.slug !== null ? item.slug : item.id}`}
                              >
                                <img
                                  src={item.image_url}
                                  alt={item.seo_title}
                                />
                              </Link>
                            </div>
                            <div className="info">
                              <Link
                                to={`/${item.category.slug}/${item.subcategory.slug
                                  }/${item.slug !== null ? item.slug : item.id}`}
                              >
                                <h6>{item.title} {item?.variant_name}</h6>
                              </Link>
                              <p>
                                Product id : <span>{item.id}</span>
                              </p>
                              <p>
                                Unit Price :{" "}
                                <span>
                                  <BiRupee />
                                  {item.sale_price}
                                </span>
                              </p>
                              {item.tax && (
                                <p className="gst-price-product-page">
                                  {" "}
                                  GST Price:
                                  <span>
                                    <BiRupee />
                                    {item.sale_price !== null
                                      ? Math.ceil(
                                        (Number(item.sale_price) / 100) *
                                        Number(item.tax) +
                                        Number(item.sale_price)
                                      ).toFixed(0)
                                      : Math.ceil(
                                        (Number(item.regular_price) / 100) *
                                        Number(item.tax) +
                                        Number(item.regular_price)
                                      ).toFixed(0)}
                                  </span>
                                  <span className="gst-span-product-page">{`(include ${item.tax}% GST)`}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="sc-item-col cm-col-1">
                          <div className="sc-item-inner-col">
                            <div className="col-name">
                              <h6>QTY</h6>
                            </div>
                            <div className="si-qty-count">
                              <span
                                className="minus"
                                onClick={() => {
                                  if (item.minimum_qty_per_order < item.quantity) {
                                    quantityChangeForBuyNow("sub", item.id, i);
                                  }
                                }}
                              >
                                <FaMinus />
                              </span>
                              <span className="count">{item.quantity}</span>
                              <span
                                className="plus"
                                onClick={() => {
                                  if (item.maximum_qty_per_order === null || item.maximum_qty_per_order > item.quantity) {
                                    quantityChangeForBuyNow("add", item.id, i);
                                  }
                                }}
                              >
                                <FaPlus />
                              </span>

                            </div>
                            {item.minimum_qty_per_order >= item.quantity &&
                              <p className="red-alert-text">Minimum {item.minimum_qty_per_order} per QTY</p>
                            }
                            {item.maximum_qty_per_order && item.maximum_qty_per_order <= item.quantity &&
                              <p className="red-alert-text">Maximum {item.maximum_qty_per_order} per QTY</p>
                            }
                          </div>
                          <div className="sc-item-inner-col">
                            <div className="col-name">
                              <h6>ITEM TOTAL</h6>
                            </div>
                            {item.sale_price !== null ? (
                              <p>
                                <span>
                                  <BiRupee />
                                  {(
                                    Math.ceil(
                                      (Number(item.sale_price) / 100) *
                                      Number(item.tax) +
                                      Number(item.sale_price)
                                    ) * Number(item.quantity)
                                  ).toFixed(0)}
                                </span>
                              </p>
                            ) : (
                              <p>
                                <span>
                                  <BiRupee />
                                  {(
                                    Math.ceil(
                                      (Number(item.sale_price) / 100) *
                                      Number(item.tax) +
                                      Number(item.sale_price)
                                    ) * Number(item.quantity)
                                  ).toFixed(0)}
                                </span>
                              </p>
                            )}
                          </div>
                          <div className="sc-item-inner-col">
                            <div className="col-name">
                              <h6>REMOVE</h6>
                            </div>
                            <div className="eelete-icon">
                              <span
                                className="itemDelete"
                                onClick={() => {
                                  DeleteCardItems(item.id);
                                }}
                              >
                                <RiDeleteBin2Line />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                :
                cart.length > 0 ? (
                  cart.map((item, i) => {
                  
                    return (
                      <div className="sc-item-wrap">
                        <div className="sc-item-row">
                          <div className="sc-item-col cm-col-2">
                            <div className="col-name">
                              <h6>Name</h6>
                            </div>
                            <div className="sc-item-info">
                              <div className="sc-item-img">
                                <Link
                                  to={`/${item.category.slug}/${item.subcategory.slug
                                    }/${item.slug !== null ? item.slug : item.id}`}
                                >
                                  <img
                                    src={item.image_url}
                                    alt={item.seo_title}
                                  />
                                </Link>
                              </div>
                              <div className="info">
                                <Link
                                  to={`/${item.category.slug}/${item.subcategory.slug
                                    }/${item.slug !== null ? item.slug : item.id}`}
                                >
                                  <h6>{item.title} {item?.variant_name}</h6>
                                </Link>
                                <p>
                                  Product id : <span>{item.id}</span>
                                </p>
                                <p>
                                  Unit Price :{" "}
                                  <span>
                                    <BiRupee />
                                    {item.sale_price}
                                  </span>
                                </p>
                                {item.tax && (
                                  <p className="gst-price-product-page">
                                    {" "}
                                    GST Price:
                                    <span>
                                      <BiRupee />
                                      {item.sale_price !== null
                                        ? Math.ceil(
                                          (Number(item.sale_price) / 100) *
                                          Number(item.tax) +
                                          Number(item.sale_price)
                                        ).toFixed(0)
                                        : Math.ceil(
                                          (Number(item.regular_price) / 100) *
                                          Number(item.tax) +
                                          Number(item.regular_price)
                                        ).toFixed(0)}
                                    </span>
                                    <span className="gst-span-product-page">{`(include ${item.tax}% GST)`}</span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="sc-item-col cm-col-1">
                            <div className="sc-item-inner-col">
                              <div className="col-name">
                                <h6>QTY</h6>
                              </div>
                              <div className="si-qty-count">
                                <span
                                  className="minus"
                                  onClick={() => {
                                    if (item.minimum_qty_per_order < item.quantity) {
                                      quantityChange("sub", item.id, i);
                                    }
                                  }}
                                >
                                  <FaMinus />
                                </span>
                                <span className="count">{item.quantity}</span>
                                <span
                                  className="plus"
                                  onClick={() => {
                                    if (item.maximum_qty_per_order === null || item.maximum_qty_per_order > item.quantity) {
                                      quantityChange("add", item.id, i);
                                    }
                                  }}
                                >
                                  <FaPlus />
                                </span>

                              </div>
                              {item.minimum_qty_per_order >= item.quantity &&
                                <p className="red-alert-text">Minimum ${item.minimum_qty_per_order} per QTY</p>
                              }
                              {item.maximum_qty_per_order && item.maximum_qty_per_order <= item.quantity &&
                                <p className="red-alert-text">Maximum ${item.maximum_qty_per_order} per QTY</p>
                              }
                            </div>
                            <div className="sc-item-inner-col">
                              <div className="col-name">
                                <h6>ITEM TOTAL</h6>
                              </div>
                              {item.sale_price !== null ? (
                                <p>
                                  <span>
                                    <BiRupee />
                                    {(
                                      Math.ceil(
                                        (Number(item.sale_price) / 100) *
                                        Number(item.tax) +
                                        Number(item.sale_price)
                                      ) * Number(item.quantity)
                                    ).toFixed(0)}
                                  </span>
                                </p>
                              ) : (
                                <p>
                                  <span>
                                    <BiRupee />
                                    {(
                                      Math.ceil(
                                        (Number(item.sale_price) / 100) *
                                        Number(item.tax) +
                                        Number(item.sale_price)
                                      ) * Number(item.quantity)
                                    ).toFixed(0)}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="sc-item-inner-col">
                              <div className="col-name">
                                <h6>REMOVE</h6>
                              </div>
                              <div className="eelete-icon">
                                <span
                                  className="itemDelete"
                                  onClick={() => {
                                    DeleteCardItems(item.id);
                                  }}
                                >
                                  <RiDeleteBin2Line />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="empty-cart">
                    <span>
                      {" "}
                      <ImSad />
                    </span>
                    <p> Your Cart Is Empty</p>
                  </div>
                )
              }


            </div>
          </Col>
          <Col
            md={{ span: 12, order: 1 }}
            lg={{ span: 4, order: 1 }}
            className="right-col"
          >
            <div className="order-summary-wrap">
              <div class="item">
                <h5>
                  Order Summary <span>({cart.length} Items)</span>
                </h5>
              </div>
              <div class="subtotal">
                <p>Subtotal:</p>
                <p>
                  <span>
                    <BiRupee /> {totalPrice}
                  </span>
                </p>
              </div>
              {/* <div class="subtotal"><p>Est. Shipping:</p><p><span><BiRupee /> 0.00</span></p></div> */}
              <div class="total">
                <h4>Order Total:</h4>
                <h4>
                  <span>
                    <BiRupee />
                    {totalPrice}
                  </span>
                </h4>
              </div>
              <div class="hr-line">
                <hr />
              </div>
              {buyNow ?
                <div class="checkout">
                  {buyNow.length > 0 ? (
                    <button
                      onClick={() =>
                        user !== null && isLogin
                          ? history.push("/address")
                          : modalToggle()
                      }
                    >
                      Proceed to checkout
                    </button>
                  ) : (
                    <button className="add-product-to-checkout">
                      Add Products to checkout
                    </button>
                  )}
                </div>
                :
                <div class="checkout">
                  {cart.length > 0 ? (
                    <button
                      onClick={() =>
                        user !== null && isLogin
                          ? history.push("/address")
                          : modalToggle()
                      }
                    >
                      Proceed to checkout
                    </button>
                  ) : (
                    <button className="add-product-to-checkout">
                      Add Products to checkout
                    </button>
                  )}
                </div>
              }


              {/* <div class="checkout">
                {cart.length > 0 ? (
                  <button
                    onClick={() =>
                      user !== null && isLogin
                        ? history.push("/address")
                        : modalToggle()
                    }
                  >
                    Proceed to checkout
                  </button>
                ) : (
                  <button className="add-product-to-checkout">
                    Add Products to checkout
                  </button>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>

      <ChooseUserType
        modalFlag={modalFlag}
        modalToggle={modalToggle}
        user={user}
        isLogin={isLogin}
        checkUserType={checkUserType}
      />
    </div>
  );
}

export default withRouter(Cart);
