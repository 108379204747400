import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./offlineBusiness.scss";

function OfflineBusiness() {
  useEffect(() => {
    document.title = "offline Business";
  }, []);

  return (
    <section className="offline_business">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/offline-business">Offline Business</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>FOR OFFLINE BUSINESS</h5>
              </div>
              <div className="common_pages_content">
                <div className="common_pages_inner offline_business_content">
                  <div className="offline_business_card">
                    <h6>TEST & Measure/Temp, Insturment/Flow Meter</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">Sushil Kumar</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>9311210517 , 011-45158802</span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>sushilbellstone@gmail.com</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>Soil Testing / Safety / Tools</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">Pratap Yadav</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>9310039444 , 011-45158888</span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>pratap.bellstone@gmail.com</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>ITI EQUIPMENT</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">RAHUL KASHYAP</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>9312483714, 9310039443, 011-45158810</span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>rahulbellstone@gmail.com</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>POWER TOOLS / SOIL TESTING / LABORATORY</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name"> ROHIT SAINI</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span> Sr. Sales Executive</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>8800229423 , 011-45158807</span>
                      </li>
                      <li>
                        <span>E-mail :</span> <span>cds@bhi.in</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>VALVE EQUIPMENT</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">SUBHASH CHANDRA</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>9212176506, 011-45158888</span>
                      </li>
                      <li>
                        <span>E-mail :</span> <span>valve@bhi.in</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>Construction Machinery (South India)</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">ASHISH RAUT</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span> <span>9650636353 </span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>cds.hydrabad@bellstone.in</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          Door No 7 3 149/15/1 Survey No 66 67 and 68 Bapuji
                          Nagar, Ganganpahad Rajender nagar, Telangana Ngos
                          Colony-Katedan Industrial Area, Hyderabad - 500077
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>Construction Machinery (North India)</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">SANJEEV KUMAR</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span> <span>9650636353 </span>
                      </li>
                      <li>
                        <span>E-mail :</span> <span>sanjeev@bellstone.in</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          Plot No. D-6, Sector-A-3, Industrial Area, Trans Delhi
                          Signature City, Ghaziabad, Uttar Pradesh 201102
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>Construction Machinery (East India)</h6>
                    <ul>
                      <li>
                        <span>Name :</span> <span className="name">BALRAM</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span> <span>9435101209 </span>
                      </li>
                      <li>
                        <span>E-mail :</span> <span>assam@bellstone.in</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          Plot No. D-6, Sector-A-3, Industrial Area, Trans Delhi
                          Signature City, Ghaziabad, Uttar Pradesh 201102
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>Construction Machinery (West India)</h6>
                    <ul>
                      <li>
                        <span>Name :</span>{" "}
                        <span className="name">MANDEEP CHAUHAN</span>
                      </li>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>Sr. Sales Manager</span>
                      </li>
                      <li>
                        <span>Call :</span> <span>9815087382 </span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>mandeepchauhan01@hotmail.com</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">Chandigarh </span>
                      </li>
                    </ul>
                  </div>

                  <div className="offline_business_card">
                    <h6>BELLSTONE ONLINE</h6>
                    <ul>
                      <li>
                        <span>Designation : </span>{" "}
                        <span>All Category Sales/Enquiry</span>
                      </li>
                      <li>
                        <span>Call :</span>{" "}
                        <span>9818599554 , 011-45158808</span>
                      </li>
                      <li>
                        <span>E-mail :</span>{" "}
                        <span>info@bellstoneonline.com</span>
                      </li>
                      <li>
                        <span>Address :</span>{" "}
                        <span className="name">
                          3755, G.F. MAIN CHAWRI BAZAR, DELHI-110006
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OfflineBusiness;
