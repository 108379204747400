import React, { useEffect } from "react";
import "./civil-labs.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import CivilLabSlider from "../slider/CivilLabSlider";
import CivilLab5 from "../../../assets/product/civil_lab5.jpg";
import { useDispatch, useSelector } from "react-redux";
import { globalAction, SHOW_TOAST, ADD_TO_CART, SAVE_PRODUCT, ADD_TO_CART_INTO_DB } from "../../../redux";

function CivilLab({ civilLabData }) {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.AppReducer);
  const { user } = useSelector((state) => state.authReducer);

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: "Please login or Register to save this product.",
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} are successfully added in your cart`,
            status: "Warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} are successfully added in your cart`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if(user){
          dispatch(globalAction(ADD_TO_CART_INTO_DB, {userId:user.id}));
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  return (
    <section className="civil_lab spacing 4">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3> {civilLabData && civilLabData.title_2}</h3>
          </Col>
          <Col className="lab_wrapper">
            <div className="lab_wrapper_img">
              {
                civilLabData &&
                  civilLabData.subcategory_slug!==null ?
                  <Link
                  to={`/${civilLabData.category_slug}/${civilLabData.subcategory_slug}`}
                   >
                    <img src={civilLabData && civilLabData.banner_2} alt={civilLabData.title_2} />
                  </Link>
                  :     
                   <Link 
                   to={`/category-detail/${civilLabData.category_slug}`}
                   >
                                      
                  <img src={civilLabData && civilLabData.banner_2} alt={civilLabData.title_2}/>
                </Link>
                 

              }

            </div>
            <div className="civil_lab_content">
              {civilLabData &&
                civilLabData.product2.map((item, i) => {
     
                  const { stock_qty,category,subcategory,slug,id } = item;
                  if (i === 0) {
                    return (
                      <div className={`product item${i}`}>
                        <Link className={stock_qty <= 0 && "outofstock"} 
                        to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                        >
                          <img src={item && item.image_url} alt="" />
                        </Link>
                        <div className="product_body">
                          <p>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={<Tooltip id={`tooltip-top`} style={{'font-size':'12px','text-transform':"capitalize"}}>
                           {item.title}
                          </Tooltip> }
                          >
                            <Link 
                              to={`/${item.category.slug}/${item.subcategory.slug}/${item.slug!==null?item.slug:id}`}
                              >{item.title.slice(0, 30)}...</Link>
                           
                            </OverlayTrigger> <span>
                              Regural Price: <BiRupee />
                              {item.regular_price}
                            </span>
                            <span>
                              Special Price: <BiRupee />
                              {item.sale_price}
                            </span>
                          </p>
                          <div className="product_icon">
                            <span className="wishlist">
                              <AiTwotoneHeart onClick={() => saveProductByUser(item.id)} />
                            </span>
                            <span className={`shop ${stock_qty <= 0 && "disabled"}`} onClick={() => stock_qty < 0 && addToCart(item)}>
                              <FaShoppingCart />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}

              <CivilLabSlider products={civilLabData && civilLabData.product2} addToCart={addToCart} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CivilLab;
