import React, { useEffect, useState } from "react";
import CivilLab from "../../../assets/product/civil_lab1.jpg";
import { Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./login.scss";
import { FaFacebookF, FaGoogle, FaGooglePlusG } from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import { globalAction } from "../../../redux/actions";
import {
  HIDE_MESSAGE,
  SHOW_TOAST,
  SIGN_IN,
  SOCIAL_LOGIN,
} from "../../../redux/actionTypes";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

const initialState = { email: "", password: "" };
function Login(props) {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState(initialState);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user) {
      props.history.push("/dashboard");
    }
  }, []);

  const changeText = (value, text) => {
    let credential = state;
    credential[text] = value.target.value;
    setState(credential);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const {
        history: { location },
        login,
      } = props;

      state.forword = props.history;

      if (location.state) {
        state.path = location.state.from;
      } else {
        let prevUrl = localStorage.getItem("url");
        state.path = prevUrl;
        // state.path = '/dashboard';
      }
      await login(state);
    }

    setValidated(true);
  };

  const onSocialLogin = (data) => {
    const { socialLogin, history, location } = props;
    console.log(history);
    if (data.provider === "google") {
      data.forword = history;
      if (location.state) {
        data.path = location.state.from;
      } else {
        data.path = "/dashboard";
      }
      console.log("data", data);
      socialLogin(data);
    }
  };

  const responseGoogle = (response) => {
    console.log(response);
    const { profileObj } = response;
    console.log(profileObj);
    const { googleId, email, name } = profileObj;
    onSocialLogin({ provider_id: googleId, email, name, provider: "google" });
  };

  const responseFacebook = (response) => {
    const { email, name } = response;
    onSocialLogin({
      provider_id: response?.id,
      email,
      name,
      provider: "facebook",
    });
  };

  return (
    <div>
      <div className="full-width-container login">
        <div className="container-md login-container">
          <div className="row login-row">
            <div className="col-md-6 login-img">
              <img src={CivilLab} className="img-fluid" alt="login-img" />
            </div>
            <div className="col-md-6 login-6">
              <div className="heading">
                <h2>Welcome Back!</h2>
                <p>Please Sign In to your account.</p>
              </div>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col} md="12" controlId="emailId">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    onChange={(value) => changeText(value, "email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="Password">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Password"
                    onChange={(value) => changeText(value, "password")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 forget_password"
                  id="formGridCheckbox"
                >
                  <Form.Check type="checkbox" label="Keep me Log In" />
                  <Link to="/forget-password"> Forget Password? </Link>
                </Form.Group>
                {/* <Link to="/register"><h6>Registrate</h6></Link> */}
                <Button className="btn w-100" type="submit">
                  Log In Now
                </Button>
                <div className="or">
                  <p>
                    <span>OR Login with</span>
                  </p>
                  <hr className="hr-line" />
                </div>
                <div className="social-login">
                  <FacebookLogin
                    appId="1724206654548485"
                    autoLoad={false}
                    textButton={<i class="fa fa-facebook-f"></i>}
                    fields="name,email,picture"
                    callback={responseFacebook}
                  />

                  <GoogleLogin
                    clientId="592785705123-gm9s0j6s7kd6v0slrr4atrrk61pe9cda.apps.googleusercontent.com"
                    buttonText="Login"
                    // icon={false}
                    autoLoad={false}
                    redirectUri="https://www.bellstoneonline.com/dashboard"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
                {/* <div className="login_width">
                  <p>With your social media account</p>
                  <div className="login_width_icon">
                    <Link to="" className="login_icon facebook">
                      <span>
                        <FaFacebookF />
                      </span>
                      <span>Facebook</span>
                    </Link>
                    <Link to="" className="login_icon google">
                      <span>
                        <FaGoogle />
                      </span>
                      <span>Google</span>
                    </Link>
                  </div>
                </div> */}
                <div className="link">
                  <p>
                    Don't Have account? <Link to="register">Signup!</Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateFromProps = (state) => {
  const {
    errorReducer: { message, visibleMessage, status },
  } = state;
  return { message, visibleMessage, status };
};
const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(globalAction(SIGN_IN, data)),
  hideMessage: () => dispatch(globalAction(HIDE_MESSAGE)),
  showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
  socialLogin: (data) => dispatch(globalAction(SOCIAL_LOGIN, data)),
});
export default connect(mapStateFromProps, mapDispatchToProps)(Login);
