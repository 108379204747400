import React,{useState} from "react";
import "./newsletter.scss";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosMailOpen } from "react-icons/io";
import { sendSubscribe } from "../../../api/Api_Call";
import {useDispatch} from 'react-redux'
import { globalAction, SHOW_TOAST } from "../../../redux";

function Newsletter() {
const dispatch = useDispatch()
  const [email, setemail] = useState("")

  const onSubscribe=(e)=>{
    e.preventDefault()
    sendSubscribe({email}).then(res=>{
      const {data,status}=res;
      if(status===200){
        setemail("")
       dispatch(globalAction(SHOW_TOAST,{data}))

      }
    }).catch(err=>{
      const {response}=err;
      dispatch(globalAction(SHOW_TOAST,response))
      

    })
  }

  return (
    <section className="newsletter spacing">
      <Container>
        <Row>
          <Col md={4}>
            <div className="newsletter_img">
              <IoIosMailOpen />
            </div>
          </Col>
          <Col md={8}>
            <div className="newsletter_inner">
              <div className="newsletter_content">
                <h4>newsletter</h4>
                <p>Subscribe Our newsletter to get our latest update & news</p>
              </div>
              <form className="newsletter_form" onSubmit={onSubscribe}>
                <div className="form_group">
                  <input value={email} type="email" onChange={(e)=>setemail(e.target.value)} />
                  <button>
                    <BsArrowReturnRight />
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Newsletter;
