import React, { useState } from "react";
import "./slider.scss";
import { Container, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_SEARCH_PRODUCT, globalAction } from "../../../redux";

function BestBrands({ brands, history }) {
  const dispatch = useDispatch();
  const onSearch = (search) => {
    let param = "";
    param += `?search=${search}`;
    // console.log("params", param);
    dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: history }));
  };
  return (
    <section className="best_brands spacing">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>Best Brands</h3>
          </Col>
          {brands && brands.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              autoplay={true}
              smartSpeed={300}
              loop={true}
              margin={10}
              responsive={{
                0: {
                  nav: false,
                  dots: false,
                  items: 3,
                },
                768: {
                  dots: false,
                  nav: false,
                  items: 4,
                },
                992: {
                  items: 6,
                  dots: false,
                  nav: true,
                },
                1024: {
                  items: 8,
                  dots: false,
                  nav: true,
                },
              }}
            >
              {brands &&
                brands.map((item) => (
                
                  <div className="item" onClick={() => onSearch(item.name)}>
                    
                    <div className="img_wrap">
                      <Link to="">
                        <img src={item.image && item.image} alt="img" />
                      </Link>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default withRouter(BestBrands);
